import { Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';
import { MemberProfileGuard } from '@fitness-central/core/authentication/guard/personal-profile-guard';
import { EmployeeProfileGuard } from '@fitness-central/core/authentication/guard/professional-profile-guard';
import { LayoutComponent } from '@fitness-central/layout/layout.component';

import { authenticationRoutes } from './authentication/authentication.routes';
import { communicationRoutes } from './communication/communication.routes';
import { communityRoutes } from './community/community.routes';
import { clientRoutes } from './employee/clients/clients.routes';
import { employeeRoutes } from './employee/employee.routes';
import { errorRoutes } from './error/error.routes';
import { memberRoutes } from './member/member.routes';
import { organizationRoutes } from './organization/organization.routes';
import { trainersRoutes } from './trainers/trainers.routes';

export const appRoutes: Routes =
	[
		{
			path: '',
			pathMatch: 'full',
			redirectTo: 'community'
		},
		{
			path: 'authentication',
			component: LayoutComponent,
			data:
			{
				layout: 'empty'
			},
			children:
				[
					...authenticationRoutes
				]
		},
		{
			path: 'signed-in-redirect',
			pathMatch: 'full',
			redirectTo: 'community'
		},
		{
			path: '',
			canActivate: [AuthGuard],
			component: LayoutComponent,
			children:
				[
					{
						path: 'community',
						children:
							[
								...communityRoutes
							]
					},
					{
						path: 'communication',
						children:
							[
								...communicationRoutes
							]
					},
					{
						path: 'client',
						children:
							[
								...clientRoutes
							]
					},
					{
						path: 'employee',
						canActivate: [EmployeeProfileGuard],
						children:
							[
								...employeeRoutes
							]
					},
					{
						path: 'member',
						canActivate: [MemberProfileGuard],
						children:
							[
								...memberRoutes
							]
					},
					{
						path: 'organization',
						children:
							[
								...organizationRoutes
							]
					},
					{
						path: 'trainers',
						canActivate: [MemberProfileGuard],
						children:
							[
								...trainersRoutes
							]
					},
					{
						path: '@pageTemplateTest',
						canActivate: [MemberProfileGuard],
						loadComponent: () => import('@fitness-central/@templates/page/page.component').then(c => c.AppPageTemplateComponent)
					},
				]
		},
		{
			path: 'error',
			canActivate: [AuthGuard],
			component: LayoutComponent,
			children:
				[
					...errorRoutes
				]
		},
		{
			path: '**',
			redirectTo: 'error/404'
		}
	];