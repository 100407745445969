import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { employeeSettingsRoutes } from './settings/settings.routes';
import { employeeTrainingRoutes } from './training/training.routes';

export const employeeRoutes: Route[] =
	[
		{
			path: 'locations',
			loadComponent: () => import('./locations/locations.component').then(c => c.EmployeeLocationsComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'settings',
			children:
				[
					...employeeSettingsRoutes
				],
			canActivate: [AuthGuard]
		},
		{
			path: 'training',
			children:
				[
					...employeeTrainingRoutes
				],
			canActivate: [AuthGuard]
		}
	];