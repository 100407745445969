import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { ClientRoutineResistanceExerciseRequestModel } from './routine-resistance-exercise.request-model';
import { ClientRoutineResistanceExerciseResponseModel } from './routine-resistance-exercise.response-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class ClientRoutineResistanceExerciseService
{
	public constructor(private http: HttpClient) { }

	public get(routineResistanceExerciseId: string): Observable<ClientRoutineResistanceExerciseResponseModel>
	{
		const url = environment.baseUrl + `/client/training/routine-resistance-exercise/${ routineResistanceExerciseId }`;

		return this.http.get<ClientRoutineResistanceExerciseResponseModel>(url);
	}

	public getByRoutineId(routineId: string): Observable<ClientRoutineResistanceExerciseResponseModel[]>
	{
		const url = environment.baseUrl + `/client/training/routine-resistance-exercises/routine/${ routineId }`;

		return this.http.get<ClientRoutineResistanceExerciseResponseModel[]>(url);
	}

	public create(request: ClientRoutineResistanceExerciseRequestModel) : Observable<ClientRoutineResistanceExerciseResponseModel>
	{
		const url = environment.baseUrl + '/client/training/routine-resistance-exercise';

		return this.http.post<ClientRoutineResistanceExerciseResponseModel>(url, request);
	}

	public update(request: ClientRoutineResistanceExerciseRequestModel) : Observable<ClientRoutineResistanceExerciseResponseModel>
	{
		const url = environment.baseUrl + `/client/training/routine-resistance-exercise/${ request.routineResistanceExerciseId }`;

		return this.http.put<ClientRoutineResistanceExerciseResponseModel>(url, request);
	}

	public updateRange(request: ClientRoutineResistanceExerciseRequestModel[]) : Observable<ClientRoutineResistanceExerciseResponseModel[]>
	{
		const url = environment.baseUrl + `/client/training/routine-resistance-exercises`

		return this.http.put<ClientRoutineResistanceExerciseResponseModel[]>(url, request);
	}
}
