<div class="flex flex-row justify-between px-6 bg-primary">
	<div class="flex flex-col pt-2 text-xl text-on-primary justify-center">Edit Body Weight Measurement</div>
	<div class="flex flex-col justify-center">
		<button (click)="cancel()"
			class="bg-accent text-on-primary my-2"
			mat-icon-button>
			<mat-icon class="text-on-accent">close</mat-icon>
		</button>
	</div>
</div>
<div class="relative flex flex-col flex-auto items-center">
	<div class="w-full max-w-3xl">
		<form [formGroup]="editForm">
			<div class="flex flex-col space-y-4 mt-8 px-6">
				<div>
					<mat-form-field appearance="outline" class="abp-mat-no-subscript flex-auto w-full">
						<mat-label>Chest Measurement Value</mat-label>
						<input type="number" matInput formControlName="value" placeholder="Enter Chest Measurement Value">
					</mat-form-field>
				</div>
				<div>
					<mat-form-field appearance="outline" >
						<mat-label>Measurement Date</mat-label>
						<input matInput formControlName="measurementDate" [matDatepicker]="measurementDateEdit" placeholder="Choose a logged date">
						<mat-datepicker-toggle matSuffix [for]="measurementDateEdit"></mat-datepicker-toggle>
						<mat-datepicker #measurementDateEdit [startAt]="measurementDateEdit" disabled="false"></mat-datepicker>
						<mat-error>Measurement Date is required!</mat-error>
					</mat-form-field>
				</div>
			</div>
		</form>
	</div>
</div>
<!-- Actions -->
<div class="absolute bottom-0 flex items-center w-full mt-10 px-6 py-4 border-t bg-gray-50">
	<button class="ml-auto w-20" 
		mat-button 
		[matTooltip]="'Cancel'" 
		(click)="cancel()"> 
		Cancel 
	</button>
	<button class="ml-2 w-20" 
		mat-flat-button 
		[color]="'primary'" 
		[disabled]="editForm.invalid" 
		[matTooltip]="'Save'" 
		(click)="update()"> 
		<span *ngIf="!editForm.disabled"> Save </span>
		<span *ngIf="editForm.disabled">
			<mat-progress-spinner  
				[diameter]="24" 
				[mode]="'indeterminate'">
			</mat-progress-spinner>
		</span> 
	</button>
</div>
