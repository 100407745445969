import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { clientBodyFatMeasurementRoutes } from './body-fat-measurement/body-fat-measurement.routes';
import { clientBodyMeasurementRoutes } from './body-measurement/body-measurement.routes';
import { clientBodyWeightMeasurementRoutes } from './body-weight/body-weight.routes';

export const clientBodyCompositionRoutes: Route[] =
	[
		{
			path: 'body-fat-measurement',
			children:
				[
					...clientBodyFatMeasurementRoutes
				],
			canActivate: [AuthGuard]
		},
		{
			path: 'body-measurement',
			children:
				[
					...clientBodyMeasurementRoutes
				],
			canActivate: [AuthGuard]
		},
		{
			path: 'body-weight',
			children:
				[
					...clientBodyWeightMeasurementRoutes
				],
			canActivate: [AuthGuard]
		},
		{
			path: '',
			loadComponent: () => import('./overview/overview.component').then(c => c.ClientDetailBodyCompositionOverviewComponent),
			canActivate: [AuthGuard]
		},
		{
			path: '**',
			redirectTo: ''
		}
	];