import { Guid } from '@abp/guid';

export class RoutineTemplateViewModel
{
	public routineId: string = Guid.empty;
	public organizationId: string = Guid.empty;
	public employeeId: string = Guid.empty;
	public name: string = '';
	public version: string = '';
	public description: string = '';
	public routineTypeId: number = 1;
	public isPublic: boolean = true;
	public isActive: boolean = true;
	public order: number = 0;
	public createdDate: Date;
	public modifiedDate: Date;
	public isEmployeeRoutine: boolean = true;
}
