@if (isLoading()) 
{
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
}
@else 
{
	<ui-sidebar
		[title]="'Create Routine Template'"
		[isFormValid]="createForm.valid"
		[isFormDisabled]="createForm.disabled"
		(onCancel)="cancel()"
		(onSave)="create()">

		<ng-container content>
			<div class="relative flex flex-col flex-auto items-center">
				<div class="w-full max-w-3xl">
					<form [formGroup]="createForm">
						<div class="flex flex-col space-y-4 mt-8 px-6">
							<div>
								<mat-form-field appearance="outline" class="abp-mat-no-subscript w-full">
									<mat-label>Description</mat-label>
									<mat-icon
										matPrefix
										class="hidden sm:flex icon-size-4">
										description
									</mat-icon>
									<input
										matInput
										[formControlName]="'description'"
										[placeholder]="'The Description of the Set'"
										[spellcheck]="false">
								</mat-form-field>
							</div>
							<div class="flex flex-row space-x-2">
								<div>
									<mat-form-field appearance="outline" class="abp-mat-no-subscript w-full">
										<mat-label>Rep Range Start</mat-label>
										<mat-icon
											matPrefix
											class="hidden sm:flex icon-size-4">
											repeat
										</mat-icon>
										<input
											matInput
											type="number"
											[formControlName]="'repRangeStart'"
											[placeholder]="'Rep Range Start'"
											[spellcheck]="false">
									</mat-form-field>
								</div>
								<div>
									<mat-form-field appearance="outline" class="abp-mat-no-subscript w-full">
										<mat-label>Rep Range End</mat-label>
										<mat-icon
											matPrefix
											class="hidden sm:flex icon-size-4">
											repeat
										</mat-icon>
										<input
											matInput
											type="number"
											[formControlName]="'repRangeEnd'"
											[placeholder]="'Rep Range End'"
											[spellcheck]="false">
									</mat-form-field>
								</div>
							</div>
							<div class="flex flex-row space-x-2">
								<div>
									<mat-form-field appearance="outline" class="abp-mat-no-subscript w-full">
										<mat-label>Weight</mat-label>
										<mat-icon
											matPrefix
											class="hidden sm:flex icon-size-4">
											fitness_center
										</mat-icon>
										<input
											matInput
											type="number"
											[formControlName]="'weight'"
											[placeholder]="'Weight'"
											[spellcheck]="false">
									</mat-form-field>
								</div>
								<div class="flex flex-col justify-center pt-6">
									@if (measurementSystem == 1) 
									{
										<span> Lbs</span>
									}
									@if (measurementSystem == 2) 
									{
										<span> Kg</span>
									}
								</div>
							</div>
							<div class="flex flex-row space-x-2">
								<div>
									<mat-form-field appearance="outline" class="abp-mat-no-subscript w-full">
										<mat-label>Rest</mat-label>
										<mat-icon
											matPrefix
											class="hidden sm:flex icon-size-4">
											pause
										</mat-icon>
										<input
											matInput
											type="number"
											[formControlName]="'rest'"
											[placeholder]="'Rest (seconds)'"
											[spellcheck]="false">
									</mat-form-field>
								</div>
								<div class="flex flex-col justify-center pt-6">
									Seconds
								</div>
							</div>

							<div class="flex flex-row space-x-2 pr-6 w-full">
								<div class="w-1/4">
									<mat-form-field appearance="outline" class="abp-mat-no-subscript w-full">
										<mat-label>Concentric</mat-label>
										<mat-icon
											matPrefix
											class="hidden sm:flex icon-size-4">
											timer
										</mat-icon>
										<input
											matInput
											type="number"
											[formControlName]="'concentricDuration'"
											[placeholder]="'Concentric Duration'"
											[spellcheck]="false">
									</mat-form-field>
								</div>
								<div class="w-1/4">
									<mat-form-field appearance="outline" class="abp-mat-no-subscript w-full">
										<mat-label>Pause</mat-label>
										<mat-icon
											matPrefix
											class="hidden sm:flex icon-size-4">
											timer
										</mat-icon>
										<input
											matInput
											type="number"
											[formControlName]="'concentricPause'"
											[placeholder]="'Concentric Pause'"
											[spellcheck]="false">
									</mat-form-field>
								</div>
								<div class="w-1/4">
									<mat-form-field appearance="outline" class="abp-mat-no-subscript w-full">
										<mat-label>Eccentric</mat-label>
										<mat-icon
											matPrefix
											class="hidden sm:flex icon-size-4">
											timer
										</mat-icon>
										<input
											matInput
											type="number"
											[formControlName]="'eccentricDuration'"
											[placeholder]="'Eccentric Duration'"
											[spellcheck]="false">
									</mat-form-field>
								</div>
								<div class="w-1/4">
									<mat-form-field appearance="outline" class="abp-mat-no-subscript w-full">
										<mat-label>Pause</mat-label>
										<mat-icon
											matPrefix
											class="hidden sm:flex icon-size-4">
											timer
										</mat-icon>
										<input
											matInput
											type="number"
											[formControlName]="'eccentricPause'"
											[placeholder]="'Eccentric Pause'"
											[spellcheck]="false">
									</mat-form-field>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</ng-container>
	</ui-sidebar>
}