export enum BodyFatCalculationType
{
	Manual = 1,
	JacksonPollock3Site = 2,
	JacksonPollock7Site = 3,
}

export const BodyFatCalculationTypeDescription: 
{
    [key in BodyFatCalculationType]: string;
} = 
{
    [BodyFatCalculationType.Manual]: 'Manual Entry',
    [BodyFatCalculationType.JacksonPollock3Site]: 'Jackson-Pollock 3-Site',
    [BodyFatCalculationType.JacksonPollock7Site]: 'Jackson-Pollock 7-Site',
};