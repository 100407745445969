import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ClientBodyWeightResponseModel } from '@fitness-central/api/client/body-composition/body-weight/body-weight-response.model';
import { ClientBodyWeightService } from '@fitness-central/api/client/body-composition/body-weight/body-weight.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { NgIf } from '@angular/common';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

@Component({
    selector: 'client-detail-body-composition-body-weight-sidebar-create',
    templateUrl: './create.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatLegacyButtonModule, MatIconModule, FormsModule, ReactiveFormsModule, MatLegacyFormFieldModule, MatLegacyInputModule, MatDatepickerModule, MatLegacyTooltipModule, NgIf, MatLegacyProgressSpinnerModule]
})

export class ClientDetailBodyCompositionBodyWeightSidebarCreateComponent implements OnInit, OnDestroy
{
	@Input() clientId : string;

	@Output() onCreated = new EventEmitter<ClientBodyWeightResponseModel>();
	@Output() onCancel = new EventEmitter();

	@ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

	public bodyWeightMeasurement: ClientBodyWeightResponseModel = new ClientBodyWeightResponseModel();
	public bodyWeightMeasurementForm : FormGroup;

	private _unsubscribeAll: Subject<any>;

	public constructor
	(
		private _formBuilder : FormBuilder,
		private _bodyWeightMeasurementService : ClientBodyWeightService
	)
	{
		this._unsubscribeAll = new Subject<any>();
	}

	public ngOnInit(): void
	{
		this.buildForm();
	}

	private buildForm()
	{
		this.bodyWeightMeasurementForm = this._formBuilder.group
		(
			{
				clientId: [this.clientId],
				value: ['', [Validators.required, Validators.pattern(/^[\d]{1,3}([\.|\,]\d{0,2})?$/)]],
				measurementDate: [new Date()]
			}
		);
	}

	public create()
	{
		if (this.bodyWeightMeasurementForm.valid)
		{
			this.bodyWeightMeasurementForm.disable();
			
			this._bodyWeightMeasurementService
				.create(this.bodyWeightMeasurementForm.value)
				.pipe(takeUntil(this._unsubscribeAll))
				.subscribe
				(
					{
						next: bodyWeightMeasurement =>
						{
							setTimeout
							(
								() => 
								{
									this.buildForm();
									this.formGroupDirective.resetForm();
								}
							);

							this.onCreated.emit(bodyWeightMeasurement);
						}
					}
				);
		}
	}

	public cancel()
	{
		setTimeout
		(
			() => 
			{
				this.buildForm();
				this.formGroupDirective.resetForm();
			}
		);

		this.onCancel.emit();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
