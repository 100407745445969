import { Component, ViewEncapsulation } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'authentication-pending',
    templateUrl: './pending.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    standalone: true
})
export class AuthenticationVerificationPendingComponent
{
	public constructor()
	{
	}
}
