export class PostResponse
{
	public postId: string;
	public profileId: string;
	public ownerProfileId: string;
	public title: string;
	public content: string;
	public isActive: boolean;
	public commentCount: number;
	public createdDate: Date;
	public modifiedDate: Date;

	constructor()
	{
		this.postId = '00000000-0000-0000-0000-000000000000';
		this.profileId = '00000000-0000-0000-0000-000000000000';
		this.ownerProfileId = '00000000-0000-0000-0000-000000000000';
		this.title = '';
		this.content = '';
		this.isActive = true;
		this.commentCount = 0;
		this.createdDate = new Date();
		this.modifiedDate = new Date();
	}
}
