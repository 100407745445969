export enum MemberRoutineTemplateFilterType
{
	MyRoutines = 1,
	TrainerRoutines = 2,
	InactiveRoutines = 3
}

export const MemberRoutineFilterTypeDescriptions = new Map<MemberRoutineTemplateFilterType, string>
	(
		[
			[MemberRoutineTemplateFilterType.MyRoutines, 'My Routine Templates'],
			[MemberRoutineTemplateFilterType.TrainerRoutines, 'Trainer Routines'],
			[MemberRoutineTemplateFilterType.InactiveRoutines, 'Inactive Routines']
		]
	);