import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { ClientTrainingHistoryDetailComponent } from './detail/detail.component';
import { ClientTrainingHistoryComponent } from './history.component';

export const clientTrainingHistoryRoutes: Route[] =
	[
		{
			path: '',
			component: ClientTrainingHistoryComponent,
			canActivate: [AuthGuard]
		},
		{
			path: 'detail/:workoutId',
			component: ClientTrainingHistoryDetailComponent,
			canActivate: [AuthGuard]
		}
	];