export class PostReactionResponse
{
	public postReactionId: string;
	public postId: string;
	public profileId: string;
	public reactionId: number;
	public createdDate: Date;
	public modifiedDate: Date;

	constructor()
	{
		this.postReactionId = '00000000-0000-0000-0000-000000000000';
		this.postId = '00000000-0000-0000-0000-000000000000';
		this.profileId = '00000000-0000-0000-0000-000000000000';
		this.reactionId = 0;
		this.createdDate = new Date();
		this.modifiedDate = new Date();
	}
}
