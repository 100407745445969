import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { RoutineType } from '@fitness-central/shared/enum/routine-type.enum';
import { TrainingStatusType } from '@fitness-central/shared/enum/training-status-type.enum';

import { Observable } from 'rxjs';

import { MemberWorkoutRequestModel } from './workout.request-model';
import { MemberWorkoutResponseModel } from './workout.response-model';

@Injectable
	(
		{
			providedIn: 'root'
		}
	)

export class MemberWorkoutService
{
	public constructor(private http: HttpClient) { }

	public get(workoutId: string): Observable<MemberWorkoutResponseModel>
	{
		const url = environment.baseUrl + `/member/training/workout/${workoutId}`;

		return this.http.get<MemberWorkoutResponseModel>(url);
	}

	public getAll(startDate: Date, endDate: Date, status: TrainingStatusType): Observable<MemberWorkoutResponseModel[]>
	{
		const url = environment.baseUrl + `/member/training/workouts?startDate=${startDate.toDateString()}&endDate=${endDate.toDateString()}&status=${status}`;

		return this.http.get<MemberWorkoutResponseModel[]>(url);
	}

	public getDaily(startDate: Date, endDate: Date, status: TrainingStatusType): Observable<MemberWorkoutResponseModel[]>
	{
		const url = environment.baseUrl + `/member/training/workouts?routineType=${RoutineType.Daily}&startDate=${startDate.toDateString()}&endDate=${endDate.toDateString()}&status=${status}`;

		return this.http.get<MemberWorkoutResponseModel[]>(url);
	}

	public getByRoutineId(routineId: string): Observable<MemberWorkoutResponseModel[]>
	{
		let url = environment.baseUrl + `/member/training/workouts/routine/${routineId}`;

		return this.http.get<MemberWorkoutResponseModel[]>(url);
	}

	public getByRoutineIdAndWorkoutStatus(routineId: string, status: TrainingStatusType): Observable<MemberWorkoutResponseModel[]>
	{
		let url = environment.baseUrl + `/member/training/workouts/routine/${routineId}?status=${status}`;

		return this.http.get<MemberWorkoutResponseModel[]>(url);
	}

	public create(request: MemberWorkoutRequestModel): Observable<MemberWorkoutResponseModel>
	{
		const url = environment.baseUrl + `/member/training/workout`;

		return this.http.post<MemberWorkoutResponseModel>(url, request);
	}

	public update(request: MemberWorkoutRequestModel): Observable<MemberWorkoutResponseModel>
	{
		const url = environment.baseUrl + `/member/training/workout/${request.workoutId}`;

		return this.http.put<MemberWorkoutResponseModel>(url, request);
	}

	public updateRange(request: MemberWorkoutRequestModel[]): Observable<MemberWorkoutResponseModel[]>
	{
		const url = environment.baseUrl + `/member/training/workouts`;

		return this.http.put<MemberWorkoutResponseModel[]>(url, request);
	}
}
