import { Injectable, signal, WritableSignal } from '@angular/core';

import { ImageResponse } from '@fitness-central/api/community/image/image.response-model';
import { ProfileResponseModel } from '@fitness-central/api/community/profile/profile.response-model';
import { EmployeeResponseModel } from '@fitness-central/api/employee/employee-response.model';
import { GenderType } from '@fitness-central/api/general/gender-type/gender-type.enum';
import { LoginResponseModel } from '@fitness-central/api/identity/login/login.response-model';
import { UserPreferenceResponse } from '@fitness-central/api/identity/user-preference/user-preference.response-model';
import { MemberResponseModel } from '@fitness-central/api/member/member/member.response-model';
import { MeasurementSystemType } from '@fitness-central/shared/enum/measurement-system-type.enum';

@Injectable
	(
		{
			providedIn: 'root'
		}
	)
export class SessionStorageHelper
{
	public profileImageUrl: WritableSignal<string>;
	public displayName: WritableSignal<string>;
	public firstName: WritableSignal<string>;
	public lastName: WritableSignal<string>;

	constructor()
	{
		this.profileImageUrl = signal(sessionStorage.getItem('profileImageUrl'));
		this.displayName = signal(sessionStorage.getItem('displayName'));
		this.firstName = signal(sessionStorage.getItem('firstName'));
		this.lastName = signal(sessionStorage.getItem('lastName'));
	}

	public accessToken(): string
	{
		const sessionStorageAccessToken = sessionStorage.getItem('accessToken');
		let accessToken = '';

		if (sessionStorageAccessToken === undefined || sessionStorageAccessToken === null)
		{
			accessToken = '';
		}
		else
		{
			accessToken = sessionStorageAccessToken;
		}

		return accessToken;
	}

	public refreshTokenGet(): string
	{
		const sessionStorageRefreshToken = sessionStorage.getItem('refreshToken');
		let refreshToken = '';

		if (sessionStorageRefreshToken === undefined || sessionStorageRefreshToken === null)
		{
			refreshToken = '';
		}
		else
		{
			refreshToken = sessionStorageRefreshToken;
		}

		return refreshToken;
	}

	public accessTokenSet(loginResponse: LoginResponseModel)
	{
		sessionStorage.setItem('accessToken', loginResponse.accessToken);
		sessionStorage.setItem('tokenType', loginResponse.tokenType);
		sessionStorage.setItem('refreshToken', loginResponse.refreshToken);
	}

	public memberSet(member: MemberResponseModel)
	{
		sessionStorage.setItem('firstName', member.firstName);
		this.firstName.update(() => member.firstName);

		sessionStorage.setItem('lastName', member.lastName);
		this.lastName.update(() => member.lastName);

		sessionStorage.setItem('dateOfBirth', member.dateOfBirth.toString());
		sessionStorage.setItem('gender', member.gender.toFixed());
	}

	public employeeSet(employee: EmployeeResponseModel)
	{
		sessionStorage.setItem('firstName', employee.firstName);
		this.firstName.update(() => employee.firstName);

		sessionStorage.setItem('lastName', employee.lastName);
		this.lastName.update(() => employee.lastName);

		sessionStorage.setItem('dateOfBirth', employee.dateOfBirth.toString());
		sessionStorage.setItem('gender', employee.gender.toFixed());
	}

	public profileSet(profile: ProfileResponseModel)
	{
		sessionStorage.setItem('displayName', profile.displayName);
		this.displayName.update(() => profile.displayName);
	}

	public profileImageUrlSet(profileImage: ImageResponse)
	{
		let sasUri = profileImage.sasUri ?? '';

		sessionStorage.setItem('profileImageUrl', sasUri);
		this.profileImageUrl.update(() => sasUri);
	}

	public userPreferencesSet(userPreference: UserPreferenceResponse)
	{
		console.log(`--- Measurement system set to ${userPreference.measurementSystem}`);
		sessionStorage.setItem('measurementSystem', userPreference.measurementSystem.toString());
	}

	public age(): number
	{
		const sessionStorageDateOfBirth = sessionStorage.getItem('dateOfBirth');

		let currentDate = new Date();
		let date = new Date(sessionStorageDateOfBirth);

		let age = 0;

		if (sessionStorageDateOfBirth !== undefined || sessionStorageDateOfBirth !== null)
		{
			age = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) / (1000 * 60 * 60 * 24 * 365));
		}

		return age;
	}

	public gender(): GenderType
	{
		const sessionStorageGenderType = sessionStorage.getItem('gender');

		let genderType: GenderType = GenderType.NotSelected;

		if (sessionStorageGenderType != undefined && sessionStorageGenderType != null) 
		{
			genderType = +sessionStorageGenderType;
		}

		return genderType;
	}

	public measurementSystem(): MeasurementSystemType
	{
		const sessionStorageMeasurementSystem = sessionStorage.getItem('measurementSystem');

		let measurementSystem: MeasurementSystemType = MeasurementSystemType.Imperial;

		if (sessionStorageMeasurementSystem != undefined && sessionStorageMeasurementSystem != null) 
		{
			measurementSystem = +sessionStorageMeasurementSystem;
		}

		return measurementSystem;
	}

	public clearStorage()
	{
		sessionStorage.clear();
		console.log("--- Session Storage cleared");
	}
}
