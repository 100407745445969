import { FuseNavigationItem } from '@fuse/components/navigation';

const employeeClientNavigationItem: FuseNavigationItem =
{
	id: 'clients',
	title: 'Clients',
	type: 'basic',
	icon: 'people',
	link: '/client/'
};

export const employeeClientNavigation: FuseNavigationItem[] =
	[
		employeeClientNavigationItem,
	];
