import { FuseNavigationItem } from '@fuse/components/navigation';

const memberBodyCompositionNavigationItem: FuseNavigationItem =
{
	id: 'body-composition',
	title: 'Body Composition',
	type: 'basic',
	icon: 'iconsmind:bodybuilding',
	link: '/member/body-composition/overview'
};

export const memberBodyCompositionNavigation: FuseNavigationItem[] =
	[
		memberBodyCompositionNavigationItem,
	];
