<div class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between px-2 py-2 bg-default dark:bg-transparent">
	<div class="flex-1 min-w-0">
		<div class="flex items-center">
			<div class="flex z-0 lg:hidden pr-2" *ngIf="hasRouteToBack">
				<button
					mat-icon-button
					(click)="routeToBack()">
					<mat-icon>arrow_back</mat-icon>
				</button>
			</div>

			<div class="flex lg:hidden pr-2 pl-1" *ngIf="!hasRouteToBack"></div>
			
			<div class="flex flex-row items-center w-full">
				<div class="flex flex-col text-xl leading-none items-start justify-start w-full">
					<span class="text-lg font-normal lg:flex w-full">
						<div class="flex flex-col rounded-lg items-start font-normal text-on-card p-4 w-full gap-2">
							<div class="text-lg">
								{{ title }} 
								<ng-container *ngIf="titleSegment.length">
									<span>- {{ titleSegment }}</span>
								</ng-container>
							</div>
							<div class="text-secondary text-md">
								{{ subtitle }} 
								<ng-container *ngIf="subtitleSegment.length">
									<span class="pl-1">- {{ subtitleSegment }}</span>
								</ng-container>
							</div>
						</div>
					</span>
				</div>
			</div>
		</div>
	</div>
</div>