
import { Component, EventEmitter, input, OnChanges, OnDestroy, Output, signal, viewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

import { SidebarComponent } from '@fitness-central/shared/components/ui/sidebar/sidebar.component';

import { Subject } from 'rxjs';

import { RoutineSidebarViewModel } from '../../view-models/routine-sidebar.view-model';

@Component({
	selector: 'training-routine-edit-sidebar',
	templateUrl: './edit.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		MatLegacyProgressBarModule,
		MatLegacyButtonModule,
		MatIconModule,
		FormsModule,
		ReactiveFormsModule,
		MatLegacyFormFieldModule,
		MatLegacyInputModule,
		MatLegacyTooltipModule,
		MatLegacyProgressSpinnerModule,
		SidebarComponent
	]
})

export class TrainingRoutineEditSidebarComponent implements OnChanges, OnDestroy
{
	public routine = input<RoutineSidebarViewModel>();

	@Output() onUpdate = new EventEmitter<RoutineSidebarViewModel>();
	@Output() onCancel = new EventEmitter<void>();

	public formGroupDirective = viewChild(FormGroupDirective);

	public editForm: FormGroup;

	public isLoading = signal<boolean>(false);

	private _unsubscribeAll: Subject<any>;

	public constructor
		(
			private _formBuilder: FormBuilder,
		)
	{
		this._unsubscribeAll = new Subject();
	}

	public ngOnChanges(): void
	{
		this.buildForm();
	}

	public update()
	{
		this.editForm.disable();

		const routineSidebarViewModel: RoutineSidebarViewModel = this.editForm.value;
		this.isLoading.set(false);

		setTimeout
			(
				() =>
				{
					this.buildForm();
					this.formGroupDirective().resetForm();
				}
			);

		this.onUpdate.emit(routineSidebarViewModel);
	}

	public cancel()
	{
		setTimeout
			(
				() =>
				{
					this.buildForm();
					this.formGroupDirective().resetForm();
				}
			);

		this.onCancel.emit();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}

	private buildForm()
	{
		this.editForm = this._formBuilder.group
			(
				{
					routineId: [this.routine().routineId],
					name: [this.routine().name, [Validators.required, Validators.maxLength(50)]],
					version: [this.routine().version],
					routineTypeId: [1],
					description: [this.routine().description, [Validators.maxLength(256)]],
					order: [this.routine().order],
					isActive: [this.routine().isActive]
				}
			);
	}
}
