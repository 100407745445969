import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { SubscriptionStatusType } from '@fitness-central/api/billing/subscription/subscription-status-type.enum';
import { JwtHelper } from '@fitness-central/core/helper/jwt.helper';
import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';
import { UserType } from '@fitness-central/shared/enum/user-type.enum';

import jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';

@Injectable
	(
		{
			providedIn: 'root'
		}
	)

export class AuthGuard 
{
	public constructor
		(
			private readonly _jwtHelper: JwtHelper,
			private readonly _sessionStorageHelper: SessionStorageHelper,
			private readonly _router: Router

		)
	{
	}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
	{
		const redirectUrl = state.url === '/authentication/signout' ? '/' : state.url;

		let isAuthorized: boolean = this.isLoggedIn();

		if (isAuthorized === false)
		{
			this._router.navigate
				(
					['authentication/signin'],
					{
						queryParams:
						{
							redirectUrl
						}
					}
				);

			return false;
		}

		let isNew = this._jwtHelper.isNew();
		let subscriptionStatusId = this._jwtHelper.subscriptionStatus();

		if (isNew || (subscriptionStatusId != SubscriptionStatusType.Active && subscriptionStatusId != SubscriptionStatusType.Trialing))
		{

			this.redirectIfNewOrInvalidSubscription(state);

			return isAuthorized;
		}
	}

	public isLoggedIn(): boolean
	{
		const accessToken = this._sessionStorageHelper.accessToken();

		if (accessToken === '')
		{
			return false;
		}

		let isVerified: boolean = this.isVerified();

		return isVerified;
	}

	private isVerified(): boolean
	{
		const accessToken = this._sessionStorageHelper.accessToken();

		let jwtDecoded = jwt_decode(accessToken);

		if (jwtDecoded['extension_IsVerified'] === false)
		{
			this._router.navigateByUrl("authentication/verification");
		}

		return true;
	}

	private redirectIfNewOrInvalidSubscription(state: RouterStateSnapshot)
	{
		const allowedRoutes =
			[
				'/member/settings',
				'/employee/settings',
				'/authentication/signout'
			];

		let isAllowed = allowedRoutes.some(route => state.url.startsWith(route));

		if (isAllowed)
		{
			return true;
		}
		else
		{
			let userType = this._jwtHelper.userType();

			switch (userType)
			{
				case UserType.Member:
					{
						this._router.navigate([`/member/settings/welcome`]);
						break;
					}
				case UserType.Employee:
					{
						this._router.navigate([`/employee/settings/welcome`]);
						break;
					}
			}
		}
	}
}
