

import { DatePipe, Location } from '@angular/common';
import { Component, input, OnDestroy, OnInit, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { SidebarViewComponent } from '@abp/components/sidebar-view/sidebar-view.component';

import { MemberWorkoutResistanceExerciseSetService } from '@fitness-central/api/member/training/workout-resistance-exercise-set/workout-resistance-exercise-set.service';
import { MemberWorkoutResistanceExerciseService } from '@fitness-central/api/member/training/workout-resistance-exercise/workout-resistance-exercise.service';
import { MemberWorkoutService } from '@fitness-central/api/member/training/workout/workout.service';
import { TrainingHistoryExerciseCardComponent } from '@fitness-central/shared/components/training/history/cards/exercise/exercise.component';
import { TrainingHistoryCardComponent } from '@fitness-central/shared/components/training/history/cards/workout/workout.component';
import { WorkoutResistanceExerciseSetViewModel } from '@fitness-central/shared/components/training/workout/view-models/workout-resistance-exercise-set.view-model';
import { WorkoutResistanceExerciseViewModel } from '@fitness-central/shared/components/training/workout/view-models/workout-resistance-exercise.view-model';
import { WorkoutViewModel } from '@fitness-central/shared/components/training/workout/view-models/workout.view-model';
import { PageHeaderComponent } from '@fitness-central/shared/components/ui/page-header/page-header.component';
import { TrainingStatusType } from '@fitness-central/shared/enum/training-status-type.enum';

import { concatMap, from, map, mergeMap, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'member-training-history-detail',
	templateUrl: './detail.component.html',
	standalone: true,
	imports:
		[
			SidebarViewComponent,
			PageHeaderComponent,
			MatLegacyButtonModule,
			MatIconModule,
			MatProgressBarModule,
			TrainingHistoryExerciseCardComponent,
			TrainingHistoryCardComponent,
			DatePipe
		]
})

export class MemberTrainingHistoryDetailComponent implements OnInit, OnDestroy
{
	public workoutId = input<string>();

	public workout = signal<WorkoutViewModel>(new WorkoutViewModel());
	public resistanceExercisesWithSets = signal<WorkoutResistanceExerciseViewModel[]>([]);

	public trainingStatusType = TrainingStatusType;

	public isLoading = signal<boolean>(true);

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public constructor
		(
			private _location: Location,
			private _workoutService: MemberWorkoutService,
			private _workoutResistanceExerciseService: MemberWorkoutResistanceExerciseService,
			private _workoutResistanceExerciseSetService: MemberWorkoutResistanceExerciseSetService
		)
	{
	}

	public ngOnInit()
	{
		this.getWorkout();
		this.getWorkoutResistanceExercisesWithSets();
	}

	public routeToBack()
	{
		this._location.back();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	private getWorkout()
	{
		this._workoutService
			.get(this.workoutId())
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: workout =>
					{
						this.workout.set(workout);
					}
				}
			)
	}

	private getWorkoutResistanceExercisesWithSets(): void
	{
		this._workoutResistanceExerciseService
			.getByWorkoutId(this.workoutId())
			.pipe
			(
				takeUntil(this._unsubscribeAll),
				concatMap(resistanceExercises => from(resistanceExercises)),
				mergeMap
					(
						workoutResistanceExercise => this._workoutResistanceExerciseSetService.getByWorkoutResistanceExerciseId(workoutResistanceExercise.workoutResistanceExerciseId)
							.pipe
							(
								map
									(
										workoutResistanceExerciseSets => 
										{
											let filteredWorkoutResistanceExerciseSets = workoutResistanceExerciseSets
												.filter(workoutResistanceExerciseSet => workoutResistanceExerciseSet.isActive == true)
												.sort((first, second) => first.order - second.order);

											let resistanceExerciseSets = filteredWorkoutResistanceExerciseSets.map
												(
													resistanceExerciseSet => 
													{
														let exerciseSet: WorkoutResistanceExerciseSetViewModel =
														{
															...resistanceExerciseSet,
															routineResistanceExerciseId: workoutResistanceExercise.routineResistanceExerciseId
														}

														return exerciseSet;
													}
												);

											let WorkoutWithExerciseSets: WorkoutResistanceExerciseViewModel =
											{
												workoutResistanceExerciseSets: resistanceExerciseSets,
												...workoutResistanceExercise
											};

											return WorkoutWithExerciseSets;
										}
									)
							)
					)
			)
			.subscribe
			(
				{
					next: workoutResistanceExerciseWithSets =>
					{
						this.resistanceExercisesWithSets.update
							(
								exercises => ([...exercises, workoutResistanceExerciseWithSets])
									.sort((first, second) => first.order - second.order)
							);
					},
					complete: () =>
					{
						this.isLoading.set(false);
					}
				}
			)
	}
}
