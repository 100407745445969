<!-- Button -->
<button
    mat-icon-button
    [matTooltip]="tooltip || 'Toggle Fullscreen'"
    (click)="toggleFullscreen()">
    <ng-container [ngTemplateOutlet]="iconTpl || defaultIconTpl"></ng-container>
</button>

<!-- Default icon -->
<ng-template #defaultIconTpl>
    <mat-icon>

        fullscreen

    </mat-icon>
</ng-template>
