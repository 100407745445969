import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable
	(
		{
			providedIn: 'root'
		}
	)
export class ErrorInterceptor implements HttpInterceptor
{
	public constructor
		(
			public _router: Router
		)
	{
	}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
	{
		return next
			.handle(request)
			.pipe
			(
				catchError
					(
						err =>
						{
							if (err.status === 401)
							{
								this._router.navigate(['/authentication/signout']);
							}
							else
							{
								console.error(err);
								return throwError(() => err);
							}
						}
					)
			)
	}
}
