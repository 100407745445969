import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { memberBodyCompositionBodyMeasurementRoutes } from './body-measurement/body-measurement.routes';

export const memberBodyCompositionRoutes: Route[] =
	[
		{
			path: 'body-fat-measurements',
			loadComponent: () => import('./body-fat-measurement/body-fat-measurement.component').then(c => c.MemberBodyCompositionBodyFatMeasurementComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'body-measurements',
			loadComponent: () => import('./overview/overview.component').then(c => c.MemberBodyCompositionOverviewComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'body-measurement-detail',
			children:
				[
					...memberBodyCompositionBodyMeasurementRoutes
				],
			canActivate: [AuthGuard]
		},
		{
			path: 'body-weight-measurements',
			loadComponent: () => import('./body-weight/body-weight.component').then(c => c.MemberBodyCompositionBodyWeightComponent),
			canActivate: [AuthGuard]
		},
		{
			path: '**',
			redirectTo: ''
		}
	];