import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { employeeRoutineTemplateRoutes } from './routine-templates/routine-template.routes';

export const employeeTrainingRoutes: Route[] =
	[
		{
			path: 'routine-templates',
			children:
				[
					...employeeRoutineTemplateRoutes
				],
			canActivate: [AuthGuard]
		}
	];