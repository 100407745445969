import { Component, OnInit, signal, viewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { Router } from '@angular/router';

import { DragDropCardContainerComponent } from '@abp/components/drag-drop-card-container/drag-drop-card-container.component';
import { SidebarViewComponent } from '@abp/components/sidebar-view/sidebar-view.component';
import { Guid } from '@abp/guid';
import { EnumDescriptionPipe } from '@abp/pipes/enum/enum-description.pipe';

import { OrganizationRoutineResponseModel } from '@fitness-central/api/organization/training/routine/routine.response-model';
import { OrganizationRoutineService } from '@fitness-central/api/organization/training/routine/routine.service';
import { JwtHelper } from '@fitness-central/core/helper/jwt.helper';
import { TrainingRoutineTemplateCardComponent } from '@fitness-central/shared/components/training/routine-templates/cards/routine/card.component';
import { TrainingRoutineCreateSidebarComponent } from '@fitness-central/shared/components/training/routine-templates/sidebars/routine/create/create.component';
import { TrainingRoutineEditSidebarComponent } from '@fitness-central/shared/components/training/routine-templates/sidebars/routine/edit/edit.component';
import { PageHeaderComponent } from '@fitness-central/shared/components/ui/page-header/page-header.component';

import { Subject, takeUntil } from 'rxjs';

import { EmployeeRoutineFilterType, EmployeeRoutineFilterTypeDescriptions } from '../../_enums/routine-filter-type.enum';
import { RoutineTemplateViewModel } from '../../view-models/routine.view-model';
import { TrainingEmployeeRoutineNavigationComponent } from './_components/navigation/navigation.component';

@Component({
	selector: 'training-routine-list',
	templateUrl: './list.component.html',
	standalone: true,
	imports:
		[
			DragDropCardContainerComponent,
			EnumDescriptionPipe,
			MatIconModule,
			MatLegacyButtonModule,
			MatLegacyProgressBarModule,
			MatLegacyTooltipModule,
			PageHeaderComponent,
			SidebarViewComponent,
			TrainingRoutineTemplateCardComponent,
			TrainingRoutineCreateSidebarComponent,
			TrainingRoutineEditSidebarComponent,
			TrainingEmployeeRoutineNavigationComponent
		]
})

export class TrainingEmployeeRoutineListComponent implements OnInit
{
	private sidebarView = viewChild(SidebarViewComponent)

	public filteredRoutines = signal<RoutineTemplateViewModel[]>([]);

	public allRoutines = signal<RoutineTemplateViewModel[]>([]);

	public allEmployeeRoutines: RoutineTemplateViewModel[] = [];
	public activeEmployeeRoutines: RoutineTemplateViewModel[] = [];
	public inactiveEmployeeRoutines: RoutineTemplateViewModel[] = [];

	public allOrganizationRoutines: RoutineTemplateViewModel[] = [];
	public activeOrganizationRoutines: RoutineTemplateViewModel[] = [];
	public inactiveOrganizationRoutines: RoutineTemplateViewModel[] = [];

	public selectedRoutine = signal<RoutineTemplateViewModel>(new RoutineTemplateViewModel());

	public selectedRoutineFilterType = signal<EmployeeRoutineFilterType>(EmployeeRoutineFilterType.MyRoutines);

	public routineFilterTypes: typeof EmployeeRoutineFilterType = EmployeeRoutineFilterType;
	public routineFilterTypeDescriptions = EmployeeRoutineFilterTypeDescriptions;

	public activeEmployeeId: string = Guid.empty;

	public isLoading = signal<boolean>(true);

	private _unsubscribeAll: Subject<any>;

	public constructor
		(
			private _organizationRoutineService: OrganizationRoutineService,
			private _jwtHelper: JwtHelper,
			private _router: Router
		)
	{
		this.activeEmployeeId = this._jwtHelper.employeeId();
		this._unsubscribeAll = new Subject();
	}

	public ngOnInit(): void
	{
		this.getRoutines()
	}

	public showCreateSidebar(): void
	{
		this.sidebarView().openCreateSidebar();
	}

	public showEditSidebar(routine: RoutineTemplateViewModel): void
	{
		this.selectedRoutine.set(routine);
		this.sidebarView().openEditSidebar();
	}

	public closeSidebar(): void
	{
		this.sidebarView().closeSidebar();
	}

	public closeNavigation(): void
	{
		this.sidebarView().closeNavigation();
	}

	public toggleNavigation(): void
	{
		this.sidebarView().toggleNavigation();
	}

	public changeRoutineFilter(routineFilterType: EmployeeRoutineFilterType): void
	{
		this.closeNavigation();

		this.selectedRoutineFilterType.set(routineFilterType);
		this.filterRoutines();
	}

	public changeActive(): void
	{
		this.filterRoutines();

		this.activeEmployeeRoutines.forEach((routine, index) => 
		{
			routine.order = index + 1;
		});

		this.inactiveEmployeeRoutines.forEach((routine, index) => 
		{
			routine.order = index + 1;
		});

		this.updateRoutines();
	}

	public selectRoutine(routine: RoutineTemplateViewModel): void
	{
		this._router.navigateByUrl(`/employee/training/routine-templates/routine/${routine.routineId}/detail`);
	}

	public createRoutine(routine: RoutineTemplateViewModel): void
	{
		routine.order = this.activeEmployeeRoutines.length + 1;

		this._organizationRoutineService
			.create(routine)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: routine =>
					{
						this.isLoading.set(false);

						this.closeSidebar();

						this._router.navigateByUrl(`/employee/training/routine-templates/routine/${routine.routineId}/detail`);
					}
				}
			)
	}

	public updateRoutine(routine: RoutineTemplateViewModel): void
	{
		this._organizationRoutineService
			.update(routine)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: organizationRoutine =>
					{
						const isEmployeeRoutine = this.activeEmployeeId === organizationRoutine.employeeId;

						let employeeRoutine: RoutineTemplateViewModel =
						{
							...routine,
							isEmployeeRoutine: isEmployeeRoutine,
						}

						this.allEmployeeRoutines = this.allEmployeeRoutines.map(item => item.routineId === employeeRoutine.routineId ? { ...employeeRoutine } : item);

						this.filterRoutines();

						this.closeSidebar();
					}
				}
			)
	}

	public updateRoutines(): void
	{
		this._organizationRoutineService
			.updateRange(this.allEmployeeRoutines)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: organizationRoutines =>
					{
						this.allRoutines.update
							(
								routines => routines.map
									(
										routine =>
										{
											const updatedItem = organizationRoutines.find(updatedRoutine => updatedRoutine.routineId === routine.routineId);

											return updatedItem ? { ...routine, ...updatedItem } : routine;
										}
									)
							);

						this.filterRoutines();
					}
				}
			)
	}

	private getRoutines(): void
	{
		this._organizationRoutineService
			.getAll()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: organizationRoutines =>
					{
						this.buildRoutineTemplateViewModels(organizationRoutines);

						this.filterRoutines();
						this.isLoading.set(false);
					}
				}
			)
	}

	private buildRoutineTemplateViewModels(organizationRoutines: OrganizationRoutineResponseModel[]): void
	{
		let routines: RoutineTemplateViewModel[] = [];

		organizationRoutines.map
			(
				organizationRoutine =>
				{
					const isEmployeeRoutine = this.activeEmployeeId === organizationRoutine.employeeId;

					let routine: RoutineTemplateViewModel =
					{
						...organizationRoutine,
						isEmployeeRoutine: isEmployeeRoutine
					}

					routines.push(routine);
				}
			);

		this.allRoutines.set(routines);
	}

	private filterRoutines(): void
	{
		this.allEmployeeRoutines = this.allRoutines().filter(routine => routine.isEmployeeRoutine);
		this.allOrganizationRoutines = this.allRoutines().filter(routine => !routine.isEmployeeRoutine);

		this.activeEmployeeRoutines = this.allRoutines()
			.filter(routine => routine.isActive && routine.isEmployeeRoutine)
			.sort((first, second) => first.order - second.order);

		this.inactiveEmployeeRoutines = this.allRoutines()
			.filter(routine => !routine.isActive && routine.isEmployeeRoutine)
			.sort((first, second) => first.order - second.order);

		this.activeOrganizationRoutines = this.allRoutines()
			.filter(routine => routine.isActive && !routine.isEmployeeRoutine)
			.sort((first, second) => first.order - second.order);

		this.inactiveOrganizationRoutines = this.allRoutines()
			.filter(routine => !routine.isActive && !routine.isEmployeeRoutine)
			.sort((first, second) => first.order - second.order);

		switch (this.selectedRoutineFilterType())
		{
			case EmployeeRoutineFilterType.MyRoutines:
				{
					this.filteredRoutines.set(this.activeEmployeeRoutines);

					break;
				}
			case EmployeeRoutineFilterType.OrganizationRoutines:
				{
					this.filteredRoutines.set(this.activeOrganizationRoutines);

					break;
				}
			case EmployeeRoutineFilterType.InactiveRoutines:
				{
					let inactiveRoutines = [];

					inactiveRoutines.push
						(
							...this.inactiveEmployeeRoutines,
							...this.inactiveOrganizationRoutines
						)

					this.filteredRoutines.set(inactiveRoutines);

					break;
				}
		}
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
