import { Route } from '@angular/router';

import { ClientDetailNavigationMenuItemType } from './_components/navigation/models/menu-item-types.enum';
import { clientBodyCompositionRoutes } from './tabs/body-composition/body-composition.routes';
import { clientDetailHomeRoutes } from './tabs/home/home.routes';
import { clientTrainingRoutes } from './tabs/training/training.routes';

export const clientsDetailRoutes: Route[] =
	[
		{
			path: 'body-composition',
			children:
				[
					...clientBodyCompositionRoutes
				],
			data:
			{
				selectedNavigationItem: ClientDetailNavigationMenuItemType.BodyComposition
			}
		},
		{
			path: 'home',
			children:
				[
					...clientDetailHomeRoutes
				],
			data:
			{
				selectedNavigationItem: ClientDetailNavigationMenuItemType.Home
			}
		},
		{
			path: 'training',
			children:
				[
					...clientTrainingRoutes
				],
			data:
			{
				selectedNavigationItem: ClientDetailNavigationMenuItemType.Training
			}
		},
		{
			path: '**',
			redirectTo: 'home'
		}
	];