import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { memberTrainingHistoryRoutes } from './history/history.routes';
import { memberRoutineTemplateRoutes } from './routine-templates/routine-template.routes';

export const memberTrainingRoutes: Route[] =
	[
		{
			path: 'history',
			children:
				[
					...memberTrainingHistoryRoutes
				],
			canActivate: [AuthGuard]
		},
		{
			path: 'routine-templates',
			children:
				[
					...memberRoutineTemplateRoutes
				],
			canActivate: [AuthGuard]
		},
		{
			path: 'workout/daily',
			loadComponent: () => import('app/member/training/daily-workout/daily-workout.component').then(c => c.MemberTrainingDailyWorkoutComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'workout/:routineId',
			loadComponent: () => import('app/member/training/workout/workout.component').then(c => c.MemberTrainingWorkoutComponent),
			canActivate: [AuthGuard]
		}
	];