export enum MeasurementSystemType
{
	Imperial = 1,
	Metric = 2
}

export const WeightMeasurement = new Map<MeasurementSystemType, string>
	(
		[
			[MeasurementSystemType.Imperial, 'Lbs'],
			[MeasurementSystemType.Metric, 'Kgs'],
		]
	);

export const TemperatureMeasurement = new Map<MeasurementSystemType, string>
	(
		[
			[MeasurementSystemType.Imperial, '°F'],
			[MeasurementSystemType.Metric, '°C'],
		]
	);

export const DistanceMeasurement = new Map<MeasurementSystemType, string>
	(
		[
			[MeasurementSystemType.Imperial, 'm'],
			[MeasurementSystemType.Metric, 'km'],
		]
	);

export const BloodGlucoseMeasurement = new Map<MeasurementSystemType, string>
	(
		[
			[MeasurementSystemType.Imperial, 'mg/dL'],
			[MeasurementSystemType.Metric, 'mmol/L'],
		]
	);