<fuse-card class="flex flex-col min-w-70 m-2 sm:mx-4 w-100-p rounded-lg">
	<ng-container>
		<div class="flex flex-row">
			<div class="flex flex-col w-11/12">
				<div class="flex flex-row">
					<div class="flex flex-col items-center w-1/3 p-2">
						<div class="flex flex-col w-full">
							<div class="flex flex-row space-x-2 justify-center w-full mt-2 text-secondary font-normal">
								{{ clientContact.contactTypeName }}
							</div>
						</div>
					</div>
					<div class="flex flex-col items-center w-1/3 p-2">
						<div class="flex flex-col w-full">
							<div class="flex flex-row space-x-2 justify-center items-center w-100-p mt-2 text-secondary font-normal">
								{{ clientContact.value }}
							</div>
						</div>
					</div>
		
					<div class="flex flex-col items-center w-1/3 p-2">
						<div class="flex flex-col w-full">
							<div class="flex flex-row space-x-2 justify-center items-center w-100-p mt-2 text-secondary font-normal">
								<ng-container *ngIf="clientContact.isOptOut">
									Yes
								</ng-container>

								<ng-container *ngIf="!clientContact.isOptOut">
									No
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="flex flex-col justify-center w-1/12">
				<div class="flex flex-row justify-end pr-2 mt-1">
					<button 
						mat-icon-button
						(click)="toggleActionBarVisibility()">
						<mat-icon class="text-gray-300">more_vert</mat-icon>
					</button>
				</div>
			</div>

		</div>
		<div class="flex flex-col items text-md font-medium bg-accent text-on-accent" *ngIf="isActionBarVisible">
			<div class="flex flex-row justify-end">
				<div class="flex flex-col justify-center">
					<div class="flex flex-row space-x-4 mx-2 my-1">

						<button 
							mat-icon-button
							(click)="deleteClientContact()">
							<mat-icon class="text-red-400">delete</mat-icon>
						</button>

						<button 
							mat-icon-button
							(click)="editClientContact()">
							<mat-icon class="text-blue-400">edit</mat-icon>
						</button>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</fuse-card>