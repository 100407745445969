<div class="w-screen sm:w-90">
	<fuse-card class="flex flex-col -ml-3 mr-9 min-h-20 sm:min-h-40">
		<ng-container>
			<div class="flex flex-1 h-full">
				<div class="flex flex-col bg-primary p-4"></div>
				<div class="flex flex-col w-full">
					<div class="flex flex-row px-2 py-4">
						
						<div class="flex flex-col w-full justify-center items-start">
							<div class="flex flex-row font-normal break-words w-full">

								<div class="flex flex-col font-semibold w-1/5">
									Set {{ exerciseSet().order}}
								</div>
							</div>
							
							<div class="hidden sm:flex sm:flex-col">
								<div class="flex flex-row h-full">
									@if (exerciseSet().description.length) 
									{
										<div class="text-md leading-relaxed text-left">
											{{ exerciseSet().description | truncate:90:'...' }}
										</div>
									}
									@else 
									{
										<div class="text-md italic leading-relaxed text-left">
											No description...
										</div>
									}
								</div>
							</div>

							<div class="flex flex-row sm:hidden pt-2 text-md leading-relaxed text-left w-full">
								<div class="flex flex-col w-1/3">
									<div class="flex flex-+ items-center">
										<mat-icon class="text-gray-400 icon-size-4 px-2">repeat</mat-icon>
										<div class="px-2">
											{{ exerciseSet().repRangeStart }} - {{ exerciseSet().repRangeEnd }} Reps 
										</div>
									</div>
								</div>
								<div class="flex flex-col w-1/3">
									<div class="flex flex-row items-center">
										<mat-icon class="text-gray-400 icon-size-4">fitness_center</mat-icon>
										<div class="px-2">
											{{ exerciseSet().weight }}
											@if (measurementSystem == 1) 
											{
												<span> Lbs</span>
											}
											@if (measurementSystem == 2) 
											{
												<span> Kg</span>
											}
										</div>
									</div>
								</div>
								<div class="flex flex-col w-1/3">
									<div class="flex flex-row items-center">
										<mat-icon class="text-gray-400 icon-size-4">pause</mat-icon>
										<div class="px-2">
											{{ exerciseSet().rest }}s
										</div>
									</div>
								</div>
							</div>
						</div>
			
						<div class="flex flex-col justify-center">
							<div class="flex flex-row justify-end  mt-1">
								@if (canEdit() || canChangeStatus()) 
								{
									<button 
										mat-icon-button
										(click)="toggleActionBarVisibility()">
										<mat-icon class="text-gray-300">
											more_vert
										</mat-icon>
									</button>
								}
							</div>
						</div>
					</div>

					<div class="hidden sm:flex sm:flex-col">
						
						<div class="flex flex-row h-full">
							<hr class="w-11/12 border-t mx-4 my-2 place-items-center">
						</div>
						<div class="flex flex-row h-full">

							<div class="flex flex-col w-full text-md leading-relaxed pt-4">
								<div class="flex flex-row items-center">
									<div class="flex flex-col px-2">
										<mat-icon class="text-gray-400 icon-size-4">repeat</mat-icon>
									</div>
									<div class="flex flex-col w-1/3">
										Reps
									</div>
									<div class="flex flex-col w-1/3 font-semibold">
										<div>
											{{ exerciseSet().repRangeStart }} - {{ exerciseSet().repRangeEnd }}
										</div>
									</div>
								</div>
								<div class="flex flex-row items-center text-md leading-relaxed">
									<div class="flex flex-col px-2">
										<mat-icon class="text-gray-400 icon-size-4">fitness_center</mat-icon>
									</div>
									<div class="flex flex-col w-1/3">
										Weight
									</div>
									<div class="flex flex-col w-1/3 font-semibold">
										<div>
											{{ exerciseSet().weight }}
											@if (measurementSystem == 1) 
											{
												<span> Lbs</span>
											}
											@if (measurementSystem == 2) 
											{
												<span> Kg</span>
											}
										</div>
									</div>
								</div>
								<div class="flex flex-row items-center text-md leading-relaxed">
									<div class="flex flex-col px-2">
										<mat-icon class="text-gray-400 icon-size-4">pause</mat-icon>
									</div>
									<div class="flex flex-col w-1/3">
										Rest
									</div>
									<div class="flex flex-col w-1/3 font-semibold">
										<div>
											{{ exerciseSet().rest }}s
										</div>
									</div>
								</div>
								<div class="flex flex-row items-center text-md leading-relaxed pb-4">
									<div class="flex flex-col px-2">
										<mat-icon class="text-gray-400 icon-size-4">timer</mat-icon>
									</div>
									<div class="flex flex-col w-1/3">
										Cadence
									</div>
									<div class="flex flex-col w-1/3 font-semibold">
										<div>
											{{ exerciseSet().concentricDuration }}-
											{{ exerciseSet().concentricPause }}-
											{{ exerciseSet().eccentricDuration }}-
											{{ exerciseSet().eccentricPause }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
				
			@if (isActionBarVisible()) 
			{
				@if (exerciseSet().isActive) 
				{
					<div class="flex flex-col items text-md font-medium bg-accent text-on-default">
						<div class="flex flex-row justify-end">
							<div class="flex flex-col">
								<div class="flex flex-row mx-2 my-1">
									@if (canEdit()) 
									{
										<button mat-icon-button
											matTooltip="Edit Set"
											(click)="edit()">
											<mat-icon class="text-blue-400">
												edit
											</mat-icon>
										</button>
									}
									@if (canChangeStatus()) 
									{
										<button mat-icon-button
											matTooltip="Inactivate Set"
											(click)="inactivate()">
											<mat-icon class="text-red-400">
												highlight_off
											</mat-icon>
										</button>
									}
								</div>
							</div>
						</div>
					</div>
				}
				@else
				{
					<div class="flex flex-col items text-md font-medium bg-accent text-on-default">
						<div class="flex flex-row justify-end">
							<div class="flex flex-col">
								<div class="flex flex-row mx-2 my-1">
									<button mat-icon-button 
										matTooltip="Reactivate Set"
										(click)="reactivate()">
										<mat-icon class="text-green-400">check_circle_outline</mat-icon>
									</button>
								</div>
							</div>
						</div>
					</div>
				}
			}			
		</ng-container>			
	</fuse-card>
</div>