import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

import { ClientBodyWeightResponseModel } from '@fitness-central/api/client/body-composition/body-weight/body-weight-response.model';
import { ClientBodyWeightService } from '@fitness-central/api/client/body-composition/body-weight/body-weight.service';
import { FuseAlertType } from '@fuse/components/alert';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'client-detail-body-composition-body-weight-sidebar-edit',
	templateUrl: './edit.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [MatLegacyButtonModule, MatIconModule, FormsModule, ReactiveFormsModule, MatLegacyFormFieldModule, MatLegacyInputModule, MatDatepickerModule, MatLegacyTooltipModule, NgIf, MatLegacyProgressSpinnerModule]
})

export class ClientDetailBodyCompositionBodyWeightSidebarEditComponent implements OnChanges, OnDestroy
{
	@Input() bodyWeightMeasurement: ClientBodyWeightResponseModel;

	@Output() onUpdated = new EventEmitter<ClientBodyWeightResponseModel>();
	@Output() onCancel = new EventEmitter();

	public alertBodyWeight:
		{
			type: FuseAlertType,
			message: string
		} =
		{
			type: 'success',
			message: ''
		};

	public editForm: FormGroup;

	public showAlert: boolean = false;

	private _unsubscribeAll: Subject<any>;

	public constructor
		(
			private _formBuilder: FormBuilder,
			private _bodyWeightMeasurementService: ClientBodyWeightService
		)
	{
		this._unsubscribeAll = new Subject<any>();
	}

	public ngOnChanges(): void
	{
		this.buildForm();
	}

	public buildForm()
	{
		this.editForm = this._formBuilder.group
			(
				{
					bodyWeightMeasurementId: [this.bodyWeightMeasurement.bodyWeightMeasurementId, [Validators.required]],
					value: [this.bodyWeightMeasurement.value, [Validators.required, Validators.pattern(/^[\d]{1,3}([\.|\,]\d{0,2})?$/)]],
					measurementDate: [this.bodyWeightMeasurement.measurementDate, [Validators.required]]
				}
			);
	}

	public update()
	{
		if (this.editForm.valid)
		{
			this.editForm.disable();

			this._bodyWeightMeasurementService
				.update(this.editForm.value)
				.pipe(takeUntil(this._unsubscribeAll))
				.subscribe
				(
					{
						next: bodyWeightMeasurement =>
						{
							this.onUpdated.emit(bodyWeightMeasurement);
						},
						error: error =>
						{
							// Set the alert
							this.alertBodyWeight =
							{
								type: 'error',
								message: 'There was a problem. Please try again later.'
							};

							// Show the alert
							this.showAlert = true;
							console.error(error);
						}
					}
				);
		}
	}

	public cancel()
	{
		this.buildForm();
		this.onCancel.emit();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
