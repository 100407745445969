export enum UserType
{
	Member = 1,
	Employee = 2
}

export const UserTypeDescriptions = new Map<UserType, string>
(
	[
		[UserType.Member, 'Member'],
		[UserType.Employee, 'Employee'],
	]
);