import { Component, OnDestroy, OnInit, signal, viewChild } from '@angular/core';
import { Router } from '@angular/router';

import { SidebarViewComponent } from '@abp/components/sidebar-view/sidebar-view.component';

import { MemberWorkoutService } from '@fitness-central/api/member/training/workout/workout.service';
import { TrainingHistoryCardComponent } from '@fitness-central/shared/components/training/history/cards/workout/workout.component';
import { TrainingWorkoutHistoryMessageService } from '@fitness-central/shared/components/training/history/message-services/history.message-service';
import { WorkoutViewModel } from '@fitness-central/shared/components/training/workout/view-models/workout.view-model';
import { PageHeaderComponent } from '@fitness-central/shared/components/ui/page-header/page-header.component';
import { TrainingStatusType } from '@fitness-central/shared/enum/training-status-type.enum';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MemberTrainingHistoryNavigationComponent } from './_components/navigation/navigation.component';

@Component({
	selector: 'member-training-history',
	templateUrl: './history.component.html',
	standalone: true,
	imports:
		[
			SidebarViewComponent,
			PageHeaderComponent,
			MemberTrainingHistoryNavigationComponent,
			TrainingHistoryCardComponent
		]
})

export class MemberTrainingHistoryComponent implements OnInit, OnDestroy
{
	public sidebarView = viewChild(SidebarViewComponent);

	public workouts = signal<WorkoutViewModel[]>([]);

	public filterName = "Today's Workouts";

	public isLoading = signal<boolean>(true);

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public constructor
		(
			private readonly _router: Router,
			private readonly _workoutService: MemberWorkoutService,
			private readonly _trainingWorkoutHistoryMessageService: TrainingWorkoutHistoryMessageService
		)
	{
	}

	public ngOnInit()
	{
		this.subscribeToMemberTrainingWorkoutHistoryMessageService();
	}

	public showAllWorkouts()
	{
		this.filterName = "All Workouts";

		this.workouts = this.workouts;

		this.sidebarView().closeNavigation();
	}


	public continueWorkout(workout: WorkoutViewModel)
	{
		this._router.navigate([`./member/training/workout/${workout.routineId}`]);
	}

	public routeToDetail(workout: WorkoutViewModel)
	{
		this._router.navigate([`/member/training/history/detail/${workout.workoutId}`]);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	private subscribeToMemberTrainingWorkoutHistoryMessageService()
	{
		this._trainingWorkoutHistoryMessageService
			.dateFilterChangedAction$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: dateRange =>
					{
						this.getWorkouts(dateRange);
					}
				}
			)
	}

	private getWorkouts(dateRange: Date[])
	{
		let startDate = dateRange[0];
		let endDate = dateRange[1];

		this._workoutService.getAll(startDate, endDate, TrainingStatusType.None)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: workouts =>
					{
						let workoutHistory: WorkoutViewModel[] = [...workouts];

						this.workouts.set
							(
								workoutHistory.sort((first, second) => first.order - second.order)
							);

						this.isLoading.set(false);
					}
				}
			)
	}
}
