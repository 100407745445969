import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

export const memberBodyCompositionBodyMeasurementRoutes: Route[] =
	[
		{
			path: 'biceps',
			loadComponent: () => import('./biceps/biceps.component').then(c => c.MemberBodyCompositionBodyMeasurementBicepsComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'butt',
			loadComponent: () => import('./butt/butt.component').then(c => c.MemberBodyCompositionBodyMeasurementButtComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'calves',
			loadComponent: () => import('./calves/calves.component').then(c => c.MemberBodyCompositionBodyMeasurementCalvesComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'chest',
			loadComponent: () => import('./chest/chest.component').then(c => c.MemberBodyCompositionBodyMeasurementChestComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'forearms',
			loadComponent: () => import('./forearms/forearms.component').then(c => c.MemberBodyCompositionBodyMeasurementForearmsComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'hips',
			loadComponent: () => import('./hips/hips.component').then(c => c.MemberBodyCompositionBodyMeasurementHipComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'neck',
			loadComponent: () => import('./neck/neck.component').then(c => c.MemberBodyCompositionBodyMeasurementNeckComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'shoulders',
			loadComponent: () => import('./shoulders/shoulders.component').then(c => c.MemberBodyCompositionBodyMeasurementShoulderComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'thighs',
			loadComponent: () => import('./thighs/thighs.component').then(c => c.MemberBodyCompositionBodyMeasurementThighsComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'waist',
			loadComponent: () => import('./waist/waist.component').then(c => c.MemberBodyCompositionBodyMeasurementWaistComponent),
			canActivate: [AuthGuard]
		}
	];