<div class="flex flex-col h-full overflow-hidden">
	@if (showTitleBar)
	{
		<div class="flex flex-row justify-between px-6 w-full bg-primary">
			<div class="flex flex-col text-xl text-on-primary justify-center">
				{{ title }}
			</div>
			<div class="flex flex-col justify-center">
				<button mat-icon-button class="bg-accent text-on-primary my-2"
					(click)="cancel()">
					<mat-icon class="text-on-accent">
						close
					</mat-icon>
				</button>
			</div>
		</div>
	}

	<ng-content select="[fixed-content]">
	</ng-content>

    <div class="flex-1 overflow-y-scroll">
		<div class="w-full max-w-3xl">
			<ng-content select="[content]">
			</ng-content>
		</div>
    </div>

	@if (showActionBar)
	{
		<div class="flex flex-row w-full px-6 py-4 border-t bg-gray-50">
					
			@if (showCancelButton)
			{
				<button #cancelButton mat-button class="ml-auto w-20"
					(click)="cancel()">
					{{ cancelButtonLabel }}
				</button>
			}
			@else 
			{
				<div class="ml-auto w-20"></div>
			}
		
			@if (showSaveButton)
			{
				<button #saveButton mat-flat-button class="ml-2"
					[color]="'primary'"
					[disabled]="!isFormValid"
					(click)="save()">

					@if (!isFormDisabled)
					{
						<span>
							{{ saveButtonLabel }} 
						</span>
					}
					@else 
					{
						<span>
							<mat-progress-spinner  
								[diameter]="24" 
								[mode]="'indeterminate'">
							</mat-progress-spinner>
						</span>
					}
				</button>
			}

		</div>
	}
</div>