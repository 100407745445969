import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

export const communicationRoutes: Route[] =
[
	{
		path: 'messagecenter',
		loadComponent: () => import('app/communication/message-center/message-center.component').then(c => c.CommunicationMessageCenterComponent),
		canActivate: [AuthGuard]
	}
];
