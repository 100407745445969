import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

export const employeeSettingsRoutes: Route[] =
	[
		{
			path: 'account',
			loadComponent: () => import('app/employee/settings/account/account.component').then(c => c.EmployeeSettingsAccountComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'community-profile',
			loadComponent: () => import('app/employee/settings/community-profile/community-profile.component').then(c => c.EmployeeSettingsCommunityProfileComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'disciplines',
			loadComponent: () => import('app/employee/settings/discipline/discipline.component').then(c => c.EmployeeSettingsDisciplinesComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'education',
			loadComponent: () => import('app/employee/settings/education/education.component').then(c => c.EmployeeSettingsEducationComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'experience',
			loadComponent: () => import('app/employee/settings/experience/experience.component').then(c => c.EmployeeSettingsExperienceComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'finished',
			loadComponent: () => import('app/employee/settings/finished/finished.component').then(c => c.EmployeeSettingsFinishedComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'preferences',
			loadComponent: () => import('app/employee/settings/preferences/preferences.component').then(c => c.EmployeeSettingsPreferencesComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'subscription',
			loadComponent: () => import('app/employee/settings/subscription/subscription.component').then(c => c.EmployeeSettingsSubscriptionComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'welcome',
			loadComponent: () => import('app/employee/settings/welcome/welcome.component').then(c => c.EmployeeSettingsWelcomeComponent),
			canActivate: [AuthGuard]
		}
	];