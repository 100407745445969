import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from 'environments/environment';

import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';

import { Observable } from 'rxjs';

import { LoginRequestModel } from './login.request-model';
import { LoginResponseModel } from './login.response-model';

@Injectable
	(
		{
			providedIn: 'root'
		}
	)
export class LoginService
{
	public constructor
		(
			private http: HttpClient,
			private sessionStorageHelper: SessionStorageHelper,
			public router: Router
		)
	{ }

	public login(login: LoginRequestModel): Observable<LoginResponseModel>
	{
		const url = environment.baseUrl + `/identity/login`;

		return this.http.post<LoginResponseModel>(url, login);
	}

	public refreshToken(refreshToken: string): Observable<LoginResponseModel>
	{
		const url = environment.baseUrl + `/identity/refresh-token?refreshToken=${refreshToken}`;

		return this.http.get<LoginResponseModel>(url);
	}

	public logOff()
	{
		sessionStorage.clear();

		this.router.navigate(['/login']);
	}

	public isLoggedIn(): boolean
	{
		const hasAccessToken = this.sessionStorageHelper.accessToken();

		if (hasAccessToken === '')
		{
			return false;
		}

		return true;
	}
}
