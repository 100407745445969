export enum EmployeeRoutineFilterType
{
	MyRoutines = 1,
	OrganizationRoutines = 2,
	InactiveRoutines = 3
}

export const EmployeeRoutineFilterTypeDescriptions = new Map<EmployeeRoutineFilterType, string>
	(
		[
			[EmployeeRoutineFilterType.MyRoutines, 'My Routine Templates'],
			[EmployeeRoutineFilterType.OrganizationRoutines, 'Organization Routine Templates'],
			[EmployeeRoutineFilterType.InactiveRoutines, 'Inactive Routines']
		]
	);