import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { ClientDetailBodyCompositionBodyWeightComponent } from './body-weight.component';

export const clientBodyWeightMeasurementRoutes: Route[] =
[
	{
		path: '',
		component: ClientDetailBodyCompositionBodyWeightComponent,
		canActivate: [AuthGuard]
	}
];