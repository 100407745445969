import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { JwtHelper } from '@fitness-central/core/helper/jwt.helper';
import { UserType } from '@fitness-central/shared/enum/user-type.enum';

import { Observable } from 'rxjs';

@Injectable
	(
		{
			providedIn: 'root'
		}
	)

export class MemberProfileGuard 
{
	private IsMemberProfile: boolean;

	public constructor
		(
			private _router: Router,
			private _jwtHelper: JwtHelper
		)
	{
		this.IsMemberProfile = this._jwtHelper.userType() == UserType.Member;
	}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
	{
		if (this.IsMemberProfile === false)
		{
			this._router.navigateByUrl("error/404");
		}

		return true;
	}
}
