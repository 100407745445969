import { Guid } from '@abp/guid';

export class ProfileResponseModel
{
	public profileId: string = Guid.empty;
	public userId: string = Guid.empty;
	public profileTypeId: number = 1;
	public profileImageUrl: string = '';
	public handle: string = '';
	public displayName: string = '';
	public location: string = '';
	public dateOfBirth: Date = new Date();
	public isDateOfBirthVisible: boolean = false;
	public gender: number = 0;
	public isGenderVisible: boolean = false;
	public aboutDescription: string = '';
	public companyName: string = '';
	public title: string = '';
	public isNewProfile: boolean = false;
	public isDefaultProfile: boolean = false;
	public createdDate: Date = new Date();
	public modifiedDate: Date = new Date();
	public isFollowed: Boolean = false;
}
