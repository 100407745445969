<div>
	<div class="sticky top-0 z-9999">
		<div class="flex flex-row justify-between px-6 bg-primary">
			<div class="flex flex-col text-xl text-on-primary justify-center">
				{{ firstName() }} {{ lastName() }} (&#64;{{handle}})
			</div>
			<div class="flex flex-col justify-center">
				<button mat-icon-button
					class="bg-accent text-on-primary my-2"
					(click)="cancel()">
					<mat-icon class="text-on-accent" >
						close
					</mat-icon>
				</button>
			</div>
		</div>
	</div>

	<div>
		<global-user-sidebar-list-card
			name="Settings"
			description="Go to Profile Settings"
			icon="heroicons_outline:cog"
			(click)="routeToSettings()">

		</global-user-sidebar-list-card>

		<div class="flex flex-col items-start text-md font-medium bg-default text-on-default">
			<div class="flex flex-row max-w-140 items-start w-full">
				<button mat-button class="bg-default text-on-default m-2 w-full"
					(click)="signOut()">
					<div>
						Sign Out
					</div>
				</button>
			</div>
		</div>
	</div>
</div>
