import { KeyValuePipe, NgFor, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Signal, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule } from '@angular/material/legacy-select';

import { BodyFatCalculationType } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-calculation-type.enum';
import { ClientBodyFatMeasurementCreateRequestModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement-create-request.model';
import { ClientBodyFatMeasurementResponseModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement-response.model';
import { ClientBodyFatMeasurementService } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement.service';
import { ClientResponseModel } from '@fitness-central/api/client/client/client-response.model';

import { Subject, takeUntil } from 'rxjs';

import { ClientDetailBodyCompositionBodyFatMeasurementSidebarCreateJacksonPollock3SiteComponent } from './jackson-pollock-3-site/jackson-pollock-3-site.component';
import { ClientDetailBodyCompositionBodyFatMeasurementSidebarCreateJacksonPollock7SiteComponent } from './jackson-pollock-7-site/jackson-pollock-7-site.component';
import { ClientDetailBodyCompositionBodyFatMeasurementSidebarCreateManualComponent } from './manual/manual.component';

@Component({
	selector: 'client-detail-body-composition-body-fat-measurement-sidebar-create',
	templateUrl: './create.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: 
	[
		FormsModule,
		ReactiveFormsModule,
		MatLegacyButtonModule, 
		MatIconModule, 
		MatLegacySelectModule, 
		NgFor, 
		MatLegacyOptionModule, 
		NgSwitch, 
		NgSwitchCase, 
		ClientDetailBodyCompositionBodyFatMeasurementSidebarCreateManualComponent, 
		ClientDetailBodyCompositionBodyFatMeasurementSidebarCreateJacksonPollock3SiteComponent, 
		ClientDetailBodyCompositionBodyFatMeasurementSidebarCreateJacksonPollock7SiteComponent, 
		KeyValuePipe
	]
})

export class ClientDetailBodyCompositionBodyFatMeasurementSidebarCreateComponent implements OnInit, OnDestroy
{
	@Input() client: Signal<ClientResponseModel>;

	@Output() onCreated = new EventEmitter<ClientBodyFatMeasurementResponseModel>();
	@Output() onCancel = new EventEmitter();

	public selectedBodyFatCalculationType: BodyFatCalculationType = BodyFatCalculationType.Manual;
	public bodyFatCalculationTypes: typeof BodyFatCalculationType = BodyFatCalculationType;

	public bodyFatCalculationTypeList: { [key: string]: number };

	public showAlert: boolean = false;

	public bodyWeightMeasurement: ClientBodyFatMeasurementResponseModel = new ClientBodyFatMeasurementResponseModel();
	public bodyWeightMeasurementForm: FormGroup;

	private _unsubscribeAll: Subject<any>;

	public constructor
		(
			public readonly _bodyFatMeasurementService: ClientBodyFatMeasurementService
		)
	{
		this._unsubscribeAll = new Subject<any>();
	}

	public ngOnInit(): void
	{
		this.getBodyFatCalculationTypes();
	}

	public bodyFatMeasurementCreated(bodyFatMeasurement: ClientBodyFatMeasurementCreateRequestModel)
	{
		bodyFatMeasurement.clientId = this.client().clientId;
		bodyFatMeasurement.age = this.calculateAge(this.client().dateOfBirth);
		bodyFatMeasurement.gender = this.client().genderId;

		this._bodyFatMeasurementService
			.create(bodyFatMeasurement)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: bodyFatMeasurement =>
					{
						this.onCreated.emit(bodyFatMeasurement);
					}
				}
			);
	}

	public cancel()
	{
		this.onCancel.emit();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}

	private calculateAge(dateOfBirth: Date): number
	{
		let currentDate = new Date();
		let date = new Date(dateOfBirth);

		let age = 0;

		if (dateOfBirth !== undefined || dateOfBirth !== null)
		{
			age = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) / (1000 * 60 * 60 * 24 * 365));
		}

		return age;
	}

	private getBodyFatCalculationTypes()
	{
		this.bodyFatCalculationTypeList =
		{
			'Manual Entry': 1,
			'Jackson-Pollock 3-Site': 2,
			'Jackson-Pollock 7-Site': 3
		}
	}
}
