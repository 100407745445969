
import { Component, EventEmitter, input, OnChanges, OnDestroy, OnInit, Output, signal, viewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';
import { SidebarComponent } from '@fitness-central/shared/components/ui/sidebar/sidebar.component';

import { Subject } from 'rxjs';

import { ResistanceExerciseSetSidebarViewModel } from '../../view-models/reistance-exercise-set-sidebar.view-model';

@Component({
	selector: 'training-exercise-set-create-sidebar',
	templateUrl: './create.component.html',
	standalone: true,
	imports: [
		MatLegacyProgressBarModule,
		MatLegacyButtonModule,
		MatIconModule,
		FormsModule,
		ReactiveFormsModule,
		MatLegacyFormFieldModule,
		MatLegacyInputModule,
		MatLegacyTooltipModule,
		MatLegacyProgressSpinnerModule,
		SidebarComponent
	]
})

export class TrainingRoutineResistanceExerciseSetCreateSidebarComponent implements OnInit, OnChanges, OnDestroy
{
	public exercise = input<ResistanceExerciseSetSidebarViewModel>();

	@Output() onCreate = new EventEmitter<ResistanceExerciseSetSidebarViewModel>();
	@Output() onCancel = new EventEmitter();

	public formGroupDirective = viewChild(FormGroupDirective);

	public createForm: FormGroup;

	public measurementSystem: number;

	public isLoading = signal<boolean>(false);

	private _unsubscribeAll: Subject<any>;

	public constructor
		(
			private _formBuilder: FormBuilder,
			private _sessionStorageHelper: SessionStorageHelper
		)
	{
		this._unsubscribeAll = new Subject<any>();
	}

	public ngOnInit(): void
	{
		this.measurementSystem = +this._sessionStorageHelper.measurementSystem();
	}

	public ngOnChanges()
	{
		this.buildForm();
	}

	public create()
	{
		this.createForm.disable();

		const resistanceExerciseSetSidebarViewModel: ResistanceExerciseSetSidebarViewModel = this.createForm.value;
		this.isLoading.set(false);

		setTimeout
			(
				() =>
				{
					this.buildForm();
					this.formGroupDirective().resetForm();
				}
			);

		this.onCreate.emit(resistanceExerciseSetSidebarViewModel);
	}

	public cancel()
	{
		setTimeout
			(
				() =>
				{
					this.buildForm();
					this.formGroupDirective().resetForm();
				}
			);

		this.onCancel.emit();
	}

	private buildForm()
	{
		this.createForm = this._formBuilder.group
			(
				{
					routineResistanceExerciseSetId: ['00000000-0000-0000-0000-000000000000'],
					routineResistanceExerciseId: [this.exercise().routineResistanceExerciseId],
					description: ['', [Validators.maxLength(256)]],
					weight: ['', [Validators.required, Validators.pattern(/^[\d]{1,4}([\.|\,]\d{0,2})?$/)]],
					weightMeasurement: [''],
					duration: [0],
					repRangeStart: ['', [Validators.required]],
					repRangeEnd: ['', [Validators.required]],
					rest: ['', [Validators.required]],
					timeMeasurement: [this.measurementSystem],
					concentricDuration: ['1', [Validators.required]],
					concentricPause: ['0', [Validators.required]],
					eccentricDuration: ['1', [Validators.required]],
					eccentricPause: ['0', [Validators.required]],
					isActive: true
				}
			);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
