import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { ClientDetailHomeComponent } from './home.component';

export const clientDetailHomeRoutes: Route[] =
[
	{
		path: '',
		component: ClientDetailHomeComponent,
		canActivate: [AuthGuard]
	},
	{
		path: '**',
		redirectTo: ''
	}
];