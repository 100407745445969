import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { MemberTrainingHistoryDetailComponent } from './detail/detail.component';
import { MemberTrainingHistoryComponent } from './history.component';

export const memberTrainingHistoryRoutes: Route[] =
	[
		{
			path: '',
			component: MemberTrainingHistoryComponent,
			canActivate: [AuthGuard]
		},
		{
			path: 'detail/:workoutId',
			component: MemberTrainingHistoryDetailComponent,
			canActivate: [AuthGuard]
		}
	];