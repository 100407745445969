import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ui-content-header',
    templateUrl: './content-header.component.html',
    styleUrls: ['./content-header.component.css'],
    standalone: true,
    imports: [NgIf, MatLegacyButtonModule, MatIconModule]
})

export class ContentHeaderComponent implements OnInit 
{
	@Input() title : string = '';
	@Input() titleSegment : string = '';

	@Input() subtitle : string = '';
	@Input() subtitleSegment : string = '';
	
	@Input() hasRouteToBack : boolean = true;

	@Output() onRouteToBack = new EventEmitter();

	constructor() { }

	public ngOnInit() 
	{
	}

	public routeToBack()
	{
		this.onRouteToBack.emit();
	}
}
