import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { ProfileFollowRequest } from './profile-follow.request-model';
import { ProfileListResponse } from './profile-list.response-model';
import { ProfileUpdateRequest } from './profile-update.request-model';
import { ProfileResponseModel } from './profile.response-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class ProfileService
{
	public constructor(private http: HttpClient) { }

	public get() : Observable<ProfileResponseModel>
	{
		const url = environment.baseUrl + `/community/profile`

		return this.http.get<ProfileResponseModel>(url);
	}

	public getByUserId(userId : string) : Observable<ProfileResponseModel>
	{
		const url = environment.baseUrl + `/community/profile/user/${ userId }`

		return this.http.get<ProfileResponseModel>(url);
	}

	public getByProfileId(profileId : string) : Observable<ProfileResponseModel>
	{
		const url = environment.baseUrl + `/community/profile/${ profileId }`

		return this.http.get<ProfileResponseModel>(url);
	}

	public getByClientId(clientId : string) : Observable<ProfileResponseModel>
	{
		const url = environment.baseUrl + `/community/profile/client/${ clientId }`

		return this.http.get<ProfileResponseModel>(url);
	}

	public getByHandle(handle: string) : Observable<ProfileResponseModel>
	{
		const url = environment.baseUrl + `/community/profile/handle/${ handle }`

		return this.http.get<ProfileResponseModel>(url);
	}

	public search(searchText: string, lastProfileId: string, numberOfProfiles: number) : Observable<ProfileListResponse>
	{
		const url = environment.baseUrl + `/community/profile/search?searchText=${ searchText }&lastProfileId=${ lastProfileId }&numberOfProfiles=${ numberOfProfiles}`

		return this.http.get<ProfileListResponse>(url);
	}

	public follow(request: ProfileFollowRequest) : Observable<ProfileResponseModel>
	{
		const url = environment.baseUrl + `/community/profile/${ request.profileId }/follow`

		return this.http.post<ProfileResponseModel>(url, request);
	}

	public unFollow(request: ProfileFollowRequest) : Observable<ProfileResponseModel>
	{
		const url = environment.baseUrl + `/community/profile/${ request.profileId }/unfollow`

		return this.http.post<ProfileResponseModel>(url, request);
	}

	public getFollowed(profileId: string, lastProfileId: string, numberOfProfiles: number) : Observable<ProfileListResponse>
	{
		const url = environment.baseUrl + `/community/profile/${ profileId }/followed?lastProfileId=${ lastProfileId }&numberOfProfiles=${ numberOfProfiles }`

		return this.http.get<ProfileListResponse>(url);
	}

	public getFollowers(profileId: string, lastProfileId: string, numberOfProfiles: number) : Observable<ProfileListResponse>
	{
		const url = environment.baseUrl + `/community/profile/${ profileId }/followers?lastProfileId=${ lastProfileId }&numberOfProfiles=${ numberOfProfiles }`

		return this.http.get<ProfileListResponse>(url);
	}

	public getFollowedCount(profileId: string) : Observable<number>
	{
		const url = environment.baseUrl + `/community/profile/${ profileId }/followed/count`

		return this.http.get<number>(url);
	}

	public getFollowerCount(profileId: string) : Observable<number>
	{
		const url = environment.baseUrl + `/community/profile/${ profileId }/follower/count`

		return this.http.get<number>(url);
	}

	public update(request: ProfileUpdateRequest) : Observable<ProfileResponseModel>
	{
		const url = environment.baseUrl + `/community/profile/${ request.profileId }`

		return this.http.put<ProfileResponseModel>(url, request);
	}

	public updateRange(request: ProfileUpdateRequest[]) : Observable<ProfileResponseModel[]>
	{
		const url = environment.baseUrl + `/community/profiles`

		return this.http.put<ProfileResponseModel[]>(url, request);
	}
}
