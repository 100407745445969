import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { SystemMessageRequestModel } from './system-message.request-model';
import { SystemMessageResponseModel } from './system-message.response-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class SystemMessageService
{
	public constructor(private http: HttpClient) { }

	public get(systemMessageId : string) : Observable<SystemMessageResponseModel>
	{
		const url = environment.baseUrl + `/communication/system-message/${ systemMessageId }`;

		return this.http.get<SystemMessageResponseModel>(url);
	}

	public getReceived(startDate : Date, endDate : Date) : Observable<SystemMessageResponseModel[]>
	{
		const url = environment.baseUrl + `/communication/system-messages/received?startDate=${ startDate.toDateString() }&endDate=${ endDate.toDateString() }`;

		return this.http.get<SystemMessageResponseModel[]>(url);
	}

	public update(request: SystemMessageRequestModel) : Observable<SystemMessageResponseModel>
	{
		const url = environment.baseUrl + `/communication/system-message/${ request.systemMessageId }`

		return this.http.put<SystemMessageResponseModel>(url, request);
	}
}
