import { NgModule, Optional, SkipSelf } from '@angular/core';

import { IconsModule } from '@fitness-central/core/icons/icons.module';
import { TranslocoCoreModule } from '@fitness-central/core/transloco/transloco.module';

@NgModule
(
	{
		imports:
		[
			IconsModule,
			TranslocoCoreModule
		]
	}
)
export class CoreModule
{
	public constructor
	(
		@Optional() @SkipSelf() parentModule?: CoreModule
	)
	{
		// Do not allow multiple injections
		if ( parentModule )
		{
			throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
		}
	}
}
