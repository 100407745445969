import { AsyncPipe, KeyValuePipe } from '@angular/common';
import { Component, effect, EventEmitter, input, OnDestroy, OnInit, Output, viewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

import { EnumDescriptionPipe } from '@abp/pipes/enum/enum-description.pipe';

import { ResistanceExerciseResponse } from '@fitness-central/api/training/resistance-exercise/resistance-exercise.response-model';
import { SidebarComponent } from '@fitness-central/shared/components/ui/sidebar/sidebar.component';
import { ResistanceType, ResistanceTypeDescriptions } from '@fitness-central/shared/enum/resistance-type.enum';

import { Subject } from 'rxjs';

import { TrainingExerciseCardComponent } from '../components/card/card.component';

@Component({
	selector: 'training-exercise-edit-sidebar',
	templateUrl: './edit.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		AsyncPipe,
		EnumDescriptionPipe,
		FormsModule,
		KeyValuePipe,
		MatIconModule,
		MatLegacyAutocompleteModule,
		MatLegacyButtonModule,
		MatLegacyFormFieldModule,
		MatLegacyInputModule,
		MatLegacyOptionModule,
		MatLegacyProgressBarModule,
		MatLegacyProgressSpinnerModule,
		MatLegacyTooltipModule,
		MatLegacySelectModule,
		ReactiveFormsModule,
		SidebarComponent,
		TrainingExerciseCardComponent
	]
})

export class TrainingRoutineResistanceExerciseEditSidebarComponent implements OnInit, OnDestroy
{
	public exercise = input<ResistanceExerciseResponse>();
	public exercises = input<ResistanceExerciseResponse[]>();

	@Output() onUpdate = new EventEmitter<ResistanceExerciseResponse>();
	@Output() onCancel = new EventEmitter();

	public selectedExerciseId = 0;

	public formGroupDirective = viewChild(FormGroupDirective);
	public editForm: FormGroup;

	public resistanceTypes = ResistanceType;
	public resistanceTypeDescriptions = ResistanceTypeDescriptions;

	public filteredExercises: ResistanceExerciseResponse[] = [];

	public readonly updateSelectedExercise = effect(() =>
	{
		this.select(this.exercise().resistanceExerciseId);
		this.buildExerciseList();
	});

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public constructor
		(
			private readonly _formBuilder: FormBuilder
		)
	{
	}

	public ngOnInit(): void
	{
		this.buildForm();
		this.buildExerciseList();
	}

	public edit()
	{
		this.editForm.disable();

		this.resetForm();

		const selectedExercise = this.exercises().find(x => x.resistanceExerciseId == this.selectedExerciseId);
		this.onUpdate.emit(selectedExercise);
	}

	public cancel()
	{
		this.resetForm();

		this.onCancel.emit();
	}

	public select(resistanceExerciseId: number): void
	{
		this.selectedExerciseId = resistanceExerciseId;
		this.editForm.controls['resistanceExerciseId'].setValue(resistanceExerciseId);
	}

	private buildForm()
	{
		this.editForm = this._formBuilder.group
			(
				{
					resistanceExerciseId: [this.exercise().resistanceExerciseId, [Validators.required, Validators.min(1)]],
					resistanceExerciseName: [''],
					resistanceType: [ResistanceType.All]
				}
			);
	}

	public buildExerciseList(): void
	{
		const sortedExercises = this.exercises().sort((a, b) => 
		{
			if (a.resistanceExerciseId === this.selectedExerciseId) 
			{
				return -1;
			}

			if (b.resistanceExerciseId === this.selectedExerciseId) 
			{
				return 1;
			}

			return a.name.localeCompare(b.name);
		});

		this.filteredExercises = sortedExercises;
	}

	public filterExercises(): void
	{
		this.select(0);

		const filterValue = this.editForm.controls['resistanceExerciseName'].value.toLowerCase();

		let filteredExercises = this.exercises().filter
			(
				exercise => exercise.name.toLowerCase().includes(filterValue)
			);

		const resistanceType = this.editForm.controls['resistanceType'].value;

		if (resistanceType != 0)
		{
			filteredExercises = filteredExercises.filter(exercise => exercise.resistanceTypeId == resistanceType);
		}

		this.filteredExercises = filteredExercises;
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	private resetForm()
	{
		setTimeout
			(
				() =>
				{
					this.buildForm();
					this.formGroupDirective().resetForm();
					this.filterExercises()
				}
			);
	}
}
