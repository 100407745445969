import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { TrainingEmployeeExerciseDetailComponent } from './exercise/detail/detail.component';
import { TrainingEmployeeRoutineDetailComponent } from './routine/detail/detail.component';
import { TrainingEmployeeRoutineListComponent } from './routine/list/list.component';

export const employeeRoutineTemplateRoutes: Route[] =
	[
		{
			path: 'routine/list',
			component: TrainingEmployeeRoutineListComponent,
			canActivate: [AuthGuard]
		},
		{
			path: 'routine/:routineId/detail',
			component: TrainingEmployeeRoutineDetailComponent,
			data:
			{
				isEmployeeRoutine: true
			},
			canActivate: [AuthGuard]
		},
		{
			path: 'trainer-routine/:routineId/detail',
			component: TrainingEmployeeRoutineDetailComponent,
			data:
			{
				isEmployeeRoutine: false
			},
			canActivate: [AuthGuard]
		},
		{
			path: 'exercise/:resistanceExerciseId/detail',
			component: TrainingEmployeeExerciseDetailComponent,
			data:
			{
				isEmployeeExercise: true
			},
			canActivate: [AuthGuard]
		},
		{
			path: 'trainer-exercise/:resistanceExerciseId/detail',
			component: TrainingEmployeeExerciseDetailComponent,
			data:
			{
				isEmployeeExercise: false
			},
			canActivate: [AuthGuard]
		},
		{
			path: '**',
			redirectTo: ''
		}
	];