import { ClientBodyFatSiteMeasurementType } from './body-fat-site-measurement-type.enum';

export class ClientBodyFatSiteMeasurementRequestModel
{
	public bodyFatSiteMeasurementTypeId : ClientBodyFatSiteMeasurementType;
	public value: number;

	constructor()
	{
		this.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Abdomen;
		this.value = 0;
	}
}