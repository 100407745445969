import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { ResistanceExerciseResponse } from './resistance-exercise.response-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class ResistanceExerciseService
{
	public constructor(private http: HttpClient) { }

	public getAll() : Observable<ResistanceExerciseResponse[]>
	{
		const url = environment.baseUrl + `/training/resistance-exercises`

		return this.http.get<ResistanceExerciseResponse[]>(url);
	}
}
