import { DatePipe, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, WritableSignal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { Router } from '@angular/router';

import { MessageCenterMessageService } from 'app/communication/message-center/_message-services/message-center.message-service';

import { SystemMessageRequestModel } from '@fitness-central/api/communication/system-message/system-message.request-model';
import { SystemMessageResponseModel } from '@fitness-central/api/communication/system-message/system-message.response-model';
import { SystemMessageService } from '@fitness-central/api/communication/system-message/system-message.service';
import { CommunityMessageRequestModel } from '@fitness-central/api/community/message/message.request-model';
import { CommunityMessageResponseModel } from '@fitness-central/api/community/message/message.response-model';
import { CommunityMessageService } from '@fitness-central/api/community/message/message.service';
import { ProfileResponseModel } from '@fitness-central/api/community/profile/profile.response-model';
import { ProfileService } from '@fitness-central/api/community/profile/profile.service';
import { MessageCenterMessageType } from '@fitness-central/shared/enum/message-type.enum';

import { forkJoin, Observable, Subject, takeUntil } from 'rxjs';

import { MessageListCardComponent } from './_components/list-card/list-card.component';
import { MessageListViewModel } from './_view-models/message-list.view-model';
import { MessageViewModel } from './_view-models/message.view-model';
import { MessageSidebarMessageService } from './message-sidebar.message-service';

@Component({
	selector: 'layout-message-sidebar',
	templateUrl: './message-sidebar.component.html',
	standalone: true,
	imports: [MatLegacyButtonModule, MatIconModule, NgIf, NgFor, MessageListCardComponent, DatePipe]
})

export class LayoutMessageSidebarComponent implements OnInit, OnDestroy
{
	@Input() unreadMessageCount: WritableSignal<number>;
	@Output() onCancel = new EventEmitter();

	public messageLists: MessageListViewModel[] = [];
	public today: Date = new Date(Date.now());

	private messages: MessageViewModel[] = [];

	public isLoading: boolean = true;

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	public constructor
		(
			private readonly _messageCenterMessageService: MessageCenterMessageService,
			private readonly _messageSidebarMessageService: MessageSidebarMessageService,
			private readonly _communityMessageService: CommunityMessageService,
			private readonly _profileService: ProfileService,
			private readonly _router: Router,
			private readonly _systemMessageService: SystemMessageService
		) 
	{
	}

	public ngOnInit() 
	{
		this.getMessages();
		this.subscribeToMessageCenterDeleteMessage();
		this.subscribeToMessageCenterMarkAsRead();
	}

	public selectMessage(selectedMessage: MessageViewModel)
	{
		this._messageSidebarMessageService.selectMessage(selectedMessage);
		this.routeToMessageCenter();
	}

	public deleteMessage(deletedMessage: MessageViewModel)
	{
		if (deletedMessage.isActive)
		{
			deletedMessage.isActive = false;

			switch (deletedMessage.messageType)
			{
				case MessageCenterMessageType.Community:
					{
						this.updateCommunityMessage(deletedMessage);
						break;
					}

				case MessageCenterMessageType.System:
					{
						this.updateSystemMessage(deletedMessage);
						break;
					}
			}

			this._messageSidebarMessageService.deleteMessage(deletedMessage);
		}
	}

	public cancel()
	{
		this.onCancel.emit();
	}

	public routeToMessageCenter()
	{
		this.onCancel.emit();
		this._router.navigateByUrl('/communication/messagecenter');
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}

	private getMessages()
	{
		const defaultNumberOfDays: number = 7;

		let endDate = new Date(Date.now());
		let startDate = new Date();

		startDate.setDate(endDate.getDate() - defaultNumberOfDays);

		let systemMessages = this._systemMessageService.getReceived(startDate, endDate);
		let communityMessages = this._communityMessageService.getReceived(startDate, endDate);

		forkJoin
			(
				[
					systemMessages,
					communityMessages
				]
			)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next:
						(
							[
								systemMessages,
								communityMessages,
							]
						) =>
						{
							if (!systemMessages.length && !communityMessages.length)
							{
								this.isLoading = false;
							}
							else
							{
								this.buildSystemMessageModels(systemMessages);
								this.buildCommunityMessageModels(communityMessages);
								this.buildMessageLists();
							}
						}
				}
			)
	}

	private async subscribeToMessageCenterMarkAsRead()
	{
		this._messageCenterMessageService
			.markMessageAsReadAction$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: readMessage =>
					{
						this.updateMessageList(readMessage);
					}
				}
			)
	}

	private subscribeToMessageCenterDeleteMessage()
	{
		this._messageCenterMessageService
			.deleteMessageAction$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: deletedMessage =>
					{
						this.updateMessageList(deletedMessage);
					}
				}
			)
	}

	private updateMessageList(updatedMessage: MessageViewModel)
	{
		let messageIndex = this.messages.findIndex(message => message.messageId == updatedMessage.messageId);
		this.messages.splice(messageIndex, 1, updatedMessage);

		this.buildMessageLists();
		this.updateUnreadMessageCount();
	}

	private updateCommunityMessage(message: MessageViewModel)
	{
		let communityMessage: CommunityMessageRequestModel =
		{
			messageId: message.messageId,
			profileId: message.id,
			...message
		};

		this._communityMessageService
			.update(communityMessage)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: () =>
					{
						this.updateMessageList(message);
					}
				}
			)
	}

	private updateSystemMessage(message: MessageViewModel)
	{
		let systemMessage: SystemMessageRequestModel =
		{
			systemMessageId: message.messageId,
			...message
		};

		this._systemMessageService
			.update(systemMessage)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: () =>
					{
						this.updateMessageList(message);
					}
				}
			)
	}

	private buildSystemMessageModels(systemMessages: SystemMessageResponseModel[])
	{
		systemMessages
			.filter(message => message.isActive)
			.map
			(
				systemMessage => 
				{
					let message = new MessageViewModel();

					message =
					{
						messageId: systemMessage.systemMessageId,
						id: systemMessage.userId,
						senderId: systemMessage.senderUserId,
						messageType: MessageCenterMessageType.System,
						displayName: 'Fitness Central',
						imageSasUri: '',
						messageTypeId: systemMessage.systemMessageTypeId,
						...systemMessage,
					}

					this.messages.push(message);
				}
			)
	}

	private buildCommunityMessageModels(communityMessages: CommunityMessageResponseModel[]) 
	{
		communityMessages
			.filter(message => message.isActive)
			.forEach
			(
				communityMessage =>
				{
					let message = new MessageViewModel();

					message =
					{
						messageId: communityMessage.messageId,
						id: communityMessage.profileId,
						senderId: communityMessage.senderProfileId,
						messageType: MessageCenterMessageType.Community,
						messageTypeId: communityMessage.communityMessageTypeId,
						...communityMessage,
					}

					this.messages.push(message);
				}
			)
	}

	private getProfiles(senderUserIds: string[]): Observable<ProfileResponseModel[]>
	{
		let profileTasks: Observable<ProfileResponseModel>[] = []

		senderUserIds.forEach
			(
				senderUserId =>
				{
					profileTasks.push(this._profileService.getByUserId(senderUserId));
				}
			);

		return forkJoin(profileTasks);
	}

	private buildMessageLists()
	{
		this.messageLists = [];

		// Sort Messages
		this.messages = this.messages.filter(message => message.isActive);
		this.messages.sort((first, second) => 0 - (first.createdDate < second.createdDate ? -1 : 1));

		// Separate Messages By Date
		let messageDates = this.messages.map(message => new Date(message.createdDate).toDateString())
		messageDates = messageDates.filter((n, i) => messageDates.indexOf(n) === i);

		messageDates.forEach
			(
				messageDate =>
				{
					let messageList = new MessageListViewModel();
					messageList.messageDate = new Date(messageDate);

					messageList.messages = this.messages.filter
						(
							message => new Date(message.createdDate).toDateString() == messageDate
						);

					this.messageLists.push(messageList);
				}
			)

		this.updateUnreadMessageCount();

		this.isLoading = false;
	}

	private updateUnreadMessageCount()
	{
		let unreadMessages = this.messages.filter(message => !message.isRead);
		this.unreadMessageCount.update(() => unreadMessages.length);
	}
}
