import { NgFor, NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { PostImageResponse } from '@fitness-central/api/community/post-image/post-image.response-model';
import { PostImageService } from '@fitness-central/api/community/post-image/post-image.service';
import { PostResponse } from '@fitness-central/api/community/post/post.response-model';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CommunityPostImageDialogComponent } from '../image-dialog/image-dialog.component';

@Component({
	selector: 'profile-image-gallery',
	templateUrl: './image-gallery.component.html',
	standalone: true,
	imports: [NgIf, MatGridListModule, NgFor]
})

export class ProfileImageGalleryComponent implements OnInit, OnDestroy
{
	@Input() public profileId: string;

	public posts: PostResponse[] = [];
	public postImageIds: string[] = [];
	public postImages: PostImageResponse[] = [];

	public isLoading: boolean = false;

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	constructor
		(
			private _postImageService: PostImageService,
			private postImageDialog: MatDialog
		)
	{ }

	public ngOnInit()
	{
		this.getPostImages();
	}

	public onImageSelected(selectedIndex: number)
	{
		this.openPostImageDialog(selectedIndex);
	}

	public openPostImageDialog(selectedIndex: number)
	{
		const dialogRef = this.postImageDialog.open
			(
				CommunityPostImageDialogComponent,
				{
					panelClass: 'profile-post-image-dialog',
					data:
					{
						postImageIds: [this.postImageIds[selectedIndex]],
						selectedIndex: selectedIndex
					},
					disableClose: true
				}
			);

		dialogRef.afterClosed().subscribe
			(
				result =>
				{
				}
			);
	}

	private getPostImages()
	{
		const reducedImageSize = true;

		this._postImageService
			.getByProfileId(this.profileId, reducedImageSize, 16)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: postImages =>
					{
						postImages.forEach
							(
								postImage =>
								{
									this.postImages.push(postImage);
									this.postImageIds.push(postImage.postImageId);

									this.isLoading = false;
								}
							)
					}
				}
			)
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
