
import { Component, computed, EventEmitter, input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

import { Guid } from '@abp/guid';
import { TruncateStringPipe } from '@abp/pipes/string/truncate-string';

import { FuseCardModule } from '@fuse/components/card';

import { ResistanceExerciseCardViewModel } from '../view-models/exercise.view-model';
import { TrainingRoutineResistanceExerciseMessageService } from './message-service';

@Component({
	selector: 'training-routine-resistance-exercise-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss'],
	standalone: true,
	imports: [
		FuseCardModule,
		MatLegacyButtonModule,
		MatIconModule,
		MatLegacyTooltipModule,
		TruncateStringPipe
	]
})

export class TrainingRoutineResistanceExerciseCardComponent
{
	public exercise = input<ResistanceExerciseCardViewModel>();
	public canEdit = input<boolean>(true);
	public canChangeStatus = input<boolean>(true);

	@Output() onActiveChanged = new EventEmitter<ResistanceExerciseCardViewModel>();
	@Output() onEdit = new EventEmitter<ResistanceExerciseCardViewModel>();
	@Output() onSelect = new EventEmitter<ResistanceExerciseCardViewModel>();

	public isActionBarVisible = computed(() => this._trainingRoutineResistanceExerciseMessageService.activeId() == this.exercise().routineResistanceExerciseId)

	public constructor
		(
			private readonly _trainingRoutineResistanceExerciseMessageService: TrainingRoutineResistanceExerciseMessageService
		)
	{
	}

	public select()
	{
		this.toggleActionBarVisibility();
		this.onSelect.emit(this.exercise());
	}

	public edit()
	{
		this.toggleActionBarVisibility();
		this.onEdit.emit(this.exercise());
	}

	public reactivate()
	{
		this.toggleActionBarVisibility();

		const exercise = this.exercise();

		exercise.order = 0;
		exercise.isActive = true;

		this.onActiveChanged.emit(exercise);
	}

	public inactivate()
	{
		this.toggleActionBarVisibility();

		const exercise = this.exercise();

		exercise.order = 0;
		exercise.isActive = false;

		this.onActiveChanged.emit(exercise);
	}

	public toggleActionBarVisibility()
	{
		if (this.isActionBarVisible())
		{
			this._trainingRoutineResistanceExerciseMessageService.activeId.set(Guid.empty);
		}
		else
		{
			this._trainingRoutineResistanceExerciseMessageService.activeId.set(this.exercise().routineResistanceExerciseId);
		}
	}
}
