import { Location } from '@angular/common';
import { Component, computed, EventEmitter, input, OnChanges, Output, signal, ViewEncapsulation } from '@angular/core';

import { MemberRoutineResistanceExerciseSetResponseModel } from '@fitness-central/api/member/training/routine-resistance-exercise-set/routine-resistance-exercise-set.response-model';
import { StatusType } from '@fitness-central/shared/enum/status-type.enum';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';

@Component({
	selector: 'client-detail-training-routine-exercise-detail-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['navigation.component.scss'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports:
		[
			FuseNavigationModule
		]
})

export class ClientDetailTrainingExerciseDetailNavigationComponent implements OnChanges
{
	public allExerciseSets = input<MemberRoutineResistanceExerciseSetResponseModel[]>();
	public canEdit = input<boolean>(true);
	public canAdd = input<boolean>(true);

	@Output() readonly onCreateExerciseSet = new EventEmitter();
	@Output() readonly onEditExercise = new EventEmitter();
	@Output() readonly onShowActiveExerciseSets = new EventEmitter();
	@Output() readonly onShowInactiveExerciseSets = new EventEmitter();

	public menuData: FuseNavigationItem[];

	public selectedFilter = signal<StatusType>(StatusType.Active);

	private activeExerciseSetCount = computed(() => this.allExerciseSets().filter(exercise => exercise.isActive).length);
	private inactiveExerciseSetCount = computed(() => this.allExerciseSets().filter(exercise => !exercise.isActive).length);

	public constructor
		(
			private _location: Location
		)
	{
	}

	public ngOnChanges(): void
	{
		this.buildNavigation();
	}

	private buildNavigation()
	{
		this.menuData =
			[
				{
					title: 'Exercises',
					type: 'basic',
					icon: 'arrow_back',
					function: () =>
					{
						this._location.back();
					}
				}
			];

		this.addActions();
		this.addNavigationFilters();
	}

	private addActions()
	{
		let actionItems: FuseNavigationItem[] = [];

		if (this.canEdit())
		{
			actionItems.push
				(
					{
						title: 'Edit exercise detail',
						type: 'basic',
						icon: 'edit',
						function: () =>
						{
							this.onEditExercise.emit();
						}
					}
				);
		}

		if (this.canAdd())
		{
			actionItems.push
				(
					{
						title: 'Add set',
						type: 'basic',
						icon: 'add_circle',
						function: () =>
						{
							this.onCreateExerciseSet.emit();
						}
					}
				);
		}

		if (actionItems.length)
		{
			let actions: FuseNavigationItem =
			{
				title: 'Actions',
				type: 'group',
				children: []
			}

			actions.children.push(...actionItems);

			this.menuData.push(actions);
		}
	}


	private addNavigationFilters()
	{
		let navigationFilters: FuseNavigationItem =
		{
			title: 'Filters',
			type: 'group',
			children:
				[
					{
						id: "1",
						title: 'Active sets',
						type: 'basic',
						icon: 'check_circle',
						badge:
						{
							title: this.activeExerciseSetCount().toString(),
							classes: 'px-2 bg-primary text-on-primary rounded-full'
						},
						function: () =>
						{
							this.selectedFilter.set(StatusType.Active);
							this.buildNavigation();
							this.onShowActiveExerciseSets.emit();
						},
						active: this.selectedFilter() == StatusType.Active
					},
					{
						id: "2",
						title: 'Inactive sets',
						type: 'basic',
						icon: 'cancel',
						badge:
						{
							title: this.inactiveExerciseSetCount().toString(),
							classes: 'px-2 bg-primary text-on-primary rounded-full'
						},
						function: () =>
						{
							this.selectedFilter.set(StatusType.Inactive);
							this.buildNavigation();
							this.onShowInactiveExerciseSets.emit();
						},
						active: this.selectedFilter() == StatusType.Inactive
					}
				]
		};

		this.menuData.push(navigationFilters);
	}
}
