import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

import { MessageViewModel } from './_view-models/message.view-model';

@Injectable
	(
		{
			providedIn: 'root'
		}
	)

export class MessageSidebarMessageService
{
	private selectMessageSource = new BehaviorSubject<MessageViewModel>(null);
	public selectMessageAction$ = this.selectMessageSource.asObservable();

	private deleteMessageSource = new Subject<MessageViewModel>();
	public deleteMessageAction$ = this.deleteMessageSource.asObservable();

	public constructor()
	{
	}

	public selectMessage(message: MessageViewModel)
	{
		this.selectMessageSource.next(message);
	}

	public deleteMessage(message: MessageViewModel)
	{
		this.deleteMessageSource.next(message);
	}
}
