import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { OrganizationRoutineRequestModel } from './routine.request-model';
import { OrganizationRoutineResponseModel } from './routine.response-model';

@Injectable
	(
		{
			providedIn: 'root'
		}
	)

export class OrganizationRoutineService
{
	public constructor(private http: HttpClient) { }

	public get(routineId: string): Observable<OrganizationRoutineResponseModel>
	{
		const url = environment.baseUrl + `/organization/training/routine/${routineId}`

		return this.http.get<OrganizationRoutineResponseModel>(url);
	}

	public getAll(): Observable<OrganizationRoutineResponseModel[]>
	{
		const url = environment.baseUrl + `/organization/training/routines?includePublicRoutines=true`

		return this.http.get<OrganizationRoutineResponseModel[]>(url);
	}

	public create(request: OrganizationRoutineRequestModel): Observable<OrganizationRoutineResponseModel>
	{
		const url = environment.baseUrl + '/organization/training/routine'

		return this.http.post<OrganizationRoutineResponseModel>(url, request);
	}

	public update(request: OrganizationRoutineRequestModel): Observable<OrganizationRoutineResponseModel>
	{
		const url = environment.baseUrl + `/organization/training/routine/${request.routineId}`

		return this.http.put<OrganizationRoutineResponseModel>(url, request);
	}

	public updateRange(request: OrganizationRoutineRequestModel[]): Observable<OrganizationRoutineResponseModel[]>
	{
		const url = environment.baseUrl + `/organization/training/routines`

		return this.http.put<OrganizationRoutineResponseModel[]>(url, request);
	}
}
