import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { ClientDetailBodyCompositionBodyFatMeasurementComponent } from './body-fat-measurement.component';

export const clientBodyFatMeasurementRoutes: Route[] =
[
	{
		path: '',
		component: ClientDetailBodyCompositionBodyFatMeasurementComponent,
		canActivate: [AuthGuard]
	}
];