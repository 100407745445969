<div *ngIf="!isLoading">

	<mat-grid-list cols="4" rowHeight="1:1" gutterSize="10">
		<mat-grid-tile *ngFor="let postImage of postImages; index as index">
			<img [src]="postImage.sasUri" class="h-full object-cover rounded-md cursor-pointer" (click)="onImageSelected(index)">
		</mat-grid-tile>
	</mat-grid-list>

	<div class="flex flex-col items-center" *ngIf="!postImages.length">
		<div class="flex flex-row">No Images in gallery.</div>
	</div>

</div>
