export enum RoutineType
{
	All = 0,
	Template = 1,
	Daily = 2
}

export const RoutineTypeDescriptions = new Map<RoutineType, string>
	(
		[
			[RoutineType.All, 'All'],
			[RoutineType.Template, 'Template'],
			[RoutineType.Daily, 'Daily']
		]
	);