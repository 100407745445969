<div class="relative flex flex-col flex-auto items-center">
	<div class="w-full max-w-3xl">

		<form [formGroup]="bodyFatMeasurementForm">
			
			<div class="flex flex-col gap-4 mt-8 px-6">
				<div>
					<mat-form-field appearance="outline" class="abp-mat-no-subscript flex-auto w-full">
						<mat-label>Body Fat %</mat-label>
						<input type="number" matInput formControlName="value" placeholder="Enter Body Weight Value">
					</mat-form-field>
				</div>
				<div>
					<mat-form-field appearance="outline" >
						<mat-label>Measurement Date</mat-label>
						<input matInput formControlName="measurementDate" [matDatepicker]="measurementDateCreate" placeholder="Choose a logged date">
						<mat-datepicker-toggle matSuffix [for]="measurementDateCreate"></mat-datepicker-toggle>
						<mat-datepicker #measurementDateCreate [startAt]="measurementDateCreate" disabled="false"></mat-datepicker>
						<mat-error>Measurement Date is required!</mat-error>
					</mat-form-field>
				</div>
			</div>
		</form>
	</div>
</div>
<div class="absolute bottom-0 flex items-center w-full mt-10 px-6 py-4 border-t bg-gray-50">
	<button class="ml-auto w-20" 
		mat-button 
		[matTooltip]="'Cancel'" 
		(click)="cancel()"> 
		Cancel 
	</button>
	<button class="ml-2 w-20" 
		mat-flat-button 
		[color]="'primary'" 
		[disabled]="bodyFatMeasurementForm.invalid" 
		[matTooltip]="'Save'" 
		(click)="update()"> 
		<span *ngIf="!bodyFatMeasurementForm.disabled">
			 Save 
		</span>
		<span *ngIf="bodyFatMeasurementForm.disabled">
			<mat-progress-spinner  
				[diameter]="24" 
				[mode]="'indeterminate'">
			</mat-progress-spinner>
		</span> 
	</button>
</div>