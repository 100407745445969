import { BodyFatCalculationType } from './body-fat-calculation-type.enum';

export class ClientBodyFatMeasurementResponseModel
{
	public bodyFatMeasurementId: string;
	public clientId: string;
	public bodyFatCalculationTypeId : BodyFatCalculationType;
	public value : number;
	public measurementDate : Date;

	constructor()
	{
		this.bodyFatMeasurementId = '00000000-0000-0000-0000-000000000000';
		this.clientId = '00000000-0000-0000-0000-000000000000';
		this.bodyFatCalculationTypeId = BodyFatCalculationType.Manual;
		this.value = 0;
		this.measurementDate = new Date()
	}
}