import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { VerificationCodeRequest } from './verification-code.request-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)
export class VerificationService
{
	public constructor
	(
		private http: HttpClient,
		public router: Router
	)
	{ }

	public verifyCode(verificationCode: string): Observable<string>
	{
		const url = environment.baseUrl + `/identity/verification/${ verificationCode }`;

		return this.http.get<string>(url);
	}

	public requestCode(request : VerificationCodeRequest): Observable<any>
	{
		const url = environment.baseUrl + `/identity/verification/code`;

		return this.http.post<any>(url, request);
	}
}
