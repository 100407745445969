import { ClientBodyFatSiteMeasurementRequestModel } from '../body-fat-site-measurement/body-fat-site-measurement-request.model';
import { BodyFatCalculationType } from './body-fat-calculation-type.enum';

export class ClientBodyFatMeasurementUpdateRequestModel
{
	public bodyFatMeasurementId: string;
	public age : number;
	public gender : number;
	public bodyFatCalculationTypeId : BodyFatCalculationType;
	public value : number;
	public measurementDate : Date;
	public bodyFatSiteMeasurements : ClientBodyFatSiteMeasurementRequestModel[];

	constructor()
	{
		this.bodyFatMeasurementId = '00000000-0000-0000-0000-000000000000';
		this.age = 0;
		this.gender = 0;
		this.bodyFatCalculationTypeId = BodyFatCalculationType.Manual;
		this.value = 0;
		this.measurementDate = new Date()
		this.bodyFatSiteMeasurements = [];
	}
}