import { Injectable } from '@angular/core';

import { UserType } from '@fitness-central/shared/enum/user-type.enum';

import jwt_decode from 'jwt-decode';

@Injectable
	(
		{
			providedIn: 'root'
		}
	)
export class JwtHelper
{

	public userName(): string
	{
		const accessToken = sessionStorage.getItem('accessToken');
		const jwtDecoded = jwt_decode(accessToken);

		return jwtDecoded['userName'];
	}

	public userId(): string
	{
		const accessToken = sessionStorage.getItem('accessToken');
		const jwtDecoded = jwt_decode(accessToken);

		return jwtDecoded['userId'];
	}

	public userType(): UserType
	{
		const accessToken = sessionStorage.getItem('accessToken');
		const jwtDecoded = jwt_decode(accessToken);

		let userType: UserType = +jwtDecoded['userTypeId'];

		return userType;
	}

	public organizationId(): string
	{
		const accessToken = sessionStorage.getItem('accessToken');
		const jwtDecoded = jwt_decode(accessToken);

		return jwtDecoded['organizationId'];
	}

	public employeeId(): string
	{
		const accessToken = sessionStorage.getItem('accessToken');
		const jwtDecoded = jwt_decode(accessToken);

		return jwtDecoded['employeeId'];
	}

	public memberId(): string
	{
		const accessToken = sessionStorage.getItem('accessToken');
		const jwtDecoded = jwt_decode(accessToken);

		return jwtDecoded['memberId'];
	}

	public profileId(): string
	{
		const accessToken = sessionStorage.getItem('accessToken');
		const jwtDecoded = jwt_decode(accessToken);

		return jwtDecoded['profileId'];
	}

	public isNew(): boolean
	{
		const accessToken = sessionStorage.getItem('accessToken');
		const jwtDecoded = jwt_decode(accessToken);

		try
		{
			return JSON.parse(jwtDecoded['isNew'].toLowerCase());
		}
		catch (e)
		{
			return undefined;
		}
	}

	public subscriptionStatus(): number
	{
		const accessToken = sessionStorage.getItem('accessToken');
		const jwtDecoded = jwt_decode(accessToken);

		return jwtDecoded['subscriptionStatusId'];
	}

	public handle(): string
	{
		const accessToken = sessionStorage.getItem('accessToken');
		const jwtDecoded = jwt_decode(accessToken);

		return jwtDecoded['handle'];
	}

	public clientIds(): string[]
	{
		const accessToken = sessionStorage.getItem('accessToken');
		const jwtDecoded = jwt_decode(accessToken);

		let clientIds = jwtDecoded['clientIds'].split(',');

		return clientIds;
	}

	public azureAccessToken(): string
	{
		const accessToken = sessionStorage.getItem('accessToken');
		const jwtDecoded = jwt_decode(accessToken);

		return jwtDecoded['azureAccessToken'];
	}

	public expiration(): Date
	{
		const accessToken = sessionStorage.getItem('accessToken');
		const jwtDecoded = jwt_decode(accessToken);
		const jwtExpirationDate = new Date(0);

		jwtExpirationDate.setUTCSeconds(jwtDecoded['exp']);

		return jwtExpirationDate;
	}
}
