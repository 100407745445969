export class PostReactionRequest
{
	public postReactionId: string;
	public postId: string;
	public profileId: string;
	public reactionId: number;

	constructor()
	{
		this.postReactionId = '00000000-0000-0000-0000-000000000000';
		this.postId = '00000000-0000-0000-0000-000000000000';
		this.reactionId = 0;
	}
}
