import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { CommunityMessageRequestModel } from './message.request-model';
import { CommunityMessageResponseModel } from './message.response-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class CommunityMessageService
{
	public constructor(private http: HttpClient) { }

	public create(request: CommunityMessageRequestModel) : Observable<CommunityMessageResponseModel>
	{
		const url = environment.baseUrl + `/community/message`

		return this.http.post<CommunityMessageResponseModel>(url, request);
	}

	public getReceived(startDate : Date, endDate : Date) : Observable<CommunityMessageResponseModel[]>
	{
		const url = environment.baseUrl + `/community/messages/received?startDate=${ startDate.toDateString() }&endDate=${ endDate.toDateString() }`;

		return this.http.get<CommunityMessageResponseModel[]>(url);
	}

	public getSent(startDate : Date, endDate : Date) : Observable<CommunityMessageResponseModel[]>
	{
		const url = environment.baseUrl + `/community/messages/sent?startDate=${ startDate.toDateString() }&endDate=${ endDate.toDateString() }`;

		return this.http.get<CommunityMessageResponseModel[]>(url);
	}

	public update(request: CommunityMessageRequestModel) : Observable<CommunityMessageResponseModel>
	{
		const url = environment.baseUrl + `/community/message/${ request.messageId }`

		return this.http.put<CommunityMessageResponseModel>(url, request);
	}
}
