import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'enumDescription',
    pure: true,
    standalone: true
})
export class EnumDescriptionPipe implements PipeTransform
{
	transform(enumValue: number, enumDescriptions: any): string
	{
		let enumDescription = enumDescriptions.get(enumValue);
		
		return enumDescription;
	}
}
