import { MessageCenterMessageType } from '@fitness-central/shared/enum/message-type.enum';

export class MessageViewModel
{
	public messageId: string;
	public id: string;
	public senderId: string;
	public messageType : MessageCenterMessageType
	public messageTypeId : number;
	public displayName : string;
	public imageSasUri : string;
	public subject: string;
	public body: string;
	public isRead: boolean;
	public isActive: boolean;
	public createdDate: Date;
	public modifiedDate: Date;

	constructor()
	{
		this.messageId = '00000000-0000-0000-0000-000000000000';
		this.id = '00000000-0000-0000-0000-000000000000';
		this.senderId = '00000000-0000-0000-0000-000000000000';
		this.messageType = MessageCenterMessageType.System;
		this.displayName = '';
		this.imageSasUri = '';
		this.subject = '';
		this.body = '';
		this.isRead = false;
		this.isActive = true;
		this.createdDate = new Date();
		this.modifiedDate = new Date();
	}
}
