import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'enumDescription',
	pure: true,
	standalone: true
})

export class EnumDescriptionPipe implements PipeTransform
{
	transform(value: number, descriptions: { [key: number]: string }): string
	{
		if (typeof value === 'number')
		{
			const enumDescription = descriptions[value];

			return enumDescription;
		}
	}
}