import { NgIf } from '@angular/common';
import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { FuseLoadingBarModule } from '@fuse/components/loading-bar/loading-bar.module';

import { Subject } from 'rxjs';

@Component({
	selector: 'empty-layout',
	templateUrl: './empty-layout.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [FuseLoadingBarModule, NgIf, RouterOutlet]
})
export class EmptyLayoutComponent implements OnDestroy
{
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	public constructor()
	{
	}

	public ngOnDestroy(): void
	{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}
}
