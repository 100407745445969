<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

<sidebar-view #sidebarView
	[showNavigation]="false">
	<ng-container header>
	</ng-container>

	<ng-container create-sidebar>
		<profile-post-sidebar *ngIf="postSidebarViewModel"
			[postSidebarViewModel]="postSidebarViewModel"
			(postCreatedOutput)="onPostCreated($event)"
			(closeSidebarOutput)="closeSidebar()">
		</profile-post-sidebar>
	</ng-container>

	<ng-container content>
		<div class="flex flex-col sm:flex-auto min-w-0 w-full">
			<div class="flex flex-col flex-auto min-w-0" *ngIf="!isLoading">

				<!-- Search -->
				<profile-search></profile-search>

				<!-- Header -->
				<div class="flex flex-col shadow bg-card">

					<!-- Cover image -->
					<div>
						<img
							class="h-20 sm:h-16 object-cover"
							src="assets/images/profile/default2.jpg"
							alt="Cover image">
					</div>

					<!-- User info -->
					<div class="flex flex-col flex-0 lg:flex-row items-center max-w-5xl w-full mx-auto px-8 lg:h-18 bg-card">

						<div class="-mt-13 lg:-mt-16 h-16 w-16 sm:h-24 sm:w-24 rounded-full ring-4 ring-bg-card text-4xl sm:mr-4">
							<default-profile-image [profileUrl]="profile.profileImageUrl" [displayName]="profile.displayName"></default-profile-image>
						</div>

						<!-- Details -->
						<div class="flex flex-col items-center sm:items-start mt-2 ml-0 sm:mt-0 sm:ml-8">
							<div class="text-lg font-medium leading-none">{{ profile.displayName }}</div>
							<div class="text-secondary font-normal pt-1">&#64;{{ profile.handle }}</div>
						</div>

						<!-- Separator -->
						<div class="hidden lg:flex h-8 mx-8 border-l-2"></div>

						<!-- Stats -->
						<div class="flex flex-row items-center m-2">
							<div class="flex flex-col items-center cursor-pointer" (click)="routeToFollowers()">
								<button mat-button>
									<span class="font-bold">{{ followerCount }}</span>
									<span class="text-md font-medium text-secondary ml-2">Followers</span>
								</button>
							</div>

							<div class="flex flex-col items-center cursor-pointer" (click)="routeToFollowing()">
								<button mat-button>
									<span class="font-bold">{{ followingCount }}</span>
									<span class="text-md font-medium text-secondary ml-2">Following</span>
								</button>
							</div>
						</div>

						<!-- Menu -->
						<div class="hidden sm:flex items-center mt-8 mb-4 lg:m-0 lg:ml-auto space-x-6">
						</div>

					</div>
				</div>

				<!-- Main -->
				<div class="flex flex-auto justify-center w-full max-w-5xl mx-auto p-6 sm:p-8">

					<!-- Column -->
					<div class="hidden lg:flex flex-col items-start mr-8">

						<!-- About me -->
						<fuse-card class="flex flex-col w-80 px-8 pt-6 pb-4">
							<div class="flex items-center justify-between">
								<div class="text-2xl font-semibold leading-tight">About Me</div>

							</div>
							<div class="mt-4" *ngIf="profile.aboutDescription">
								{{ profile.aboutDescription }}
								<hr class="w-full border-t mt-4 mb-0">
							</div>

							<div class="flex flex-col">
								<div class="flex items-center mt-4" *ngIf="profile.location">
									<mat-icon
										class="icon-size-5 mr-3">
									
										home_pin
									
									</mat-icon>
									<span class="leading-none">{{ profile.location }}</span>
								</div>
								<div class="flex items-center mt-4" *ngIf="profile.title || profile.companyName">
									<mat-icon
										class="icon-size-5 mr-3">
									
										work
									
									</mat-icon>
									<div >
										<div> {{ profile.title }}</div>
										<div> {{ profile.companyName }}</div>
									</div>

								</div>
								<div class="flex items-center mt-4" *ngIf="profile.isDateOfBirthVisible">
									<mat-icon
										class="icon-size-5 mr-3">

										cake

									</mat-icon>									
									<span class="leading-none">
										{{ profile.dateOfBirth | date:'MMMM d' }}
									</span>
								</div>
								<div class="flex items-center mt-4" *ngIf="profile.isGenderVisible">
									<ng-container *ngIf="profile.gender == gender.Male">
										<mat-icon
											class="icon-size-5 mr-3">

											male

										</mat-icon>
										<span class="leading-none">
											Male
										</span>
									</ng-container>
									<ng-container *ngIf="profile.gender == gender.Female">
										<mat-icon
											class="icon-size-5 mr-3">

											male

										</mat-icon>
										<span class="leading-none">
											Female
										</span>
									</ng-container>
								</div>
							</div>
						</fuse-card>

						<!-- Followers -->
						<fuse-card class="flex flex-col max-w-80 w-full mt-8 px-8 pt-6 pb-4">
							<div class="flex items-center justify-between">
								<div class="text-2xl font-semibold leading-tight">Followers</div>
								<div class="-mr-3">
									<button
										mat-icon-button
										[matMenuTriggerFor]="followerMenu">
										<mat-icon
											class="icon-size-5">

											more_vert
										
										</mat-icon>
									</button>
									<mat-menu #followerMenu="matMenu">
										<button mat-menu-item (click)="routeToFollowers()">See All Followers</button>
									</mat-menu>
								</div>
							</div>

							<profile-follower [profileId]="profile.profileId" class="mt-6"></profile-follower>

						</fuse-card>

						<!-- Following -->
						<fuse-card class="flex flex-col max-w-80 w-full mt-8 px-8 pt-6 pb-4">
							<div class="flex items-center justify-between">
								<div class="text-2xl font-semibold leading-tight">Following</div>
								<div class="-mr-3">
									<button
										mat-icon-button
										[matMenuTriggerFor]="followingMenu">
										<mat-icon
											class="icon-size-5">

											more_vert
										
										</mat-icon>
									</button>
									<mat-menu #followingMenu="matMenu">
										<button mat-menu-item (click)="routeToFollowing()">See All Following</button>
									</mat-menu>
								</div>
							</div>

							<profile-following [profileId]="profile.profileId" class="mt-6"></profile-following>

						</fuse-card>

						<!-- Gallery -->
						<fuse-card class="flex flex-col max-w-80 w-full mt-8 px-8 pt-6 pb-4">

							<div class="flex items-center justify-between">
								<div class="text-2xl font-semibold leading-tight">Gallery</div>
								<div class="-mr-3">

								</div>
							</div>

							<profile-image-gallery [profileId]="profile.profileId" class="mt-6"></profile-image-gallery>
						</fuse-card>

					</div>

					<!-- Post Buttons -->
					<div class="flex flex-col items-start w-full">
						<fuse-card class="flex flex-col max-w-140 w-full">
							<div class="px-6 sm:px-8 pb-6">
								<div class="flex flex-row items-center mt-8 w-full">
									<div class="flex flex-col items-center">
										<div class="h-12 w-12 text-lg mr-4">
											<default-profile-image
												[profileUrl]="userProfile.profileImageUrl"
												[displayName]="userProfile.displayName">
											</default-profile-image>
										</div>
									</div>
									<div class="flex flex-col items-center mb-0 w-full">
										<button mat-flat-button
											(click)="createPost(postType.Comment)"
											class="w-full bg-default justify-start">What's new?
										</button>
									</div>
								</div>
							</div>

							<div class="flex flex-col items text-md font-medium bg-accent text-on-accent">
								<div class="flex flex-row justify-start">
									<div class="flex flex-col">
										<div class="flex flex-row m-2 items-center">
											<button
												(click)="createPost(postType.Image)"
												class="bg-accent text-on-accent"
												mat-button>
												<mat-icon>photo_library</mat-icon>
												<div class="pl-2">Image</div>
											</button>
										</div>
									</div>
								</div>
							</div>
						</fuse-card>

						<div *ngIf="posts.length" class="w-full">
							<div *ngFor="let post of posts">
								<profile-post (postRemovedOutput)="onPostRemoved($event)" [profileId]="userProfile.profileId" [post]="post"></profile-post>
							</div>
							<div *ngIf="previousPosts">
								<div class="flex flex-col items-start text-md font-medium bg-default text-on-default">
									<div class="flex flex-row max-w-140 items-start w-full">
										<button
											(click)="showPreviousPosts()"
											class="bg-default text-on-default m-2 w-full"
											mat-button>
											<mat-icon>

												expand_circle_down

											</mat-icon>
											<div class="pl-2">Display Previous Posts</div>
										</button>
									</div>
								</div>
							</div>
							<div *ngIf="!previousPosts">
								<div class="flex flex-col items-start text-md font-medium bg-default text-on-default">
									<div class="flex flex-row max-w-140 items-start w-full">
										<button
											class="bg-default text-on-default m-2 w-full"
											mat-button>

											<div>End of Posts.</div>
										</button>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="!posts.length" class="w-full">
							<fuse-card class="flex flex-col max-w-140 w-full h-100-p justify-between my-4 p-4 items-center">
								<div class="font-medium">No Posts.</div>
							</fuse-card>
						</div>

					</div>

				</div>

			</div>
		</div>
	</ng-container>
</sidebar-view>
