<div class="flex flex-row items-start w-full pt-4" *ngIf="!isLoading">
	<div class="flex flex-col mr-4 border-l-1">
		<default-profile-image class="w-8 h-8 cursor-pointer" [profileUrl]="ownerProfileImageUrl" [displayName]="ownerProfile.displayName" (click)="routeToProfile()"></default-profile-image>
	</div>
	<div class="flex flex-col mt-0.5 w-full">
		<div class="p-2 bg-default rounded-md w-full">
			<div class="font-semibold leading-none">
				{{ ownerProfile.displayName }}
				<span class="font-normal leading-none">
					&#64;{{ ownerProfile.handle }}
				</span>
			</div>
			<div>
				<span class="text-sm text-secondary">
					{{ postComment.modifiedDate | timeAgo }}
				</span>
			</div>
			<div class="bg-default mt-4 w-full rounded-3xl" [innerHTML]="postComment.comment">
			</div>
		</div>
		<div class="flex items-center text-sm text-secondary mt-2">

			<ng-container *ngIf="postComment.postCommentParentId == defaultGuid">
				<div class="mr-2 hover:underline cursor-pointer" (click)="showReplyForm()">
					Reply
				</div>
			</ng-container>
			<span class="mr-2" *ngIf="postComment.replyCount > 0">&bull;</span>
			<div class="mr-2 hover:underline cursor-pointer" *ngIf="postComment.replyCount > 0 && areRepliesVisible == false" (click)="showReplies()">
				Show {{ postComment.replyCount }} replies
			</div>
			<div class="mr-2 hover:underline cursor-pointer" *ngIf="areRepliesVisible == true" (click)="hideReplies()">
				Hide replies
			</div>
		</div>
	</div>
</div>
<div *ngIf="isReplyFormVisible">
	<div class="flex flex-col ml-10">
		<profile-post-comment-edit
			 [postId]="postComment.postId"
			 [postCommentParentId]="postComment.postCommentId"
		 	(postCommentOutput)="onPostCommentCreated($event)">
		</profile-post-comment-edit>
	</div>
</div>

<div *ngIf="areRepliesVisible">
	<div class="flex flex-col ml-10" *ngFor="let postComment of postComments">
		<profile-post-comment [postComment]="postComment"></profile-post-comment>
	</div>
</div>
