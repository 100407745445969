import { Injectable, OnDestroy } from '@angular/core';

import { LoginResponseModel } from '@fitness-central/api/identity/login/login.response-model';
import { LoginService } from '@fitness-central/api/identity/login/login.service';

import { interval, Subject, Subscription, takeUntil } from 'rxjs';

import { JwtHelper } from '../helper/jwt.helper';
import { SessionStorageHelper } from '../helper/session-storage.helper';

@Injectable
	(
		{
			providedIn: 'root'
		}
	)
export class TokenRefreshService implements OnDestroy
{
	private intervalInMilliseconds = 300000; // 5 Minutes
	private minimumTimeRemainingInMilliseconds = 600000; // 10 Minutes

	private timeRemaining = 0;

	private tokenRefreshTimerSubscription: Subscription = new Subscription();

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public constructor
		(
			private _sessionStorageHelper: SessionStorageHelper,
			private _loginService: LoginService,
			private _jwtHelper: JwtHelper
		)
	{
		console.debug(`Refresh timer interval set to: ${this.msToTime(this.intervalInMilliseconds)}`);
		console.debug(`Refresh timer threshold set to: ${this.msToTime(this.minimumTimeRemainingInMilliseconds)}`);
	}

	public stopRefreshTimer()
	{
		console.debug(`--- Token Refresh Timer stopped @ ${new Date()}`);

		this.tokenRefreshTimerSubscription.unsubscribe();
	}

	public startRefreshTimer()
	{
		console.debug(`--- Token Refresh Timer started @ ${new Date()}`);

		this.checkExpiration();

		this.tokenRefreshTimerSubscription = interval(this.intervalInMilliseconds)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: () =>
					{
						this.checkExpiration();
					},
					error: error =>
					{
						console.error("Error updating token refresh timer.");
						throw error;
					}
				}
			)
	}

	private checkExpiration()
	{
		const expirationDate = this._jwtHelper.expiration();
		const currentDate = new Date();
		const currentDateInUTC = new Date(currentDate.toUTCString());

		this.timeRemaining = Math.abs(expirationDate.getTime() - currentDateInUTC.getTime());

		console.debug(`--- Time Remaining on JWT: ${this.msToTime(this.timeRemaining)}`);

		if (this.timeRemaining <= this.minimumTimeRemainingInMilliseconds)
		{
			console.debug(`--- Threshold met: ${this.msToTime(this.timeRemaining)}`);
			this.refreshJwtToken();
		}
	}


	private refreshJwtToken()
	{
		let refreshToken = this._sessionStorageHelper.refreshTokenGet();

		this._loginService
			.refreshToken(refreshToken)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (token: LoginResponseModel) =>
					{
						this._sessionStorageHelper.accessTokenSet(token);

						console.log(`--- Token refreshed @${Date.now()}`);
					},
					error: error =>
					{
						console.error("Error encountered refreshing token.");
						throw error;
					}
				}
			)
	}

	private msToTime(duration: number): string 
	{
		const seconds = Math.floor((duration / 1000) % 60);
		const minutes = Math.floor((duration / (1000 * 60)) % 60);
		const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

		const formattedHours = (hours < 10) ? "0" + hours : hours.toString();
		const formattedMinutes = (minutes < 10) ? "0" + minutes : minutes.toString();
		const formattedSeconds = (seconds < 10) ? "0" + seconds : seconds.toString();

		return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
	}

	public ngOnDestroy(): void
	{
		console.log("Unsubscribing from all token refresh observables");
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}