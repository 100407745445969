import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { AuthenticationVerificationCodeComponent } from './verification/code.component';
import { AuthenticationVerificationPendingComponent } from './verification/pending.component';
import { AuthenticationVerificationComponent } from './verification/verification.component';

export const authenticationRoutes: Route[] =
[
	{
		path : 'forgot-password',
		loadComponent: () => import('./forgot-password/forgot-password.component').then(c => c.AuthenticationForgotPasswordComponent)
	},
	{
		path : 'reset-password',
		loadComponent: () => import('./change-password/change-password.component').then(c => c.AuthenticationChangePasswordComponent)
	},
	{
		path : 'signup',
		loadComponent: () => import('./sign-up/sign-up.component').then(c => c.AuthenticationSignUpComponent)
	},
	{
		path : 'signin',
		loadComponent: () => import('./sign-in/sign-in.component').then(c => c.AuthenticationSignInComponent)
	},
	{
		path : 'signout',
		canActivate: [AuthGuard],
		loadComponent: () => import('./sign-out/sign-out.component').then(c => c.AuthenticationSignOutComponent)
	},
	{
		path : 'verification',
		component: AuthenticationVerificationComponent
	},
	{
		path : 'request',
		component: AuthenticationVerificationCodeComponent
	},
	{
		path : 'pending',
		component: AuthenticationVerificationPendingComponent
	},
];
