@if(isLoading())
{
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
}
<sidebar-view #sidebarView>

	<ng-container header>
	</ng-container>

	<ng-container navigation>
		<client-detail-training-routine-exercise-detail-navigation
			[allExerciseSets]="allExerciseSets()" 
			(onCreateExerciseSet)="showExerciseSetCreateSidebar()"
			(onEditExercise)="showExerciseEditSidebar()"
			(onShowActiveExerciseSets)="showActiveExerciseSets()"
			(onShowInactiveExerciseSets)="showInactiveExerciseSets()">
		</client-detail-training-routine-exercise-detail-navigation>
	</ng-container>

	<ng-container create-sidebar>
		<training-exercise-set-create-sidebar 
			[exercise]="selectedExercise()"
			(onCreate)="createExerciseSet($event)"
			(onCancel)="closeSidebar()">
		</training-exercise-set-create-sidebar>
	</ng-container>

	<ng-container edit-sidebar>

		@if(editSidebarAction == editSidebarActionType.EditResistanceExercise)
		{
			<training-exercise-edit-sidebar
				[exercise]="selectedExercise()" 
				(onUpdate)="updateExercise($event)"
				(onCancel)="closeSidebar()">
			</training-exercise-edit-sidebar>
		}

		@if(editSidebarAction == editSidebarActionType.EditResistanceExerciseSet)
		{
			<training-exercise-set-edit-sidebar
				[exerciseSet]="selectedExerciseSet()" 
				(onUpdate)="updateExerciseSet($event)"
				(onCancel)="closeSidebar()">
			</training-exercise-set-edit-sidebar>
		}

	</ng-container>

	<ng-container content>
		@if(!isLoading())
		{
			<div class="w-full">
				<ui-content-header
					[title]="selectedExercise().name" 
					[subtitle]="selectedRoutine().name"
					[subtitleSegment]="selectedRoutine().version" 
					(onToggleNavigation)="toggleNavigation()"
					(onRouteToBack)="routeToBack()">
				</ui-content-header>
			</div>
			<div>
				<div class="flex flex-row items-center w-full">
					<div class="flex flex-col text-xl leading-none items-start justify-start w-full">
						<span class="flex lg:hidden p-2 text-lg font-normal">
							<button mat-icon-button class="bg-accent text-on-accent "
							(click)="routeToBack()">
								<mat-icon class="text-white">arrow_back</mat-icon>
							</button>
						</span>
					</div>
				</div>
				<div>
					<drag-drop-card-container #dragDropCards 
						[cardTemplate]="exerciseSet"
						[cards]="exerciseSets()" 
						[isDragDropEnabled]="isMemberExercise()"
						[dragHandleTop]="'28px'" 
						[dragHandleLeft]="'16px'" 
						(onCardReorder)="updateExerciseSets()">

						<ng-template let-card #exerciseSet>
							<training-routine-resistance-exercise-set-card 
								[exerciseSet]="card"
								(onEdit)="showExerciseSetEditSidebar($event)" 
								(onActiveChanged)="changeActive()">
							</training-routine-resistance-exercise-set-card>
						</ng-template>
					</drag-drop-card-container>

					<div class="flex flex-col m-12 items-center">
						@if(!exerciseSets().length)
						{
							<div class="flex flex-col text-lg font-medium bg-default text-on-default items-center">
								No {{ exerciseSetFilterType | enumDescription:statusTypeDescriptions}} Exercise Sets
							</div>
						}
					</div>
				</div>
			</div>
		}
	</ng-container>
</sidebar-view>