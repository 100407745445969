import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { MemberRoutineResistanceExerciseRequestModel } from './routine-resistance-exercise.request-model';
import { MemberRoutineResistanceExerciseResponseModel } from './routine-resistance-exercise.response-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class MemberRoutineResistanceExerciseService
{
	public constructor(private http: HttpClient) { }

	public get(routineResistanceExerciseId: string): Observable<MemberRoutineResistanceExerciseResponseModel>
	{
		const url = environment.baseUrl + `/member/training/routine-resistance-exercise/${ routineResistanceExerciseId }`;

		return this.http.get<MemberRoutineResistanceExerciseResponseModel>(url);
	}

	public getByRoutineId(routineId: string): Observable<MemberRoutineResistanceExerciseResponseModel[]>
	{
		const url = environment.baseUrl + `/member/training/routine-resistance-exercises/routine/${ routineId }`;

		return this.http.get<MemberRoutineResistanceExerciseResponseModel[]>(url);
	}

	public create(request: MemberRoutineResistanceExerciseRequestModel) : Observable<MemberRoutineResistanceExerciseResponseModel>
	{
		const url = environment.baseUrl + '/member/training/routine-resistance-exercise';

		return this.http.post<MemberRoutineResistanceExerciseResponseModel>(url, request);
	}

	public delete(routineResistanceExerciseId: string): Observable<MemberRoutineResistanceExerciseResponseModel>
	{
		const url = environment.baseUrl + `/member/training/routine-resistance-exercise/${ routineResistanceExerciseId }`;

		return this.http.delete<MemberRoutineResistanceExerciseResponseModel>(url);
	}

	public update(request: MemberRoutineResistanceExerciseRequestModel) : Observable<MemberRoutineResistanceExerciseResponseModel>
	{
		const url = environment.baseUrl + `/member/training/routine-resistance-exercise/${ request.routineResistanceExerciseId }`;

		return this.http.put<MemberRoutineResistanceExerciseResponseModel>(url, request);
	}

	public updateRange(request: MemberRoutineResistanceExerciseRequestModel[]) : Observable<MemberRoutineResistanceExerciseResponseModel[]>
	{
		const url = environment.baseUrl + `/member/training/routine-resistance-exercises`

		return this.http.put<MemberRoutineResistanceExerciseResponseModel[]>(url, request);
	}
}
