<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<ng-container *ngIf="isScreenSmall">
	<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" 
		[mode]="'over'" 
		[name]="'mainNavigation'" 
		[navigation]="navigation.default" 
		[opened]="false">
		<ng-container fuseVerticalNavigationContentHeader>
			<div class="flex items-center h-20 pt-6 px-8">
				<img class="w-60" 
					src="assets/images/logo/logo-white-text.svg" 
					alt="Logo image">
			</div>
		</ng-container>
	</fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto items-center w-full min-w-0 bg-gray-200 dark:bg-card">

	<!-- Header -->
	<div class="relative flex flex-col flex-0 justify-center w-full overflow-hidden z-49 shadow dark:shadow-none print:hidden">
		<div class="relative flex flex-auto justify-center w-full px-2 md:px-8 bg-gray-800 dark:bg-gray-900">
			<div class="flex items-center w-full max-w-360 h-16">
				<!-- Logo -->
				<ng-container *ngIf="!isScreenSmall">
					<div class="flex items-center">
						<img class="w-60" 
							src="assets/images/logo/logo-white-text.svg"
							alt="Logo image">
					</div>
				</ng-container>

				<!-- Navigation toggle button -->
				<ng-container *ngIf="isScreenSmall">
					<button mat-icon-button
						(click)="toggleNavigation('mainNavigation')">

						<div class="flex items-center">
							<img class="w-6"
								src="assets/images/logo/logo.svg"
								alt="Logo">
						</div>
					</button>
				</ng-container>

				<!-- Top Level Buttons -->
				<div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">

					<button mat-icon-button class="bg-accent text-on-accent"
						(click)="toggleMessageSidebar()">
						
						@if (!isNew())
						{
							<ng-container *ngIf="unreadMessageCount() == 0">
								<mat-icon>mail</mat-icon>
							</ng-container>
						}
						
						<ng-container *ngIf="unreadMessageCount() > 0">
							<mat-icon class="text-primary">mail</mat-icon>
							<mat-icon class="fixed text-red-600 icon-size-3 ml-4 mb-4">circle</mat-icon>
						</ng-container>
						
					</button>

					<button mat-icon-button
						(click)="toggleProfileSidebar()">
						<span class="relative">
							<div class="h-8 w-8 rounded-full">
								<default-profile-image [profileUrl]="profileImageUrl()" [displayName]="displayName()">
								</default-profile-image>
							</div>
						</span>
					</button>
				</div>
			</div>
		</div>
	</div>

	<mat-drawer-container class="flex-auto h-full w-full bg-gray-200">
		<mat-drawer #sidebar class="w-full md:w-128 bg-default" 
			[autoFocus]="false"
			[mode]="'over'"
			[opened]="false"
			[position]="'end'"
			[disableClose]="false">

			<ng-container *ngIf="sidebarAction=='Messages'">
				<layout-message-sidebar 
					[unreadMessageCount]="unreadMessageCount"
					(onCancel)="closeSidebar()">
				</layout-message-sidebar>
			</ng-container>
			
			<ng-container *ngIf="sidebarAction=='Profile'">
				<layout-user-sidebar
					(onCancel)="closeSidebar()">
				</layout-user-sidebar>
			</ng-container>

		</mat-drawer>

		<mat-drawer-content class="flex flex-col center">

			<!-- Top Navigation -->

			@if (!isNew())
			{
				<div class="relative flex flex-col flex-0 justify-center w-full overflow-hidden z-49 shadow dark:shadow-none print:hidden">
					<ng-container *ngIf="!isScreenSmall">
						<div class="flex flex-auto justify-center px-4 md:px-8 bg-card dark:bg-gray-700">
							<div class="relative flex items-center w-full max-w-360 h-12">
								<fuse-horizontal-navigation class="-mx-4"
									[name]="'mainNavigation'"
									[navigation]="navigation.default">
								</fuse-horizontal-navigation>
							</div>
						</div>
					</ng-container>
				</div>
			}

			<!-- Content -->
			<div class="flex flex-auto justify-center w-full sm:p-6 md:p-8">
				<div class="flex flex-col flex-auto w-full sm:max-w-360 sm:shadow-lg sm:rounded-lg sm:overflow-hidden bg-default">
					<!-- *ngIf="true" hack is required here for router-outlet to work correctly.
						Otherwise, layout changes won't be registered and the view won't be updated! -->
					<router-outlet *ngIf="true"></router-outlet>
				</div>
			</div>

			<!-- Footer -->
			<div class="relative hidden sm:flex flex-0 justify-center w-full px-6 md:px-8 z-49 border-t bg-card print:hidden">
				<div class="flex flex-col sm:flex-row items-center w-full m-2">
					<div class="font-medium text-secondary">Fitness Central &copy; {{currentYear}}</div>
					<div class="font-medium text-secondary hidden sm:flex mx-2">-</div>
					<div class="font-medium text-secondary">
						<a href="https://www.alphabusinesspartners.com">Powered by Alpha Business Partners</a>
					</div>
				</div>
			</div>

		</mat-drawer-content>
	</mat-drawer-container>

</div>
