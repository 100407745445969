import { NgModule } from '@angular/core';



import { DefaultLayoutModule } from './default-layout/default-layout.module';
import { EmptyLayoutModule } from './empty-layout/empty-layout.module';
import { LayoutComponent } from './layout.component';

const layoutModules =
[
	// Empty
	EmptyLayoutModule,

	// Horizontal navigation
	DefaultLayoutModule,
];

@NgModule
(
	{
    imports: [
    ...layoutModules,
    LayoutComponent
],
    exports: [
        LayoutComponent,
        ...layoutModules
    ]
}
)
export class LayoutModule
{
}
