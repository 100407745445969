import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { OrganizationRoutineResistanceExerciseRequestModel } from './routine-resistance-exercise.request-model';
import { OrganizationRoutineResistanceExerciseResponseModel } from './routine-resistance-exercise.response-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class OrganizationRoutineResistanceExerciseService
{
	public constructor(private http: HttpClient) { }

	public get(routineResistanceExerciseId: string): Observable<OrganizationRoutineResistanceExerciseResponseModel>
	{
		const url = environment.baseUrl + `/organization/training/routine-resistance-exercise/${ routineResistanceExerciseId }`;

		return this.http.get<OrganizationRoutineResistanceExerciseResponseModel>(url);
	}

	public getByRoutineId(routineId: string): Observable<OrganizationRoutineResistanceExerciseResponseModel[]>
	{
		const url = environment.baseUrl + `/organization/training/routine-resistance-exercises/routine/${ routineId }`;

		return this.http.get<OrganizationRoutineResistanceExerciseResponseModel[]>(url);
	}

	public create(request: OrganizationRoutineResistanceExerciseRequestModel) : Observable<OrganizationRoutineResistanceExerciseResponseModel>
	{
		const url = environment.baseUrl + '/organization/training/routine-resistance-exercise';

		return this.http.post<OrganizationRoutineResistanceExerciseResponseModel>(url, request);
	}

	public update(request: OrganizationRoutineResistanceExerciseRequestModel) : Observable<OrganizationRoutineResistanceExerciseResponseModel>
	{
		const url = environment.baseUrl + `/organization/training/routine-resistance-exercise/${ request.routineResistanceExerciseId }`;

		return this.http.put<OrganizationRoutineResistanceExerciseResponseModel>(url, request);
	}

	public updateRange(request: OrganizationRoutineResistanceExerciseRequestModel[]) : Observable<OrganizationRoutineResistanceExerciseResponseModel[]>
	{
		const url = environment.baseUrl + `/organization/training/routine-resistance-exercises`

		return this.http.put<OrganizationRoutineResistanceExerciseResponseModel[]>(url, request);
	}
}
