import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { Router, RouterLink } from '@angular/router';

import { VerificationService } from '@fitness-central/api/identity/verification/verification.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertType } from '@fuse/components/alert';
import { FuseAlertModule } from '@fuse/components/alert/alert.module';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'authentication-verification-code',
	templateUrl: './code.component.html',
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations,
	standalone: true,
	imports: [NgIf, FuseAlertModule, FormsModule, ReactiveFormsModule, MatLegacyFormFieldModule, MatLegacyInputModule, MatLegacyButtonModule, MatLegacyProgressSpinnerModule, RouterLink]
})
export class AuthenticationVerificationCodeComponent implements OnInit, OnDestroy
{
	public alert: { type: FuseAlertType; message: string } =
		{
			type: 'success',
			message: ''
		};

	public requestForm: FormGroup;
	public showAlert: boolean = false;

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	public constructor
		(
			private _formBuilder: FormBuilder,
			private _verificationService: VerificationService,
			private _router: Router
		)
	{
	}

	public ngOnInit(): void
	{
		// Create the form
		this.requestForm = this._formBuilder.group
			(
				{
					userName: ['', [Validators.required, Validators.email]]
				}
			);
	}

	public requestVerificationCode(): void
	{
		this.requestForm.disable();
		this.showAlert = false;

		this
			._verificationService
			.requestCode(this.requestForm.value)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: () =>
					{
						this._router.navigate([`/authentication/verification`]);
					},
					error: errors =>
					{
						console.log(errors);
					}
				}
			);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
