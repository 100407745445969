import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { MessageViewModel } from '../_view-models/message.view-model';

@Injectable
	(
		{
			providedIn: 'root'
		}
	)

export class MessageCenterMessageService
{
	private markMessageAsReadSource = new Subject<MessageViewModel>();
	public markMessageAsReadAction$ = this.markMessageAsReadSource.asObservable();

	private deleteMessageSource = new Subject<MessageViewModel>();
	public deleteMessageAction$ = this.deleteMessageSource.asObservable();

	public markMessageAsRead(message: MessageViewModel)
	{
		this.markMessageAsReadSource.next(message);
	}

	public deleteMessage(message: MessageViewModel)
	{
		this.deleteMessageSource.next(message);
	}
}
