import { Injectable } from '@angular/core';

import { JwtHelper } from '@fitness-central/core/helper/jwt.helper';
import { FuseNavigationItem } from '@fuse/components/navigation';

import { employeeClientNavigation } from './employee/client/client.navigation';
import { employeeCommunityNavigation } from './employee/community/community.navigation';
import { employeeOrganizationNavigation } from './employee/organization/organization.navigation';
import { employeeTrainingNavigation } from './employee/training/training.navigation';
import { memberBodyCompositionNavigation } from './member/body-composition/body-composition.navigation';
import { memberCommunityNavigation } from './member/community/community.navigation';
import { memberTrainingNavigation } from './member/training/training.navigation';
import { memberVitalsNavigation } from './member/vitals/vitals.navigation';

@Injectable
	(
		{
			providedIn: 'root'
		}
	)
export class NavigationService
{
	private isPaidProfile: boolean = false;

	public constructor
		(
			private jwtHelper: JwtHelper
		)
	{
	}

	public get(): FuseNavigationItem[]
	{
		this.isPaidProfile = this.jwtHelper.userType() > 1;

		// Subscribe to navigation data
		let mainNavigation: FuseNavigationItem[] = [];

		if (!this.isPaidProfile)
		{
			mainNavigation
				.push(...memberCommunityNavigation);

			mainNavigation
				.push(...memberTrainingNavigation);

			mainNavigation
				.push(...memberBodyCompositionNavigation);

			mainNavigation
				.push(...memberVitalsNavigation);
		}
		else
		{
			mainNavigation
				.push(...employeeCommunityNavigation);

			mainNavigation
				.push(...employeeTrainingNavigation);

			mainNavigation
				.push(...employeeClientNavigation);

			mainNavigation
				.push(...employeeOrganizationNavigation);
		}

		return mainNavigation;
	}
}
