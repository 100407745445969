export class ClientResponseModel
{
	public clientId: string = '00000000-0000-0000-0000-000000000000';
	public profileId: string = '00000000-0000-0000-0000-000000000000';
	public genderId: number = 1;
	public genderName: string = '';
	public firstName: string = '';
	public lastName: string = '';
	public dateOfBirth: Date =  new Date();
	public description: string = '';
	public isActive: boolean = false;
	public createdDate : Date = new Date();
}
