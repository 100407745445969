import { Component, input } from '@angular/core';

import { ElapsedTimePipe } from '@abp/pipes/date-time/elapsed-time.pipe';

import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';
import { WorkoutResistanceExerciseViewModel } from '@fitness-central/shared/components/training/workout/view-models/workout-resistance-exercise.view-model';
import { FuseCardModule } from '@fuse/components/card';

@Component({
	selector: 'training-history-cards-exercise',
	templateUrl: './exercise.component.html',
	standalone: true,
	imports:
		[
			FuseCardModule,
			ElapsedTimePipe
		]
})

export class TrainingHistoryExerciseCardComponent
{
	public resistanceExercise = input<WorkoutResistanceExerciseViewModel>();

	public measurementSystem: number = 0;

	constructor
		(
			private readonly _sessionStorageHelper: SessionStorageHelper
		)
	{
		this.measurementSystem = _sessionStorageHelper.measurementSystem();
	}
}