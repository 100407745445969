import { Component, computed, EventEmitter, input, OnChanges, Output, signal, ViewEncapsulation } from '@angular/core';

import { RoutineType } from '@fitness-central/shared/enum/routine-type.enum';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';

import { MemberRoutineTemplateFilterType } from '../../../../_enums/routine-filter-type.enum';
import { RoutineTemplateViewModel } from '../../../../_view-models/routine.view-model';

@Component({
	selector: 'training-routine-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['navigation.component.scss'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [FuseNavigationModule]
})

export class MemberTrainingRoutineNavigationComponent implements OnChanges
{
	public allRoutines = input<RoutineTemplateViewModel[]>();

	private activeMemberRoutineCount = computed(() => this.allRoutines().filter(x => x.isMemberRoutine == true && x.isActive && x.routineTypeId == RoutineType.Template).length);
	private activeClientRoutineCount = computed(() => this.allRoutines().filter(x => !x.isMemberRoutine == true && x.isActive && x.routineTypeId == RoutineType.Template).length);
	private inactiveRoutineCount = computed(() => this.allRoutines().filter(x => !x.isActive).length);

	@Output() readonly onCreateRoutine = new EventEmitter();
	@Output() readonly onFilterChange = new EventEmitter<MemberRoutineTemplateFilterType>();

	public menuData: FuseNavigationItem[];

	public selectedFilter = signal<MemberRoutineTemplateFilterType>(MemberRoutineTemplateFilterType.MyRoutines);

	public constructor()
	{
	}

	public ngOnChanges(): void
	{
		this.buildNavigation();
	}

	private buildNavigation(): void
	{
		this.menuData =
			[
				{
					title: 'Actions',
					type: 'group',
					children:
						[
							{
								title: 'Create routine',
								type: 'basic',
								icon: 'add_circle',
								function: () =>
								{
									this.onCreateRoutine.emit();
								}
							}
						]
				},
				{
					title: 'Filters',
					type: 'group',
					children:
						[
							{
								id: "1",
								title: 'My routines',
								type: 'basic',
								icon: 'check_circle',
								badge:
								{
									title: this.activeMemberRoutineCount().toString(),
									classes: 'px-2 bg-primary text-on-primary rounded-full'
								},
								function: () =>
								{
									this.selectedFilter.set(MemberRoutineTemplateFilterType.MyRoutines);
									this.onFilterChange.emit(MemberRoutineTemplateFilterType.MyRoutines);
									this.buildNavigation();
								},
								active: this.selectedFilter() == MemberRoutineTemplateFilterType.MyRoutines
							},
							{
								id: "3",
								title: 'Inactive routines',
								type: 'basic',
								icon: 'cancel',
								badge:
								{
									title: this.inactiveRoutineCount().toString(),
									classes: 'px-2 bg-primary text-on-primary rounded-full'
								},
								function: () =>
								{
									this.selectedFilter.set(MemberRoutineTemplateFilterType.InactiveRoutines);
									this.onFilterChange.emit(MemberRoutineTemplateFilterType.InactiveRoutines);
									this.buildNavigation();
								},
								active: this.selectedFilter() == MemberRoutineTemplateFilterType.InactiveRoutines
							}
						]
				}
			];
	}
}
