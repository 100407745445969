export enum ResistanceType
{
	All = 0,
	BodyWeight = 1,
	Barbell = 2,
	Dumbbell = 3
}

export const ResistanceTypeDescriptions = new Map<ResistanceType, string>
	(
		[
			[ResistanceType.All, 'All'],
			[ResistanceType.BodyWeight, 'BodyWeight'],
			[ResistanceType.Barbell, 'Barbell'],
			[ResistanceType.Dumbbell, 'Dumbbell'],
		]
	);