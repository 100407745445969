import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';
import { EmployeeProfileGuard } from '@fitness-central/core/authentication/guard/professional-profile-guard';

import { clientsDetailRoutes } from './detail/detail.routes';
import { clientTrainingHistoryRoutes } from './detail/tabs/training/history/history.routes';

export const clientRoutes: Route[] =
	[
		{
			path: '',
			loadComponent: () => import('./list/list.component').then(c => c.ClientListComponent),
			canActivate: [AuthGuard, EmployeeProfileGuard]
		},
		{
			path: ':clientId/training/history',
			children:
				[
					...clientTrainingHistoryRoutes
				],
			canActivate: [AuthGuard]
		},
		{
			path: ':clientId/training/workout/:routineId',
			loadComponent: () => import('./detail/tabs/training/workout/workout.component').then(c => c.ClientTrainingWorkoutComponent),
			canActivate: [AuthGuard]
		},
		{
			path: ':clientId',
			loadComponent: () => import('./detail/detail.component').then(c => c.ClientDetailComponent),
			children:
				[
					...clientsDetailRoutes
				],
			canActivate: [AuthGuard, EmployeeProfileGuard]
		},
		{
			path: '**',
			canActivate: [EmployeeProfileGuard],
			redirectTo: ''
		}
	];