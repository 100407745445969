import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { merge } from 'lodash-es';

import { FuseConfirmationConfig } from '@fuse/services/confirmation/confirmation.types';
import { FuseConfirmationDialogComponent } from '@fuse/services/confirmation/dialog/dialog.component';

@Injectable()
export class FuseConfirmationService
{
	private _defaultConfig: FuseConfirmationConfig = {
		title: 'Confirm action',
		message: 'Are you sure you want to confirm this action?',
		icon: {
			show: true,
			name: 'warning',
			color: 'warn'
		},
		actions: {
			confirm: {
				show: true,
				label: 'Confirm',
				color: 'warn'
			},
			cancel: {
				show: true,
				label: 'Cancel'
			}
		},
		dismissible: false
	};

	/**
	 * Constructor
	 */
	constructor(
		private _matDialog: MatDialog
	)
	{
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	open(config: FuseConfirmationConfig = {}): MatDialogRef<FuseConfirmationDialogComponent>
	{
		// Merge the user config with the default config
		const userConfig = merge({}, this._defaultConfig, config);

		// Open the dialog
		return this._matDialog.open(FuseConfirmationDialogComponent, {
			autoFocus: false,
			disableClose: !userConfig.dismissible,
			data: userConfig,
			panelClass: 'fuse-confirmation-dialog-panel'
		});
	}
}
