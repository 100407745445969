import { Location, NgFor, NgIf } from '@angular/common';
import { Component, effect, Signal, viewChild, ViewEncapsulation } from '@angular/core';

import { SidebarViewComponent } from '@abp/components/sidebar-view/sidebar-view.component';
import { ClientDetailNavigationMenuItemType } from 'app/employee/clients/detail/_components/navigation/models/menu-item-types.enum';
import { ClientDetailMessageService } from 'app/employee/clients/detail/_message-services/detail.message-service';

import { ClientBodyFatMeasurementResponseModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement-response.model';
import { ClientBodyFatMeasurementService } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement.service';
import { ClientBodyFatSiteMeasurementService } from '@fitness-central/api/client/body-composition/body-fat/body-fat-site-measurement/body-fat-site-measurement.service';
import { ClientResponseModel } from '@fitness-central/api/client/client/client-response.model';
import { ContentHeaderComponent } from '@fitness-central/shared/components/ui/content-header/content-header.component';

import { forkJoin, Observable, Subject, takeUntil } from 'rxjs';

import { ClientDetailBodyCompositionBodyFatMeasurementSidebarCreateComponent } from '../_components/sidebars/body-fat-measurement/create/create.component';
import { ClientDetailBodyCompositionBodyFatMeasurementSidebarDeleteComponent } from '../_components/sidebars/body-fat-measurement/delete/delete.component';
import { ClientDetailBodyCompositionBodyFatMeasurementSidebarEditComponent } from '../_components/sidebars/body-fat-measurement/edit/edit.component';
import { ClientBodyFatMeasurementViewModel } from '../_view-models/body-fat-measurement.view-model';
import { ClientDetailBodyCompositionMessageService } from '../body-composition.message-service';
import { ClientDetailBodyCompositionBodyFatMeasurementCardComponent } from './_components/card/card.component';
import { ClientDetailBodyCompositionBodyFatMeasurementNavigationComponent } from './_components/navigation/navigation.component';

@Component({
	selector: 'client-detail-body-composition-body-fat-measurement',
	templateUrl: './body-fat-measurement.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [SidebarViewComponent, ContentHeaderComponent, ClientDetailBodyCompositionBodyFatMeasurementNavigationComponent, ClientDetailBodyCompositionBodyFatMeasurementSidebarCreateComponent, ClientDetailBodyCompositionBodyFatMeasurementSidebarEditComponent, ClientDetailBodyCompositionBodyFatMeasurementSidebarDeleteComponent, NgIf, NgFor, ClientDetailBodyCompositionBodyFatMeasurementCardComponent]
})
export class ClientDetailBodyCompositionBodyFatMeasurementComponent
{
	public sidebarView = viewChild(SidebarViewComponent);

	public client: Signal<ClientResponseModel>;

	public isLoading: boolean = true;

	public selectedBodyFatMeasurement: ClientBodyFatMeasurementViewModel = new ClientBodyFatMeasurementViewModel(new ClientBodyFatMeasurementResponseModel);

	public bodyFatMeasurements: ClientBodyFatMeasurementViewModel[] = [];

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	public constructor
		(
			private _bodyFatMeasurementService: ClientBodyFatMeasurementService,
			private _bodyFatSiteMeasurementService: ClientBodyFatSiteMeasurementService,
			private _bodyCompositionMessageService: ClientDetailBodyCompositionMessageService,
			private readonly _clientDetailMessageService: ClientDetailMessageService,
			private readonly _location: Location
		)
	{
		this._clientDetailMessageService.selectedNavigationItemType.set(ClientDetailNavigationMenuItemType.BodyComposition);
		this.client = this._clientDetailMessageService.client;

		this.subscribeToNavigationChange();

		effect(() => this.getBodyFatMeasurements(this._bodyCompositionMessageService.dateFilterStartDate(), this._bodyCompositionMessageService.dateFilterEndDate()));
	}

	public routeToBack()
	{
		this._location.back();
	}

	public create()
	{
		this.sidebarView().openCreateSidebar();
	}

	public edit(bodyFatMeasurement: ClientBodyFatMeasurementViewModel)
	{
		this.selectedBodyFatMeasurement = bodyFatMeasurement;
		this.sidebarView().openEditSidebar();
	}

	public delete(bodyFatMeasurement: ClientBodyFatMeasurementViewModel)
	{
		this.selectedBodyFatMeasurement = bodyFatMeasurement;
		this.sidebarView().openDeleteSidebar();
	}

	public cancel()
	{
		this.sidebarView().closeSidebar();
	}

	public toggleNavigation(isOpen: boolean)
	{
		if (isOpen)
		{
			this.sidebarView().openNavigation();
		}
		else
		{
			this.sidebarView().closeNavigation();
		}
	}

	public created(createdBodyFatMeasurement: ClientBodyFatMeasurementResponseModel)
	{
		this.getBodyFatWithSiteMeasurements(createdBodyFatMeasurement)
			.subscribe
			(
				{
					next: bodyFatMeasurementViewModel =>
					{
						this.bodyFatMeasurements.push(bodyFatMeasurementViewModel);
						this.bodyFatMeasurements = this.bodyFatMeasurements.sort((first, second) => 0 - (first.measurementDate < second.measurementDate ? -1 : 1));

						this.sidebarView().closeSidebar();
					}
				}
			)
	}

	public updated(updatedBodyFatMeasurement: ClientBodyFatMeasurementResponseModel)
	{
		this.getBodyFatWithSiteMeasurements(updatedBodyFatMeasurement)
			.subscribe
			(
				{
					next: bodyFatMeasurementViewModel =>
					{
						let bodyFatMeasurementIndex = this.bodyFatMeasurements.findIndex(bodyFatMeasurement => bodyFatMeasurement.bodyFatMeasurementId == bodyFatMeasurementViewModel.bodyFatMeasurementId);

						this.bodyFatMeasurements.splice(bodyFatMeasurementIndex, 1, bodyFatMeasurementViewModel);
						this.bodyFatMeasurements = this.bodyFatMeasurements.sort((first, second) => 0 - (first.measurementDate < second.measurementDate ? -1 : 1));

						this.sidebarView().closeSidebar();
					}
				}
			)
	}

	public deleted(deletedBodyFatMeasurement: ClientBodyFatMeasurementResponseModel)
	{
		this.getBodyFatWithSiteMeasurements(deletedBodyFatMeasurement)
			.subscribe
			(
				{
					next: bodyFatMeasurementViewModel =>
					{
						let bodyFatMeasurementIndex = this.bodyFatMeasurements.findIndex(bodyFatMeasurement => bodyFatMeasurement.bodyFatMeasurementId == bodyFatMeasurementViewModel.bodyFatMeasurementId);

						this.bodyFatMeasurements.splice(bodyFatMeasurementIndex, 1);

						this.bodyFatMeasurements = this.bodyFatMeasurements.sort((first, second) => 0 - (first.measurementDate < second.measurementDate ? -1 : 1));

						this.sidebarView().closeSidebar();
					}
				}
			)
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}

	private subscribeToNavigationChange()
	{
		this._clientDetailMessageService
			.navigationOpenAction$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: () =>
					{
						this.sidebarView().toggleNavigation();
					}
				}
			)
	}

	private getBodyFatMeasurements(startDate: Date, endDate: Date)
	{
		this._bodyFatMeasurementService
			.getByClientId(this.client().clientId, startDate, endDate)
			.pipe
			(
				takeUntil(this._unsubscribeAll)
			)
			.subscribe
			(
				{
					next: bodyFatMeasurements =>
					{
						let tasks: Observable<ClientBodyFatMeasurementViewModel>[] = [];

						bodyFatMeasurements.map
							(
								bodyFatMeasurement => 
								{
									tasks.push(this.getBodyFatWithSiteMeasurements(bodyFatMeasurement));
								}
							);

						forkJoin(tasks)
							.pipe(takeUntil(this._unsubscribeAll))
							.subscribe
							(
								{
									next: bodyFatMeasurementViewModels =>
									{
										this.bodyFatMeasurements = bodyFatMeasurementViewModels;
									},
									complete: () =>
									{
										this.isLoading = false;
									}
								}
							)
					}
				}
			);
	}

	private getBodyFatWithSiteMeasurements(bodyFatMeasurement: ClientBodyFatMeasurementResponseModel): Observable<ClientBodyFatMeasurementViewModel>
	{
		let bodyFatMeasurementViewModel = new ClientBodyFatMeasurementViewModel(bodyFatMeasurement);

		const result = new Observable<ClientBodyFatMeasurementViewModel>
			(
				observer =>
				{
					this._bodyFatSiteMeasurementService
						.getByBodyFatMeasurement(bodyFatMeasurement.bodyFatMeasurementId)
						.pipe(takeUntil(this._unsubscribeAll))
						.subscribe
						(
							{
								next: bodyFatSiteMeasurements =>
								{
									bodyFatMeasurementViewModel.bodyFatSiteMeasurements = [...bodyFatSiteMeasurements];

									observer.next(bodyFatMeasurementViewModel);
									observer.complete();
								}
							}
						)
				}
			)

		return result;
	}
}
