import { KeyValuePipe, NgFor, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Signal, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule } from '@angular/material/legacy-select';

import { BodyFatCalculationType } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-calculation-type.enum';
import { ClientBodyFatMeasurementResponseModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement-response.model';
import { ClientBodyFatMeasurementService } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement.service';
import { ClientResponseModel } from '@fitness-central/api/client/client/client-response.model';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ClientBodyFatMeasurementViewModel } from '../../../../_view-models/body-fat-measurement.view-model';
import { ClientDetailBodyCompositionBodyFatMeasurementSidebarDeleteJacksonPollock3SiteComponent } from './jackson-pollock-3-site/jackson-pollock-3-site.component';
import { ClientDetailBodyCompositionBodyFatMeasurementSidebarDeleteJacksonPollock7SiteComponent } from './jackson-pollock-7-site/jackson-pollock-7-site.component';
import { ClientDetailBodyCompositionBodyFatMeasurementSidebarDeleteManualComponent } from './manual/manual.component';

@Component({
	selector: 'client-detail-body-composition-body-fat-measurement-sidebar-delete',
	templateUrl: './delete.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, MatLegacyButtonModule, MatIconModule, MatLegacySelectModule, NgFor, MatLegacyOptionModule, NgSwitch, NgSwitchCase, ClientDetailBodyCompositionBodyFatMeasurementSidebarDeleteManualComponent, ClientDetailBodyCompositionBodyFatMeasurementSidebarDeleteJacksonPollock3SiteComponent, ClientDetailBodyCompositionBodyFatMeasurementSidebarDeleteJacksonPollock7SiteComponent, KeyValuePipe]
})

export class ClientDetailBodyCompositionBodyFatMeasurementSidebarDeleteComponent implements OnInit, OnChanges, OnDestroy
{
	@Input() client: Signal<ClientResponseModel>;
	@Input() bodyFatMeasurement: ClientBodyFatMeasurementViewModel;

	@Output() onDeleted = new EventEmitter<ClientBodyFatMeasurementResponseModel>();
	@Output() onCancel = new EventEmitter();

	public selectedBodyFatCalculationType: BodyFatCalculationType = BodyFatCalculationType.Manual;
	public bodyFatCalculationTypes: typeof BodyFatCalculationType = BodyFatCalculationType;

	public bodyFatCalculationTypeList: { [key: string]: number };

	public showAlert: boolean = false;

	public bodyWeightMeasurement: ClientBodyFatMeasurementResponseModel = new ClientBodyFatMeasurementResponseModel();
	public bodyWeightMeasurementForm: FormGroup;

	private _unsubscribeAll: Subject<any>;

	public constructor
		(
			public readonly _bodyFatMeasurementService: ClientBodyFatMeasurementService,
		)
	{
		this._unsubscribeAll = new Subject<any>();
	}

	public ngOnInit(): void
	{
		this.getBodyFatCalculationTypes();
	}

	public ngOnChanges(): void
	{
		this.selectedBodyFatCalculationType = this.bodyFatMeasurement.bodyFatCalculationTypeId;
	}

	public bodyFatMeasurementDeleted(bodyFatMeasurmentId: string)
	{
		this._bodyFatMeasurementService
			.delete(bodyFatMeasurmentId)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: bodyFatMeasurement =>
					{
						this.onDeleted.emit(bodyFatMeasurement);
					}
				}
			);
	}

	public cancel()
	{
		this.onCancel.emit();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}

	private getBodyFatCalculationTypes()
	{
		this.bodyFatCalculationTypeList =
		{
			'Manual Entry': 1,
			'Jackson-Pollock 3-Site': 2,
			'Jackson-Pollock 7-Site': 3
		}
	}
}
