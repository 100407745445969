import { Guid } from '@abp/guid';

export class MemberRoutineResistanceExerciseRequestModel
{
	public routineResistanceExerciseId: string = Guid.empty;
	public routineId: string = Guid.empty;
	public resistanceExerciseId: number = 0;
	public description: string = "";
	public order: number = 0;
	public isActive: boolean = true;
}
