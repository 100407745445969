import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { JwtHelper } from '@fitness-central/core/helper/jwt.helper';

import { Observable } from 'rxjs';

import { ClientBodyWeightCreateRequestModel } from './body-weight-create-request.model';
import { ClientBodyWeightResponseModel } from './body-weight-response.model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class ClientBodyWeightService
{
	public constructor
	(
		private http: HttpClient,
		private jwtHelper: JwtHelper
	)
	{
	}

	public getByClientId(clientId : string, startDate : Date, endDate : Date) : Observable<ClientBodyWeightResponseModel[]>
	{
		const url = environment.baseUrl + `/client/body-weight-measurements/client/${ clientId }?startDate=${ startDate.toDateString() }&endDate=${ endDate.toDateString() }`;

		return this.http.get<ClientBodyWeightResponseModel[]>(url);
	}

	public get(bodyWeightId: string) : Observable<ClientBodyWeightResponseModel>
	{
		const url = environment.baseUrl + `/client/body-weight-measurement/` + bodyWeightId;

		return this.http.get<ClientBodyWeightResponseModel>(url);
	}

	public create(bodyWeight: ClientBodyWeightCreateRequestModel): Observable<ClientBodyWeightResponseModel>
	{
		const url = environment.baseUrl + `/client/body-weight-measurement`;
		
		return this.http.post<ClientBodyWeightResponseModel>(url, bodyWeight);
	}

	public update(bodyWeight: ClientBodyWeightResponseModel): Observable<ClientBodyWeightResponseModel>
	{
		const url = environment.baseUrl + `/client/body-weight-measurement/` + bodyWeight.bodyWeightMeasurementId;

		return this.http.put<ClientBodyWeightResponseModel>(url, bodyWeight)
	}

	public delete(bodyWeightId: string): Observable<ClientBodyWeightResponseModel>
	{
		const url = environment.baseUrl + `/client/body-weight-measurement/` + bodyWeightId;

		return this.http.delete<ClientBodyWeightResponseModel>(url)
	}
}
