import { NgFor, NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { Router } from '@angular/router';

import { DefaultProfileImageComponent } from '@abp/components/default-profile-image/default-profile-image.component';

import { ImageResponse } from '@fitness-central/api/community/image/image.response-model';
import { ProfileImageService } from '@fitness-central/api/community/image/image.service';
import { ProfileResponseModel } from '@fitness-central/api/community/profile/profile.response-model';
import { ProfileService } from '@fitness-central/api/community/profile/profile.service';

import { Subject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';

import { ProfileFollowingViewModel } from './following.view-model';

@Component({
	selector: 'profile-following',
	templateUrl: './following.component.html',
	standalone: true,
	imports: [NgIf, MatGridListModule, NgFor, DefaultProfileImageComponent, MatLegacyTooltipModule]
})

export class ProfileFollowingComponent implements OnInit, OnDestroy
{
	@Input() public profileId: string;

	public profiles: ProfileResponseModel[] = [];
	public profileImages: ImageResponse[] = [];

	public followingProfiles: ProfileFollowingViewModel[] = [];

	private defaultGuid: string = "00000000-0000-0000-0000-000000000000";

	public isLoading: boolean = true;

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	constructor
		(
			private _profileService: ProfileService,
			private _profileImageService: ProfileImageService,
			private _router: Router
		)
	{ }

	public ngOnInit()
	{
		this.getFollowedProfiles();
	}

	public routeToProfile(selectedIndex: number)
	{
		let handle = this.followingProfiles[selectedIndex].handle;
		this._router.navigateByUrl(`/community/profile/public/${handle}`);
	}

	private getFollowedProfiles()
	{
		this._profileService
			.getFollowed(this.profileId, this.defaultGuid, 16)
			.pipe
			(
				mergeMap
					(
						profileList =>
						{
							this.profiles = profileList.profiles;

							return profileList.profiles;
						}
					)
			)
			.subscribe
			(
				{
					next: profile =>
					{
						this.getProfileImage(profile);
					},
					complete: () =>
					{
						this.isLoading = false;
					}
				}
			)
	}

	private getProfileImage(profile: ProfileResponseModel)
	{
		this._profileImageService
			.getByProfileId(profile.profileId)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: profileImage =>
					{
						let followedProfile = new ProfileFollowingViewModel();

						followedProfile.profileId = profile.profileId;
						followedProfile.displayName = profile.displayName;
						followedProfile.handle = profile.handle;
						followedProfile.sasUri = profileImage.sasUri;

						this.followingProfiles.push(followedProfile);
					}
				}
			)
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
