import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ui-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.css'],
    standalone: true,
    imports: [NgIf, MatLegacyButtonModule, MatIconModule]
})

export class PageHeaderComponent implements OnInit 
{
	@Input() title : string = '';
	@Input() titleSegment : string = '';

	@Input() subtitle : string = '';
	@Input() subtitleSegment : string = '';
	
	@Input() hasNavigation : boolean = true;

	@Output() onToggleNavigation = new EventEmitter();

	constructor() { }

	public ngOnInit() 
	{
	}

	public toggleNavigation()
	{
		this.onToggleNavigation.emit();
	}
}
