import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import { VerificationService } from '@fitness-central/api/identity/verification/verification.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertType } from '@fuse/components/alert';
import { FuseAlertModule } from '@fuse/components/alert/alert.module';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'authentication-verification',
	templateUrl: './verification.component.html',
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations,
	standalone: true,
	imports: [NgIf, FuseAlertModule, RouterLink, MatLegacyProgressSpinnerModule]
})
export class AuthenticationVerificationComponent implements OnInit, OnDestroy
{
	public alert:
		{
			type: FuseAlertType,
			message: string
		} =
		{
			type: 'success',
			message: ''
		};

	public showAlert: boolean = false;
	public verificationCode: string = '';

	public isLoading: boolean = true;

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	public constructor
		(
			private _activatedRoute: ActivatedRoute,
			private _router: Router,
			private _verificationService: VerificationService
		)
	{
	}

	public ngOnInit(): void
	{
		this._activatedRoute.queryParams.subscribe
			(
				params =>
				{
					this.verificationCode = params['verificationCode'];

					if (this.verificationCode)
					{
						this.verifyCode();
					}
				}
			);
	}

	private verifyCode()
	{
		const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

		this.
			_verificationService
			.verifyCode(this.verificationCode)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: async userName =>
					{
						// Sleep for 5 seconds to allow for Azure to Sync
						await sleep(5000);

						this._router.navigate
							(
								[`/authentication/signin`],
								{
									queryParams:
									{
										registeredUser: userName,
										isVerified: true
									}
								}
							);
					},
					error: errorMessage =>
					{
						// Set the alert
						this.alert =
						{
							type: 'error',
							message: `${errorMessage}`
						};

						// Show the alert
						this.showAlert = true;
					}
				}
			);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
