export class PostCommentResponse
{
	public postCommentId: string;
	public postId: string;
	public postCommentParentId: string;
	public profileId: string;
	public comment: string;
	public createdDate: Date;
	public modifiedDate: Date;
	public replyCount: number;

	constructor()
	{
		this.postCommentId = "00000000-0000-0000-0000-000000000000";
		this.postId = "00000000-0000-0000-0000-000000000000";
		this.postCommentParentId = "00000000-0000-0000-0000-000000000000";
		this.profileId = "00000000-0000-0000-0000-000000000000";
		this.comment = '';
		this.createdDate = new Date();
		this.modifiedDate = new Date();
		this.replyCount = 0;
	}
}
