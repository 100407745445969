export enum GenderType
{
	NotSelected = 1,
	Male = 2,
	Female = 3
}

export const GenderTypeDescriptions = new Map<GenderType, string>
(
	[
		[GenderType.NotSelected, 'Not Selected'],
		[GenderType.Male, 'Male'],
		[GenderType.Female, 'Female']
	]
);
