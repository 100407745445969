export class Guid 
{
	public static readonly empty: string = "00000000-0000-0000-0000-000000000000";

	private value : string;

	public constructor(value : string)
	{
		this.value = this.validate(value);
	}


	private validate(value : string) : string
	{
		if (value.length > 0) 
		{
			if (!(/^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/).test(value)) 
			{
				throw "Guid should contain 32 digits with 4 dashes (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)";
			}
		}
	
		return value;
	}
}