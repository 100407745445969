<fuse-card class="flex flex-col min-w-70 m-2 sm:mx-4 w-100-p">
	<ng-container content>
		<div class="flex flex-row">
			<div class="flex flex-col p-4 justify-center">
				<ng-container *ngIf="!message.isRead">
					<mat-icon class="icon-size-5 text-hint text-gray-500" 
						[svgIcon]="'heroicons_outline:mail'" 
						[matTooltip]="'Unread'">
					</mat-icon>
				</ng-container>
				<ng-container *ngIf="message.isRead">
					<mat-icon class="icon-size-5 text-hint text-gray-500" 
						[svgIcon]="'heroicons_outline:mail-open'" 
						[matTooltip]="'Read'">
					</mat-icon>
				</ng-container>
			</div>
			<div class="flex flex-col w-11/12 p-4 cursor-pointer"
				(click)="selectMessage()">
				
				<ng-container *ngIf="!message.isRead">
					<div class="flex flex-col font-bold">
						<div>
							{{ message.displayName }}
						</div>
					</div>
					<div class="font-medium">
						{{ message.subject }}
					</div>
				</ng-container>

				<ng-container *ngIf="message.isRead">
					<div class="flex flex-col font-medium">
						<div>
							{{ message.displayName }}
						</div>
					</div>
					<div class="font-normal">
						{{ message.subject }}
					</div>
				</ng-container>
				<div class="flex flex-col sm:flex-row sm:items-center -ml-0.5 mt-2 sm:mt-1 space-y-1 sm:space-y-0 sm:space-x-3">
					<ng-container >
						<div class="flex items-center">
							<div class="ml-1.5 text-md text-secondary"></div>
						</div>
					</ng-container>
				</div>
			</div>
			<div class="flex flex-col justify-center w-1/12">
				<div class="flex flex-row justify-end pr-2 mt-1">
					<button 
						mat-icon-button
						(click)="toggleActionBarVisibility()">
						<mat-icon class="text-gray-300">more_vert</mat-icon>
					</button>
				</div>
			</div>
		</div>
		<ng-container *ngIf="isActionBarVisible">
			<div class="flex flex-row w-full border border-gray-200"></div>
			<div class="flex flex-col items text-md font-medium bg-accent text-on-accent">
				<div class="flex flex-row justify-start">
					<div class="flex flex-col justify-center">
						<div class="flex flex-row space-x-4 p-1 px-4">
							<button mat-icon-button
								(click)="deleteMessage()">
								<mat-icon class="text-red-400"
									[matTooltip]="'Delete Message'">delete</mat-icon>
							</button>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</ng-container>
</fuse-card>