import { MessageViewModel } from './message.view-model';

export class MessageListViewModel
{
	public messageDate: Date;
	public messages : MessageViewModel[];

	constructor()
	{
		this.messageDate = new Date();
		this.messages = [];
	}
}
