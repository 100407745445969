import { BodyFatCalculationType } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-calculation-type.enum';
import { ClientBodyFatMeasurementResponseModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement-response.model';
import { ClientBodyFatSiteMeasurementResponseModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-site-measurement/body-fat-site-measurement-response.model';

export class ClientBodyFatMeasurementViewModel
{
	public bodyFatMeasurementId: string;
	public clientId: string;
	public bodyFatCalculationTypeId : BodyFatCalculationType;
	public value : number;
	public measurementDate : Date;
	public bodyFatSiteMeasurements : ClientBodyFatSiteMeasurementResponseModel[];

	constructor(bodyFatMeasurement: ClientBodyFatMeasurementResponseModel)
	{
		this.bodyFatMeasurementId = bodyFatMeasurement.bodyFatMeasurementId;
		this.clientId = bodyFatMeasurement.clientId;
		this.bodyFatCalculationTypeId = bodyFatMeasurement.bodyFatCalculationTypeId;
		this.value = bodyFatMeasurement.value
		this.measurementDate = bodyFatMeasurement.measurementDate;
		this.bodyFatSiteMeasurements = [];
	}
}