import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { ProfilePublicComponent } from './public.component';

export const profilePublicRoutes: Route[] =
[
	{
		path: '',
		component: ProfilePublicComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'following',
		loadComponent: () => import('./profiles/following/following.component').then(c => c.ProfilePublicMemberFollowingComponent),
		canActivate: [AuthGuard]
	},
	{
		path: 'followers',
		loadComponent: () => import('./profiles/follower/follower.component').then(c => c.ProfilePublicMemberFollowerComponent),
		canActivate: [AuthGuard]
	},
	{
		path: '**',
		redirectTo: ''
	}
];