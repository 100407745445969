import { PostType } from '../../_enums/profile-post-type.enum';

export class PostSidebarViewModel
{
	public profileId: string;
	public ownerProfileId: string;
	public ownerDisplayName: string;
	public ownerHandle: string;
	public ownerProfileImageUrl: string;
	public postType: PostType

	constructor()
	{
		this.profileId = '';
		this.ownerProfileId = '';
		this.ownerDisplayName = '';
		this.ownerHandle = '';
		this.ownerProfileImageUrl = '';
		this.postType = PostType.None;
	}
}
