import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { ClientWorkoutResistanceExerciseRequestModel } from './workout-resistance-exercise.request-model';
import { ClientWorkoutResistanceExerciseResponseModel } from './workout-resistance-exercise.response-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class ClientWorkoutResistanceExerciseService
{
	public constructor(private http: HttpClient) { }

	private baseUrl = environment.baseUrl + '/client/training/training';

	public get(workoutResistanceExerciseId: string): Observable<ClientWorkoutResistanceExerciseResponseModel>
	{
		const url = environment.baseUrl + `/client/training/workout-resistance-exercise/${ workoutResistanceExerciseId }`;

		return this.http.get<ClientWorkoutResistanceExerciseResponseModel>(url);
	}

	public getByWorkoutId(workoutId: string): Observable<ClientWorkoutResistanceExerciseResponseModel[]>
	{
		const url = environment.baseUrl + `/client/training/workout-resistance-exercises/workout/${ workoutId }`;

		return this.http.get<ClientWorkoutResistanceExerciseResponseModel[]>(url);
	}

	public create(request: ClientWorkoutResistanceExerciseRequestModel) : Observable<ClientWorkoutResistanceExerciseResponseModel>
	{
		const url = environment.baseUrl + '/client/training/workout-resistance-exercise';

		return this.http.post<ClientWorkoutResistanceExerciseResponseModel>(url, request);
	}

	public update(request: ClientWorkoutResistanceExerciseRequestModel) : Observable<ClientWorkoutResistanceExerciseResponseModel>
	{
		const url = environment.baseUrl + `/client/training/workout-resistance-exercise/${ request.workoutResistanceExerciseId }`;

		return this.http.put<ClientWorkoutResistanceExerciseResponseModel>(url, request);
	}

	public updateRange(request: ClientWorkoutResistanceExerciseRequestModel[]) : Observable<ClientWorkoutResistanceExerciseResponseModel[]>
	{
		const url = environment.baseUrl + `/client/training/workout-resistance-exercise`

		return this.http.put<ClientWorkoutResistanceExerciseResponseModel[]>(url, request);
	}
}
