import { Location } from '@angular/common';
import { Component, effect, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';

import { FuseNavigationModule } from '@fuse/components/navigation/navigation.module';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';

import { ClientDetailBodyCompositionMessageService } from '../../../body-composition.message-service';

@Component({
	selector: 'client-detail-body-composition-body-weight-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['navigation.component.scss'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [FuseNavigationModule, FormsModule, ReactiveFormsModule, MatLegacyFormFieldModule, MatDatepickerModule, MatLegacyButtonModule]
})

export class ClientDetailBodyCompositionBodyWeightNavigationComponent implements OnInit
{
	@Output() onCreate = new EventEmitter();

	public menuData: FuseNavigationItem[];

	public filterForm: FormGroup;

	public todaysDate: Date = new Date(Date.now());

	public dateFilterStartDate: Date;
	public dateFilterEndDate: Date = new Date(Date.now());

	public constructor
		(
			private readonly _bodyCompositionMessageService: ClientDetailBodyCompositionMessageService,
			private readonly _formBuilder: FormBuilder,
			private readonly _location: Location
		)
	{
		effect(() => this.applyDateFilter(this._bodyCompositionMessageService.dateFilterStartDate(), this._bodyCompositionMessageService.dateFilterEndDate()));
	}

	ngOnInit(): void
	{
		this.buildFilterForm();
	}

	public changeDateFilter()
	{
		let dateFilterStartDate = this.filterForm.get('dateRangeStart').value;
		let dateFilterEndDate = this.filterForm.get('dateRangeEnd').value;

		if (dateFilterEndDate == null)
		{
			dateFilterEndDate = dateFilterStartDate;
		}

		this._bodyCompositionMessageService.changeDateFilter([dateFilterStartDate, dateFilterEndDate]);
	}

	private buildFilterForm()
	{
		this.filterForm = this._formBuilder.group
			(
				{
					dateRangeStart: [this.dateFilterStartDate],
					dateRangeEnd: [this.dateFilterEndDate]
				}
			);
	}

	private applyDateFilter(startDate: Date, endDate: Date)
	{
		this.dateFilterStartDate = startDate;
		this.dateFilterEndDate = endDate;

		this.buildFilterForm();
		this.buildNavigation();
	}

	private buildNavigation()
	{
		this.menuData =
			[
				{
					title: 'Body Composition',
					type: 'basic',
					icon: 'arrow_back',
					function: (item: FuseNavigationItem) =>
					{
						this._location.back();
					}
				},
				{
					title: 'Actions',
					type: 'group',
					children:
						[
							{
								title: 'Add Body Weight',
								type: 'basic',
								icon: 'add_circle',
								function: (item: FuseNavigationItem) =>
								{
									this.onCreate.emit();
								}
							}
						]
				},
				{
					title: 'Filters',
					type: 'group'
				}
			];
	}
}
