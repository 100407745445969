import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { PostImageRequest } from './post-image.request-model';
import { PostImageResponse } from './post-image.response-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class PostImageService
{
	public constructor(private http: HttpClient) { }

	public create(request: PostImageRequest) : Observable<PostImageResponse>
	{
		const formData = new FormData();
		formData.append('imageFile', request.imageFile);

		const url = environment.baseUrl + `/community/post-image/post/${ request.postId }`

		return this.http.post<PostImageResponse>(url, formData);
	}

	public getByPostImageId(postImageId: string, reducedImageSize: boolean) : Observable<PostImageResponse>
	{
		const url = environment.baseUrl + `/community/post-image/${ postImageId }?reducedImageSize=${ reducedImageSize }`;

		return this.http.get<PostImageResponse>(url);
	}


	public getByPostId(postId: string, reducedImageSize: boolean) : Observable<PostImageResponse[]>
	{
		const url = environment.baseUrl + `/community/post-images/post/${ postId }?reducedImageSize=${ reducedImageSize }`;

		return this.http.get<PostImageResponse[]>(url);
	}

	public getByProfileId(profileId: string, reducedImageSize: boolean, numberOfImages: number) : Observable<PostImageResponse[]>
	{
		let url = environment.baseUrl + `/community/post-images/profile/${ profileId }?reducedImageSize=${ reducedImageSize}&numberOfImages=${ numberOfImages }`;

		if (reducedImageSize)
		{
			url += '&reducedImageSize=true';
		}

		return this.http.get<PostImageResponse[]>(url);
	}
}
