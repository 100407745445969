import { FuseNavigationItem } from '@fuse/components/navigation';

const memberVitalsNavigationItem: FuseNavigationItem =
{
	id: 'vitals',
	title: 'Vitals',
	type: 'collapsable',
	icon: 'vital_signs',
	children:
		[
			{
				id: 'vitals.bloodglucose',
				title: 'Blood Glucose',
				type: 'basic',
				icon: 'glucose',
				link: '/member/vitals/blood-glucose'
			},
			{
				id: 'vitals.bloodoxygen',
				title: 'Blood Oxygen',
				type: 'basic',
				icon: 'spo2',
				link: '/member/vitals/blood-oxygen'
			},
			{
				id: 'vitals.bloodpressure',
				title: 'Blood Pressure',
				type: 'basic',
				icon: 'blood_pressure',
				link: '/member/vitals/blood-pressure'
			},
			{
				id: 'vitals.bodytemperature',
				title: 'Body Temperature',
				type: 'basic',
				icon: 'thermometer',
				link: '/member/vitals/body-temperature'
			},
			{
				id: 'vitals.breathingrate',
				title: 'Breathing Rate',
				type: 'basic',
				icon: 'air',
				link: '/member/vitals/breathing-rate'
			},
			{
				id: 'vitals.heartrate',
				title: 'Heart Rate',
				type: 'basic',
				icon: 'monitor_heart',
				link: '/member/vitals/heart-rate'
			}
		]
};

export const memberVitalsNavigation: FuseNavigationItem[] =
	[
		memberVitalsNavigationItem
	];
