<div *ngIf="!showInitials" class="bg-white rounded-full w-full h-full">
	<div class="flex flex-row w-full h-full justify-center">
		<div class="flex flex-col justify-center">
			<img [src]="profileUrl" class="rounded-full bg-cover">
		</div>
	</div>
</div>

<div *ngIf="showInitials" class="bg-primary rounded-full w-full h-full">
	<div class="flex flex-row w-full h-full justify-center">
		<div class="flex flex-col justify-center">
			<span class="text-white font-normal">{{ initials }}</span>
		</div>
	</div>
</div>
