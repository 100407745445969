<fuse-card class="flex flex-col w-full sm:max-w-140 mt-8" #comments="fuseCard">
	<div class="flex items-center mx-6 sm:mx-8 mt-6 mb-4">

		<div  class="h-10 w-10 text-lg mr-4 cursor-pointer" (click)="routeToProfile()">
			<default-profile-image *ngIf="isProfileImageLoaded" [profileUrl]="ownerProfileImageUrl" [displayName]="ownerProfile.displayName"></default-profile-image>
		</div>

		<div class="flex flex-col" *ngIf="ownerProfile">
			<div class="font-semibold leading-none">
				{{ ownerProfile.displayName }}
				<span class="font-light leading-none text-gray-400">
					&#64;{{ ownerProfile.handle }}
				</span>
			</div>
			<span class="text-sm text-secondary leading-none mt-1">
				{{ post.createdDate | timeAgo }}
			</span>
		</div>

		<button *ngIf="canRemovePost"
			class="ml-auto -mr-4"
			mat-icon-button
			[matMenuTriggerFor]="postMenu">
			<mat-icon
				class="icon-size-5"
				[svgIcon]="'heroicons_solid:dots-vertical'"></mat-icon>
		</button>
		<mat-menu #postMenu="matMenu">

			<button mat-menu-item (click)="removePost()">
				<span class="flex items-center">
					<mat-icon
						class="icon-size-5 mr-3"
						[svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
					<span>Remove post</span>
				</span>
			</button>
<!--
			<button mat-menu-item>
				<span class="flex items-center">
					<mat-icon
						class="icon-size-5 mr-3"
						[svgIcon]="'heroicons_solid:save'"></mat-icon>
					<span>Save post</span>
				</span>
			</button>

			<button mat-menu-item>
				<span class="flex items-center">
					<mat-icon
						class="icon-size-5 mr-3"
						[svgIcon]="'heroicons_solid:clock'"></mat-icon>
					<span>Snooze for 30 days</span>
				</span>
			</button>
			<button mat-menu-item>
				<span class="flex items-center">
					<mat-icon
						class="icon-size-5 mr-3"
						[svgIcon]="'heroicons_solid:minus-circle'"></mat-icon>
					<span>Hide all</span>
				</span>
			</button>
			<mat-divider class="my-2"></mat-divider>
			<button mat-menu-item>
				<span class="flex items-center">
					<mat-icon
						class="icon-size-5 mr-3"
						[svgIcon]="'heroicons_solid:exclamation'"></mat-icon>
					<span>Report post</span>
				</span>
			</button>
			<button mat-menu-item>
				<span class="flex items-center">
					<mat-icon
						class="icon-size-5 mr-3"
						[svgIcon]="'heroicons_solid:bell'"></mat-icon>
					<span>Turn on notifications for this post</span>
				</span>
			</button> -->
		</mat-menu>
	</div>

	<div class="mx-6 sm:mx-8 mt-4" [innerHTML]="post.content">
	</div>

	<div class="pt-6 px-1 pb-1">
		<ng-container *ngIf="postImageUrls.length">
			<image-grid
				[imageUrls]="postImageUrls"
				(imageSelected)="onImageSelected($event)">
			</image-grid>
		</ng-container>
	</div>

	<!-- <div class="relative mb-4">
		<img
			class="h-80 object-cover"
			src="assets/images/cards/14-640x480.jpg"
			alt="Card cover image">
		<div
			class="absolute bottom-0 left-0 flex items-center justify-center w-8 h-8 m-4 rounded-full cursor-pointer bg-gray-700"
			[matTooltip]="'Barmouth / UK'"
			[matTooltipPosition]="'right'">
			<mat-icon
				class="icon-size-4 text-gray-100"
				[svgIcon]="'heroicons_solid:location-marker'"></mat-icon>
		</div>
	</div> -->

	<!-- <div class="flex flex-row items-center mt-4 mx-4 mb-4">
		<div class="flex items-center">
			<img
				class="w-6 h-6 m-0.5 rounded-full text-card ring-2 ring-white"
				src="assets/images/avatars/female-02.jpg"
				alt="Card cover image">
			<img
				class="w-6 h-6 m-0.5 rounded-full -ml-3 text-card ring-2 ring-white"
				src="assets/images/avatars/female-04.jpg"
				alt="Card cover image">
			<img
				class="w-6 h-6 m-0.5 rounded-full -ml-3 text-card ring-2 ring-white"
				src="assets/images/avatars/male-09.jpg"
				alt="Card cover image">
			<img
				class="w-6 h-6 m-0.5 rounded-full -ml-3 text-card ring-2 ring-white"
				src="assets/images/avatars/male-07.jpg"
				alt="Card cover image">
			<div class="text-md tracking-tight ml-3 visible sm:invisible">24</div>
			<div class="text-md tracking-tight ml-3 invisible sm:visible">You and 24 more liked this</div>
		</div>
		<div class="flex flex-auto"></div>
		<div class="flex items-center mt-0">
			<button
				class="px-3 mr-1 ml-0"
				mat-button>
				4 shares
			</button>
			<button
				class="px-3 -mr-4"
				mat-button
				(click)="expandableCard02.expanded = !expandableCard02.expanded">
				<span class="mr-1">5 Comments</span>
				<mat-icon
					class="icon-size-5 transform transition-transform ease-in-out duration-150 rotate-0"
					[ngClass]="{'rotate-180': expandableCard02.expanded}"
					[svgIcon]="'heroicons_solid:chevron-down'"></mat-icon>
			</button>
		</div>
	</div> -->

	<mat-divider></mat-divider>

	<mat-menu #postReactMenu="matMenu" class="rounded-3xl">

		<button mat-menu-item class="p-2" (click)="reactToPost(postReactionType.Motivating)">
			<span class="flex items-center justify-center mr-2">
				<img src="/assets/emoji/png/1f4aa.png" class="w-5 mx-4">
				<span class="font-normal text-md">This is Motivating!</span>
			</span>
		</button>
		<button mat-menu-item class="p-2" (click)="reactToPost(postReactionType.Love)">
			<span class="flex items-center justify-center mr-2">
				<img src="/assets/emoji/png/2764.png" class="w-5 mx-4">
				<span class="font-normal text-md">I Love this!</span>
			</span>
		</button>
		<button mat-menu-item class="p-2" (click)="reactToPost(postReactionType.Happy)">
			<span class="flex items-center justify-center mr-2">
				<img src="/assets/emoji/png/1f642.png" class="w-5 mx-4">
				<span class="font-normal text-md">This makes me Happy!</span>
			</span>
		</button>
		<button mat-menu-item class="p-2" (click)="reactToPost(postReactionType.Like)">
			<span class="flex items-center justify-center mr-2">
				<img src="/assets/emoji/png/1f44d.png" class="w-5 mx-4">
				<span class="font-normal text-md">I Like this.</span>
			</span>
		</button>
		<button mat-menu-item class="p-2" (click)="reactToPost(postReactionType.Dislike)">
			<span class="flex items-center justify-center mr-2">
				<img src="/assets/emoji/png/1f44e.png" class="w-5 mx-4">
				<span class="font-normal text-md">I Dislike this.</span>
			</span>
		</button>
		<button mat-menu-item class="p-2" (click)="reactToPost(postReactionType.Sad)">
			<span class="flex items-center justify-center mr-2">
				<img src="/assets/emoji/png/1f641.png" class="w-5 mx-4">
				<span class="font-normal text-md">This makes me Sad.</span>
			</span>
		</button>
		<button mat-menu-item class="p-2" (click)="reactToPost(postReactionType.Angry)">
			<span class="flex items-center justify-center mr-2">
				<img src="/assets/emoji/png/1f621.png" class="w-5 mx-4">
				<span class="font-normal text-md">This makes me Angry!</span>
			</span>
		</button>
		<button mat-menu-item class="p-2" (click)="reactToPost(postReactionType.Demotivating)">
			<span class="flex items-center justify-center mr-2">
				<img src="/assets/emoji/png/1f4a9.png" class="w-5 mx-4">
				<span class="font-normal text-md">This is Demotivating.</span>
			</span>
		</button>
	</mat-menu>

	<div class="flex flex-row justify-between w-full p-2 space-x-2">
		<button *ngIf="postReaction.reactionId > 0"
			(click)="removePostReaction()"
			mat-button
			class="px-2 w-1/3">

			<div [ngSwitch]="postReaction.reactionId">
				<div class="flex items-center" *ngSwitchCase="postReactionType.Motivating">
					<img src="/assets/emoji/png/1f4aa.png" class="w-5 mr-2">
					<span>Motivating!</span>
				</div>
				<div class="flex items-center" *ngSwitchCase="postReactionType.Love">
					<img src="/assets/emoji/png/2764.png" class="w-5 mr-2">
					<span>Love!</span>
				</div>
				<div class="flex items-center" *ngSwitchCase="postReactionType.Happy">
					<img src="/assets/emoji/png/1f642.png" class="w-5 mr-2">
					<span>Happy!</span>
				</div>
				<div class="flex items-center" *ngSwitchCase="postReactionType.Like">
					<img src="/assets/emoji/png/1f44d.png" class="w-5 mr-2">
					<span>Like.</span>
				</div>
				<div class="flex items-center" *ngSwitchCase="postReactionType.Dislike">
					<img src="/assets/emoji/png/1f44e.png" class="w-5 mr-2">
					<span>Dislike.</span>
				</div>
				<div class="flex items-center" *ngSwitchCase="postReactionType.Sad">
					<img src="/assets/emoji/png/1f641.png" class="w-5 mr-2">
					<span>Sad.</span>
				</div>
				<div class="flex items-center" *ngSwitchCase="postReactionType.Angry">
					<img src="/assets/emoji/png/1f621.png" class="w-5 mr-2">
					<span>Angry!</span>
				</div>
				<div class="flex items-center" *ngSwitchCase="postReactionType.Demotivating">
					<img src="/assets/emoji/png/1f4a9.png" class="w-5 mr-2">
					<span>Demotivating.</span>
				</div>
			</div>
		</button>
		<button *ngIf="postReaction.reactionId == 0"
			class="w-1/3 px-4"
			mat-button
			[matMenuTriggerFor]="postReactMenu">
			<div class="flex items-center">
				<mat-icon
					class="icon-size-5 mr-2"
					[svgIcon]="'heroicons_solid:thumb-up'">
				</mat-icon>
				<span>React</span>
			</div>
		</button>

		<div class="w-1/3 px-4"></div>

		<button mat-button class="w-1/3 px-4"
			(click)="togglePostComments()">
			<mat-icon
				class="icon-size-5 mr-2"
				[svgIcon]="'heroicons_solid:chat-alt'"></mat-icon>
			<span>Comment</span>
		</button>
	</div>

	<mat-divider></mat-divider>

	<div class="flex flex-row justify-between items-center mx-8 my-2">
		<div *ngIf="postReactions.length == 0">
			No Reactions
		</div>
		<div class="flex items-center" *ngIf="postReactions.length > 0">
			<img *ngIf="motivatingReactionCount > 0"
				class="w-5 h-5 m-2 rounded-full -ml-1 text-card ring-2 ring-white"
				src="/assets/emoji/png/1f4aa.png"
				matTooltip="Motivating">
			<img *ngIf="loveReactionCount > 0"
				class="w-5 h-5 m-2 rounded-full -ml-1 text-card ring-2 ring-white"
				src="/assets/emoji/png/2764.png"
				matTooltip="Love">
			<img *ngIf="happyReactionCount > 0"
				class="w-5 h-5 m-2 rounded-full -ml-1 text-card ring-2 ring-white"
				src="/assets/emoji/png/1f642.png"
				matTooltip="Happy">
			<img *ngIf="likeReactionCount > 0"
				class="w-5 h-5 m-2 rounded-full -ml-1 text-card ring-2 ring-white"
				src="/assets/emoji/png/1f44d.png"
				matTooltip="Like">
			<img *ngIf="dislikeReactionCount > 0"
				class="w-5 h-5 m-2 rounded-full -ml-1 text-card ring-2 ring-white"
				src="/assets/emoji/png/1f44e.png"
				matTooltip="Dislike">
			<img *ngIf="sadReactionCount > 0"
				class="w-5 h-5 m-2 rounded-full -ml-1 text-card ring-2 ring-white"
				src="/assets/emoji/png/1f641.png"
				matTooltip="Sad">
			<img *ngIf="angryReactionCount > 0"
				class="w-5 h-5 m-2 rounded-full -ml-1 text-card ring-2 ring-white"
				src="/assets/emoji/png/1f621.png"
				matTooltip="Angry">
			<img *ngIf="demotivatingReactionCount > 0"
				class="w-5 h-5 m-2 rounded-full -ml-1 text-card ring-2 ring-white"
				src="/assets/emoji/png/1f4a9.png"
				matTooltip="Demotivating">
			<div>{{ postReactions.length }}</div>
		</div>
		<div class="flex items-center mt-4 sm:mt-0">
		</div>
		<div class="flex items-center mt-0">
			<ng-container *ngIf="post.commentCount > 0">
				<button mat-button class="px-3 sm:-mr-4"
					(click)="togglePostComments()">
					<span class="font-semibold">
						{{ post.commentCount }} Comments
					</span>

					<mat-icon class="icon-size-5 transform transition-transform ease-in-out duration-150 rotate-0"
						[ngClass]="{'rotate-180': comments.expanded}"
						[svgIcon]="'heroicons_solid:chevron-down'">
					</mat-icon>
				</button>
			</ng-container>
			<span class="font-normal px-3 -mr-4" *ngIf="post.commentCount == 0">
				No Comments
			</span>
		</div>
	</div>

	<ng-container abpCardExpansion *ngIf="areProfileCommentsVisible">
		<mat-divider></mat-divider>
		<div>
			<div class="flex flex-col mx-4 sm:mx-6 mt-4" >
				<profile-post-comment-edit
					[postId]="post.postId"
					(postCommentOutput)="onPostCommentCreated($event)">
				</profile-post-comment-edit>
			</div>
		</div>
		<div>
			<div class="relative flex flex-col mx-4 sm:mx-6  mb-4" >
				<div *ngFor="let postComment of postComments">
					<profile-post-comment [postComment]="postComment"></profile-post-comment>
				</div>
				<div *ngIf="previousPostComments">
					<div class="flex flex-col text-md font-medium bg-card text-on-card">
						<div class="flex flex-row items-center">
							<button
								(click)="togglePreviousPostComments()"
								class="bg-card text-on-card m-2 w-full"
								mat-button>
								<mat-icon>expand_more</mat-icon>
								<div>Display Previous Comments</div>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</fuse-card>
