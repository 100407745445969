export enum PostReactionType
{
	None = 0,
	Motivating = 1,
	Love = 2,
	Happy = 3,
	Like = 4,
	Dislike = 5,
	Sad = 6,
	Angry = 7,
	Demotivating = 8
}