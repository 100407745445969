import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { profilePrivateRoutes } from './profile/private/private.routes';
import { profilePublicRoutes } from './profile/public/public.routes';
import { memberSearchRoutes } from './search/search.routes';

export const communityRoutes: Route[] =
[
	{
		path: 'search',
		children: 
		[
			...memberSearchRoutes
		],
		canActivate: [AuthGuard]
	},
	{
		path: 'profile/public/:handle',
		children: 
		[
			...profilePublicRoutes
		],
		canActivate: [AuthGuard]
	},
	{
		path: 'profile',
		children: 
		[
			...profilePrivateRoutes
		],
		canActivate: [AuthGuard]
	},
	{
		path: '**',
		redirectTo: 'profile'
	}
];
