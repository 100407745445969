<div *ngIf="!isLoading">

	<mat-grid-list cols="4" rowHeight="1:1" gutterSize="10">
		<mat-grid-tile *ngFor="let followingProfile of followingProfiles; index as index">
			<default-profile-image class="h-14 w-14 cursor-pointer" 
				[profileUrl]="followingProfile.sasUri" 
				[displayName]="followingProfile.displayName" 
				[matTooltip]="followingProfile.displayName" 
				(click)="routeToProfile(index)">
			</default-profile-image>
		</mat-grid-tile>
	</mat-grid-list>

	<div class="flex flex-col items-center" *ngIf="!followingProfiles.length">
		<div class="flex flex-row">No members are being followed.</div>
	</div>

	<div class="flex items-center mt-6 -mx-3" *ngIf="followingProfiles.length > 16">
		<button
			class="px-3"
			mat-button
			[color]="'primary'"
			[routerLink]="['./']">
			See all followers
		</button>
	</div>
</div>
