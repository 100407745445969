import { CdkDragEnter, CdkDropList, DragRef, moveItemInArray, CdkDropListGroup, CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgStyle, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'drag-drop-card-container',
    templateUrl: './drag-drop-card-container.component.html',
    styleUrls: ['./drag-drop-card-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CdkDropListGroup, NgStyle, CdkDropList, NgFor, NgIf, CdkDrag, MatIconModule, CdkDragHandle, NgTemplateOutlet]
})

export class DragDropCardContainerComponent
{
	@Input() cardTemplate;
	@Input() cards : any[] = [];
	@Input() isDragDropEnabled : boolean = true;
	@Input() dragHandleTop : string = '0px';
	@Input() dragHandleLeft : string = '0px';

	@Output() onCardReorder = new EventEmitter();

	@ViewChild(CdkDropList) placeholder: CdkDropList;

	private target: CdkDropList = null;
	private targetIndex: number;
	private source: CdkDropList = null;
	private sourceIndex: number;
	private dragRef: DragRef = null;

	public ngAfterViewInit() 
	{
		const placeholderElement = this.placeholder.element.nativeElement;

		placeholderElement.style.display = 'none';
		placeholderElement.parentNode.removeChild(placeholderElement);
	}

	public onDropListDropped() 
	{
		if (!this.target) 
		{
			return;
		}

		const placeholderElement: HTMLElement = this.placeholder.element.nativeElement;
		const placeholderParentElement: HTMLElement = placeholderElement.parentElement;

		placeholderElement.style.display = 'none';

		placeholderParentElement.removeChild(placeholderElement);
		placeholderParentElement.appendChild(placeholderElement);
		placeholderParentElement.insertBefore
		(
			this.source.element.nativeElement,
			placeholderParentElement.children[this.sourceIndex]
		);

		if (this.placeholder._dropListRef.isDragging()) 
		{
			this.placeholder._dropListRef.exit(this.dragRef);
		}

		this.target = null;
		this.source = null;
		this.dragRef = null;

		if (this.sourceIndex !== this.targetIndex) 
		{
			moveItemInArray(this.cards, this.sourceIndex, this.targetIndex);

			this.cards.forEach
			(
				card =>
				{
					card.order = this.cards.indexOf(card) + 1;
				}
			);
			
			this.onCardReorder.emit();
		}
	}

	public onDropListEntered({ item, container }: CdkDragEnter) 
	{
		if (container == this.placeholder) 
		{
			return;
		}

		const placeholderElement: HTMLElement = this.placeholder.element.nativeElement;
		const sourceElement: HTMLElement = item.dropContainer.element.nativeElement;
		const dropElement: HTMLElement = container.element.nativeElement;
		
		const dragIndex: number = Array.prototype.indexOf.call
		(	
			dropElement.parentElement.children,
			this.source ? placeholderElement : sourceElement
		);
	
		const dropIndex: number = Array.prototype.indexOf.call
		(
			dropElement.parentElement.children,
			dropElement
		);

		if (!this.source) 
		{
			this.sourceIndex = dragIndex;
			this.source = item.dropContainer;

			sourceElement.parentElement.removeChild(sourceElement);
		}

		this.targetIndex = dropIndex;
		this.target = container;
		this.dragRef = item._dragRef;

		placeholderElement.style.display = '';

		dropElement.parentElement.insertBefore
		(
			placeholderElement,
			dropIndex > dragIndex ? dropElement.nextSibling : dropElement
		);

		this.placeholder._dropListRef.enter
		(
			item._dragRef,
			item.element.nativeElement.offsetLeft,
			item.element.nativeElement.offsetTop
		);
	}
}
