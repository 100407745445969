import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'default-profile-image',
    templateUrl: './default-profile-image.component.html',
    styleUrls: ['./default-profile-image.component.scss'],
    standalone: true,
    imports: [NgIf]
})

export class DefaultProfileImageComponent implements OnChanges
{
	@Input() public profileUrl: string;
	@Input() public displayName: string;

	public showInitials = false;
	public initials: string;
	public circleColor: string;

	public constructor()
	{
	}

	public ngOnChanges(changes: SimpleChanges): void
	{
		this.showInitials = false;

		if (this.profileUrl == null || !this.profileUrl.length)
		{
			this.createInititals();
		}
	}

	private createInititals(): void
	{
		let initials = "";

		for (let i = 0; i < this.displayName.length; i++)
		{
			if (this.displayName.charAt(i) === ' ')
			{
				continue;
			}

			if (this.displayName.charAt(i) === this.displayName.charAt(i).toUpperCase())
			{
				initials += this.displayName.charAt(i);

				if (initials.length == 2)
				{
					break;
				}
			}
		}

		this.initials = initials;
		this.showInitials = true;
	}
}
