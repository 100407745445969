import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { PostReactionRequest } from './post-reaction.request-model';
import { PostReactionResponse } from './post-reaction.response-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class PostReactionService
{
	public constructor(private http: HttpClient) { }

	public react(request: PostReactionRequest) : Observable<PostReactionResponse>
	{
		const url = environment.baseUrl + `/community/post-reaction/post/${ request.postId }`

		return this.http.post<PostReactionResponse>(url, request);
	}

	public getAll(postId: string) : Observable<PostReactionResponse[]>
	{
		const url = environment.baseUrl + `/community/post-reactions/post/${ postId }`;

		return this.http.get<PostReactionResponse[]>(url);
	}

	public delete(request: PostReactionRequest) : Observable<PostReactionResponse>
	{
		const url = environment.baseUrl + `/community/post-reaction/post/${ request.postId }`

		return this.http.delete<PostReactionResponse>(url, { body : request });
	}
}
