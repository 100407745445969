<div class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between px-2 py-2 lg:py-6 lg:px-8 border-b bg-card dark:bg-transparent">
	<div class="flex-1 min-w-0">
		<div class="flex items-center">
			<div class="flex z-0 lg:hidden pr-2" *ngIf="hasNavigation">
				<button
					mat-icon-button
					(click)="toggleNavigation()">
					<mat-icon>

						menu

					</mat-icon>
				</button>
			</div>

			<div class="flex lg:hidden pr-2 pl-1" *ngIf="!hasNavigation"></div>
			
			<h2>
				<div class="flex flex-col items-start text-md font-medium bg-card">
					<div class="flex flex-row items-center">
						<div class="flex flex-col items-start text-on-default pt-2">
							<div class="flex flex-row text-lg sm:text-xl leading-none">
								{{ title }} <span class="font-normal pl-2">{{ titleSegment }}</span>
							</div>
							<div class="flex flex-row text-secondary font-normal pt-1">
								{{ subtitle }} 
								<ng-container *ngIf="subtitleSegment.length">
									<span class="pl-1">- {{ subtitleSegment }}</span>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</h2>
		</div>
	</div>
</div>