import { FuseNavigationItem } from '@fuse/components/navigation';

const memberCommunityNavigationItem: FuseNavigationItem =
{
	id: 'community',
	title: 'Community',
	type: 'basic',
	icon: 'heroicons_outline:home',
	link: '/community/profile'
};

export const memberCommunityNavigation: FuseNavigationItem[] =
	[
		memberCommunityNavigationItem,
	];
