import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';


import { FuseLoadingBarModule } from '@fuse/components/loading-bar';

import { EmptyLayoutComponent } from './empty-layout.component';

@NgModule
(
	{
    imports: [
    RouterModule,
    FuseLoadingBarModule,
    EmptyLayoutComponent
],
    exports: [
        EmptyLayoutComponent
    ]
}
)
export class EmptyLayoutModule
{
}
