import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';

import { FuseNavigationModule } from '@fuse/components/navigation/navigation.module';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';

@Component({
	selector: 'client-detail-home-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['navigation.component.scss'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [FuseNavigationModule]
})

export class ClientDetailHomeNavigationComponent
{
	@Output() onDetailEdit = new EventEmitter();
	@Output() onContactCreate = new EventEmitter();

	public menuData: FuseNavigationItem[];

	public constructor()
	{
		this.buildNavigation();
	}

	public ngOnChanges()
	{
		this.buildNavigation();
	}

	private buildNavigation()
	{
		this.menuData =
			[
				{
					title: 'Actions',
					type: 'group',
					children:
						[
							{
								title: 'Edit Client Detail',
								type: 'basic',
								icon: 'edit',
								function: (item: FuseNavigationItem) =>
								{
									this.onDetailEdit.emit();
								}
							},
							{
								title: 'Add Contact Information',
								type: 'basic',
								icon: 'add_circle',
								function: (item: FuseNavigationItem) =>
								{
									this.onContactCreate.emit();
								}
							}
						]
				}
			];
	}
}
