import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'elapsedTime',
    standalone: true
})
export class ElapsedTimePipe implements PipeTransform
{
	public displayTime : string;

	private displaySeconds = 0;
	private displayMinutes = 0;
	private displayHours = 0;
	private displayDays = 0;

	private milliSecondsInASecond = 1000;
	private hoursInADay = 24;
	private minutesInAnHour = 60;
	private SecondsInAMinute = 60;


	transform(milliseconds : number): any
	{
		this.displaySeconds = Math.floor((milliseconds) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
		this.displayMinutes = Math.floor((milliseconds) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
		this.displayHours = Math.floor((milliseconds) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
		this.displayDays = Math.floor((milliseconds) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));

		this.displayTime = `${ this.displayHours }h ${ this.displayMinutes }m ${ this.displaySeconds }s`;

		return this.displayTime;
	}
}
