import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { memberBodyCompositionRoutes } from './body-composition/body-composition.routes';
import { memberSettingsRoutes } from './settings/settings.routes';
import { memberTrainingRoutes } from './training/training.routes';
import { memberVitalsRoutes } from './vitals/vitals.routes';

export const memberRoutes: Route[] =
	[
		{
			path: 'body-composition',
			children:
				[
					...memberBodyCompositionRoutes
				],
			canActivate: [AuthGuard]
		},
		{
			path: 'settings',
			children:
				[
					...memberSettingsRoutes
				],
			canActivate: [AuthGuard]
		},
		{
			path: 'training',
			children:
				[
					...memberTrainingRoutes
				],
			canActivate: [AuthGuard]
		},
		{
			path: 'vitals',
			children:
				[
					...memberVitalsRoutes
				],
			canActivate: [AuthGuard]
		},
		{
			path: '**',
			redirectTo: ''
		}
	];