<div class="flex flex-row justify-between px-6 bg-primary">
	<div class="flex flex-col pt-2 text-xl text-on-primary justify-center">Edit Client Contact</div>
	<div class="flex flex-col justify-center">
		<button (click)="cancel()" class="bg-accent text-on-primary my-2" mat-icon-button>
			<mat-icon class="text-on-accent">close</mat-icon>
		</button>
	</div>
</div>

<div class="relative flex flex-col flex-auto items-center">
	<div class="w-full max-w-3xl">
		<form [formGroup]="clientContactForm">
			<fuse-alert class="pb-8" *ngIf="showAlert" [appearance]="'outline'" [showIcon]="false" [type]="alertClientContact.type" [@shake]="alertClientContact.type === 'error'"> {{ alertClientContact.message }} </fuse-alert>
			<div class="flex flex-col space-y-4 mt-8 px-6">
				<div>
					<mat-form-field appearance="outline" class="abp-mat-no-subscript w-full" focused="false">
						<mat-label>Value</mat-label>
						<input matInput formControlName="value">
						<mat-error>Value is required!</mat-error>
					</mat-form-field>
				</div>

				<div>
					<mat-form-field appearance="outline" class="abp-mat-no-subscript w-full">
						<mat-label>Contact Type</mat-label>
						<mat-select formControlName="contactTypeId" required>
							<mat-option value="0" disabled>Choose a Contact Type</mat-option>
							<mat-option *ngFor="let contactType of contactTypes" [value]="contactType.contactTypeId">{{ contactType.value }}</mat-option>
						</mat-select>
						<mat-error>Contact Type is required!</mat-error>
					</mat-form-field>
				</div>
			</div>

			<!-- Actions -->
			<div class="flex items-center w-full mt-10 px-6 py-4 border-t bg-gray-50">
				<button class="ml-auto" mat-button [matTooltip]="'Cancel'" (click)="cancel()"> Cancel </button>
				<button class="ml-2" mat-flat-button [color]="'primary'" [disabled]="clientContactForm.invalid" [matTooltip]="'Save'" (click)="update()"> Save </button>
			</div>
		</form>
	</div>
</div>
