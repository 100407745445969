export class PostImageRequest
{
	public postId: string;
	public imageFile: File;
	public isActive: boolean;

	constructor()
	{
		this.postId = '00000000-0000-0000-0000-000000000000';
		this.imageFile = null;
		this.isActive = false;
	}
}
