<div class="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-8">
	<div class="flex md:w-full md:max-w-6xl sm:rounded-2xl sm:shadow overflow-hidden sm:bg-card">
		<div class="w-full sm:w-auto py-8 px-4 sm:p-12 md:p-16">
			<div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">

				<div class="w-80 -m-2">
					<img src="assets/images/logo/logo-black-text.svg">
				</div>

				<!-- Title -->
				<div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">Verify Account</div>
				<div class="mt-0.5 font-medium">Enter your User Name, below, to receive a new Verification Code.</div>

				<!-- Alert -->
				<fuse-alert
					class="mt-8 -mb-4"
					*ngIf="showAlert"
					[appearance]="'outline'"
					[showIcon]="false"
					[type]="alert.type"
					[@shake]="alert.type === 'error'">
					{{alert.message}}
				</fuse-alert>

				<form
					class="mt-8"
					[formGroup]="requestForm"
					#requestNgForm="ngForm">

					<!-- Email field -->
					<mat-form-field appearance="outline" class="w-full">
						<mat-label>User Name</mat-label>
						<input
							id="email"
							matInput
							[formControlName]="'userName'">
						<mat-error *ngIf="requestForm.get('userName').hasError('required')">
							Username is required
						</mat-error>
						<mat-error *ngIf="requestForm.get('userName').hasError('email')">
							Please enter a valid User Name
						</mat-error>
					</mat-form-field>

					<!-- Submit button -->
					<button
						class="abp-mat-button-large w-full mt-3"
						mat-flat-button
						[color]="'primary'"
						[disabled]="requestForm.disabled"
						(click)="requestVerificationCode()">
						<span *ngIf="!requestForm.disabled">
							Request Verification Code
						</span>
						<mat-progress-spinner
							*ngIf="requestForm.disabled"
							[diameter]="24"
							[mode]="'indeterminate'"></mat-progress-spinner>
					</button>

					<!-- Form footer -->
					<div class="mt-8 text-md font-medium text-secondary">
						<span>Return to</span>
						<a
							class="ml-1 text-primary-500 hover:underline"
							[routerLink]="['/signin']">sign in
						</a>
					</div>

				</form>
			</div>
		</div>
		<div class="relative hidden md:flex flex-auto items-center justify-center h-full p-16 lg:px-28 overflow-hidden bg-gray-800 dark:border-l">
			<!-- Background - @formatter:off -->
			<!-- Rings -->
			<svg class="absolute inset-0 pointer-events-none"
				viewBox="0 0 960 540" width="100%" height="100%" preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg">
				<g class="text-gray-700 opacity-25" fill="none" stroke="currentColor" stroke-width="100">
					<circle r="234" cx="196" cy="23"></circle>
					<circle r="234" cx="790" cy="491"></circle>
				</g>
			</svg>
			<!-- Dots -->
			<svg class="absolute -top-16 -right-16 text-gray-700"
				viewBox="0 0 220 192" width="220" height="192" fill="none">
				<defs>
					<pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
						<rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
					</pattern>
				</defs>
				<rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
			</svg>
			<!-- @formatter:on -->
			<!-- Content -->
			<div class="z-10 relative w-full max-w-2xl">
				<div class="text-7xl font-light leading-none text-gray-100 space-y-4">
					<div class="p-2"> Welcome to </div>
					<div class="w-120">
						<img src="assets/images/logo/logo-white-text.svg">
					</div>
				</div>
				<div class="mt-6 text-lg tracking-tight leading-6 text-gray-400">
				</div>
			</div>
		</div>
	</div>
</div>
