import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { Gender } from './gender-type.model';

@Injectable
(
	{
		providedIn: 'root'
	}
)
export class GenderTypeService
{
	public constructor
	(
		private httpClient: HttpClient
	)
	{
	}

	public get(): Observable<Gender[]>
	{
		let url = environment.baseUrl + `/general/gender-types`;

		return this.httpClient.get<Gender[]>(url);
	}
}
