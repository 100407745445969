import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

import { FuseCardModule } from '@fuse/components/card/card.module';

@Component({
	selector: 'global-user-sidebar-list-card',
	templateUrl: './list-card.component.html',
	standalone: true,
	imports: [FuseCardModule, MatLegacyButtonModule, MatIconModule]
})

export class GlobalUserSidebarListCardComponent 
{
	@Input() icon: string;
	@Input() name: string;
	@Input() description: string;
	@Input() link: string;

	public constructor() { }
}
