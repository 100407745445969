import { Guid } from '@abp/guid';

import { RoutineTemplateResistanceExerciseSetViewModel } from './routine-exercise-set.view-model';

export class RoutineTemplateResistanceExerciseViewModel
{
	public routineResistanceExerciseId: string = Guid.empty;
	public routineId: string = Guid.empty;
	public resistanceExerciseId: number;
	public name: string = '';
	public description: string = '';
	public order: number = 0;
	public isActive: boolean = true;
	public createdDate: Date = new Date();
	public modifiedDate: Date = new Date();
	public routineTemplateResistanceExerciseSets: RoutineTemplateResistanceExerciseSetViewModel[] = [];
}
