import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

export const memberSearchRoutes: Route[] =
[
	{
		path: 'member',
		loadComponent: () => import('./member/member.component').then(c => c.CommunitySearchMemberComponent),
		canActivate: [AuthGuard]
	},
	{
		path: '**',
		redirectTo: 'search'
	}
];