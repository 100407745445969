import { Component, computed, EventEmitter, input, OnChanges, Output, signal, ViewEncapsulation } from '@angular/core';

import { FuseNavigationModule } from '@fuse/components/navigation';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';

import { ClientRoutineTemplateFilterType } from '../../../../_enums/routine-filter-type.enum';
import { RoutineTemplateViewModel } from '../../../../_view-models/routine.view-model';

@Component({
	selector: 'client-detail-training-routine-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['navigation.component.scss'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [FuseNavigationModule]
})

export class ClientDetailTrainingRoutineNavigationComponent implements OnChanges
{
	public allRoutines = input<RoutineTemplateViewModel[]>();

	private activeRoutineCount = computed(() => this.allRoutines().filter(x => x.isActive).length);
	private inactiveRoutineCount = computed(() => this.allRoutines().filter(x => !x.isActive).length);

	@Output() readonly onCreateRoutine = new EventEmitter();
	@Output() readonly onFilterChange = new EventEmitter<ClientRoutineTemplateFilterType>();

	public menuData: FuseNavigationItem[];

	public selectedFilter = signal<ClientRoutineTemplateFilterType>(ClientRoutineTemplateFilterType.ActiveRoutines);

	public ngOnChanges(): void
	{
		this.buildNavigation();
	}

	private buildNavigation(): void
	{
		this.menuData =
			[
				{
					title: 'Actions',
					type: 'group',
					children:
						[
							{
								title: 'Create routine',
								type: 'basic',
								icon: 'heroicons_outline:plus-circle',
								function: () =>
								{
									this.onCreateRoutine.emit();
								}
							}
						]
				},
				{
					title: 'Filters',
					type: 'group',
					children:
						[
							{
								id: "1",
								title: 'Active routines',
								type: 'basic',
								icon: 'heroicons_outline:check-circle',
								badge:
								{
									title: this.activeRoutineCount().toString(),
									classes: 'px-2 bg-primary text-on-primary rounded-full'
								},
								function: () =>
								{
									this.selectedFilter.set(ClientRoutineTemplateFilterType.ActiveRoutines);
									this.onFilterChange.emit(ClientRoutineTemplateFilterType.ActiveRoutines);
									this.buildNavigation();
								},
								active: this.selectedFilter() == ClientRoutineTemplateFilterType.ActiveRoutines
							},
							{
								id: "2",
								title: 'Inactive routines',
								type: 'basic',
								icon: 'heroicons_outline:x-circle',
								badge:
								{
									title: this.inactiveRoutineCount().toString(),
									classes: 'px-2 bg-primary text-on-primary rounded-full'
								},
								function: () =>
								{
									this.selectedFilter.set(ClientRoutineTemplateFilterType.InactiveRoutines);
									this.onFilterChange.emit(ClientRoutineTemplateFilterType.InactiveRoutines);
									this.buildNavigation();
								},
								active: this.selectedFilter() == ClientRoutineTemplateFilterType.InactiveRoutines
							}
						]
				}
			];
	}
}
