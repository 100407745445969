import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

@Component({
	selector: 'ui-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.css'],
	standalone: true,
	imports: [MatLegacyButtonModule, MatIconModule, NgIf, MatLegacyProgressSpinnerModule]
})

export class SidebarComponent 
{
	@Input() title: string = '';
	@Input() isFormValid: boolean = false;
	@Input() isFormDisabled: boolean = false;

	@Input() saveButtonLabel: string = 'Save';
	@Input() cancelButtonLabel: string = 'Cancel';

	@Input() showActionBar: boolean = true;
	@Input() showCancelButton: boolean = true;
	@Input() showSaveButton: boolean = true;
	@Input() showTitleBar: boolean = true;

	@Output() onSave = new EventEmitter();
	@Output() onCancel = new EventEmitter();

	constructor() 
	{
	}

	public save()
	{
		this.onSave.emit();
	}

	public cancel()
	{
		this.onCancel.emit();
	}
}
