import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class ContactCardMessageService
{
	private displayContactCardActionBarSource = new Subject<string>();
	public displayContactCardActionBarAction$ = this.displayContactCardActionBarSource.asObservable();

	public constructor()
	{
	}

	public displayContactCardActionBar(clientContactId : string)
	{
		this.displayContactCardActionBarSource.next(clientContactId);
	}
}
