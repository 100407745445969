import { NgFor, NgIf } from '@angular/common';
import { AfterContentInit, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { DefaultProfileImageComponent } from '@abp/components/default-profile-image/default-profile-image.component';
import { TimeAgoPipe } from '@abp/pipes/date-time/time-ago.pipe';

import { PostImageResponse } from '@fitness-central/api/community/post-image/post-image.response-model';
import { PostImageService } from '@fitness-central/api/community/post-image/post-image.service';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';

import { forkJoin, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { SwiperModule } from 'swiper/angular';

SwiperCore.use([Pagination, Navigation]);

@Component({
	selector: 'profile-image-dialog',
	templateUrl: './image-dialog.component.html',
	styleUrls: ['./image-dialog.component.scss'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [DefaultProfileImageComponent, MatLegacyButtonModule, MatIconModule, NgIf, SwiperModule, NgFor, TimeAgoPipe]
})

export class CommunityPostImageDialogComponent implements OnInit, OnDestroy, AfterContentInit
{
	public postImageIds: string[] = [];
	public postImages: PostImageResponse[];
	public selectedIndex: number = 0;
	public showNavigation: boolean = false;

	public displayName: string = '';
	public postDate: string = '';
	public ownerProfileImageUrl: string = '';

	private postImageTasks: Observable<PostImageResponse>[] = [];

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	public constructor
		(
			@Inject(MAT_DIALOG_DATA) public profileImagesData: any,
			private dialog: MatDialogRef<CommunityPostImageDialogComponent>,
			private _fuseMediaWatcherService: FuseMediaWatcherService,
			private _postImageService: PostImageService
		)
	{ }

	ngAfterContentInit(): void
	{
		this.postImageIds = this.profileImagesData['postImageIds'];
		this.selectedIndex = this.profileImagesData['selectedIndex'];
		this.displayName = this.profileImagesData['displayName'];
		this.postDate = this.profileImagesData['postDate'];
		this.ownerProfileImageUrl = this.profileImagesData['ownerProfileImageUrl'];

		this.getPostImages();
	}

	public ngOnInit()
	{
		this.subscribeToMediaChanges();


	}

	public closeDialog()
	{
		this.dialog.close
			(
				{
					event: 'close'
				}
			);
	}

	public onSwiper(swiper)
	{

	}

	public onSlideChange()
	{

	}

	private getPostImages()
	{
		const reducedImageSize = false;

		this.postImageIds.forEach
			(
				postImageId =>
				{
					this.postImageTasks.push
						(
							this._postImageService.getByPostImageId(postImageId, reducedImageSize)
						);
				}
			);

		forkJoin(this.postImageTasks)
			.subscribe
			(
				{
					next: (postImages: PostImageResponse[]) =>
					{
						this.postImages = postImages.sort((first, second) => 0 - (first.createdDate > second.createdDate ? -1 : 1));
					}
				}
			)
	}

	private subscribeToMediaChanges()
	{
		this._fuseMediaWatcherService.onMediaChange$
			.pipe
			(
				takeUntil
					(
						this._unsubscribeAll
					)
			)
			.subscribe
			(
				({ matchingAliases }) =>
				{
					if (matchingAliases.includes('sm'))
					{

						if (this.postImageIds.length > 1)
						{
							this.showNavigation = true;
						}

						this.dialog.updateSize("100vw", "100%");
					}
					else
					{
						this.showNavigation = false;

						this.dialog.updateSize("100vw", "100%");
					}
				}
			);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
