import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { ClientDetailTrainingExerciseDetailComponent } from './exercise/detail/detail.component';
import { ClientDetailTrainingRoutineDetailComponent } from './routine/detail/detail.component';
import { ClientDetailTrainingRoutineListComponent } from './routine/list/list.component';

export const clientRoutineTemplateRoutes: Route[] =
	[
		{
			path: 'routine/list',
			component: ClientDetailTrainingRoutineListComponent,
			canActivate: [AuthGuard]
		},
		{
			path: 'routine/:routineId/detail',
			component: ClientDetailTrainingRoutineDetailComponent,
			data:
			{
				isClientRoutine: true
			},
			canActivate: [AuthGuard]
		},
		{
			path: 'exercise/:resistanceExerciseId/detail',
			component: ClientDetailTrainingExerciseDetailComponent,
			data:
			{
				isClientExercise: true
			},
			canActivate: [AuthGuard]
		},
		{
			path: '**',
			redirectTo: 'routine/list'
		}
	];