import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { TrainingStatusType } from '@fitness-central/shared/enum/training-status-type.enum';

import { Observable } from 'rxjs';

import { ClientWorkoutCreateRequestModel } from './workout-create.request-model';
import { ClientWorkoutRequestModel } from './workout.request-model';
import { ClientWorkoutResponseModel } from './workout.response-model';

@Injectable
	(
		{
			providedIn: 'root'
		}
	)

export class ClientWorkoutService
{
	public constructor(private http: HttpClient) { }

	public get(workoutId: string): Observable<ClientWorkoutResponseModel>
	{
		const url = environment.baseUrl + `/client/training/workout/${workoutId}`;

		return this.http.get<ClientWorkoutResponseModel>(url);
	}

	public getAll(startDate: Date, endDate: Date, status: TrainingStatusType): Observable<ClientWorkoutResponseModel[]>
	{
		const url = environment.baseUrl + `/client/training/workouts?startDate=${startDate.toDateString()}&endDate=${endDate.toDateString()}&status=${status}`;

		return this.http.get<ClientWorkoutResponseModel[]>(url);
	}

	public getAllByClientId(clientId: string, startDate: Date, endDate: Date, status: TrainingStatusType): Observable<ClientWorkoutResponseModel[]>
	{
		const url = environment.baseUrl + `/client/${clientId}/training/workouts?startDate=${startDate.toDateString()}&endDate=${endDate.toDateString()}&status=${status}`;

		return this.http.get<ClientWorkoutResponseModel[]>(url);
	}

	public getByRoutineId(routineId: string): Observable<ClientWorkoutResponseModel[]>
	{
		let url = environment.baseUrl + `/client/training/workouts/routine/${routineId}`;

		return this.http.get<ClientWorkoutResponseModel[]>(url);
	}

	public getByRoutineIdAndWorkoutStatus(routineId: string, workoutStatus: TrainingStatusType): Observable<ClientWorkoutResponseModel[]>
	{
		let url = environment.baseUrl + `/client/training/workouts/routine/${routineId}?status=${workoutStatus}`;

		return this.http.get<ClientWorkoutResponseModel[]>(url);
	}

	public create(request: ClientWorkoutCreateRequestModel): Observable<ClientWorkoutResponseModel>
	{
		const url = environment.baseUrl + `/client/training/workout`;

		return this.http.post<ClientWorkoutResponseModel>(url, request);
	}

	public update(request: ClientWorkoutRequestModel): Observable<ClientWorkoutResponseModel>
	{
		const url = environment.baseUrl + `/client/training/workout/${request.workoutId}`;

		return this.http.put<ClientWorkoutResponseModel>(url, request);
	}

	public updateRange(request: ClientWorkoutRequestModel[]): Observable<ClientWorkoutResponseModel[]>
	{
		const url = environment.baseUrl + `/client/training/workouts`;

		return this.http.put<ClientWorkoutResponseModel[]>(url, request);
	}
}
