import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

import { MessageViewModel } from 'app/communication/message-center/_view-models/message.view-model';

import { FuseCardModule } from '@fuse/components/card/card.module';

@Component({
	selector: 'message-list-card',
	templateUrl: './list-card.component.html',
	styleUrls: ['./list-card.component.css'],
	standalone: true,
	imports: [FuseCardModule, NgIf, MatIconModule, MatLegacyTooltipModule, MatLegacyButtonModule]
})

export class MessageListCardComponent implements OnInit 
{
	@Input() message: MessageViewModel;

	@Output() onMessageSelected = new EventEmitter<MessageViewModel>();
	@Output() onMessageDeleted = new EventEmitter<MessageViewModel>();

	public isActionBarVisible: boolean = false;

	public constructor() { }

	public ngOnInit() 
	{
	}

	public toggleActionBarVisibility()
	{
		this.isActionBarVisible = !this.isActionBarVisible;
	}

	public selectMessage()
	{
		this.onMessageSelected.emit(this.message);
	}

	public deleteMessage()
	{
		this.onMessageDeleted.emit(this.message);
	}
}
