import { Component, EventEmitter, OnDestroy, OnInit, Output, WritableSignal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { Router } from '@angular/router';

import { JwtHelper } from '@fitness-central/core/helper/jwt.helper';
import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';
import { UserType } from '@fitness-central/shared/enum/user-type.enum';

import { Subject } from 'rxjs';

import { GlobalUserSidebarListCardComponent } from './_components/list-card/list-card.component';

@Component({
	selector: 'layout-user-sidebar',
	templateUrl: './user-sidebar.component.html',
	standalone: true,
	imports:
		[
			MatLegacyButtonModule,
			MatIconModule,
			GlobalUserSidebarListCardComponent
		]
})

export class LayoutUserSidebarComponent implements OnInit, OnDestroy
{
	@Output() onCancel = new EventEmitter();

	public userName: string = '';
	public userType: UserType = UserType.Member;
	public firstName: WritableSignal<string>;
	public lastName: WritableSignal<string>;
	public displayName: WritableSignal<string>;
	public profileImageUrl: WritableSignal<string>;
	public handle: string = '';
	public isNew: boolean = false;

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	public constructor
		(
			private readonly _jwtHelper: JwtHelper,
			private readonly _sessionStorageHelper: SessionStorageHelper,
			private readonly _router: Router
		) 
	{
		this.displayName = this._sessionStorageHelper.displayName;
		this.firstName = this._sessionStorageHelper.firstName;
		this.lastName = this._sessionStorageHelper.lastName;
		this.profileImageUrl = this._sessionStorageHelper.profileImageUrl;
	}

	public ngOnInit() 
	{

		this.userName = this._jwtHelper.userName();
		this.userType = this._jwtHelper.userType();
		this.handle = this._jwtHelper.handle();
		this.isNew = this._jwtHelper.isNew();
	}

	public cancel()
	{
		this.onCancel.emit();
	}

	public routeToSettings()
	{
		this.onCancel.emit();

		switch (this.userType)
		{
			case UserType.Member:
				{
					if (this.isNew)
					{
						this._router.navigate([`/member/settings/welcome`]);
					}
					else
					{
						this._router.navigate([`/member/settings/account`]);
					}

					break;
				}
			case UserType.Employee:
				{
					if (this.isNew)
					{
						this._router.navigate([`/employee/settings/welcome`]);
					}
					else
					{
						this._router.navigate([`/employee/settings/account`]);
					}

					break;
				}
		}
	}

	public signOut()
	{
		this._router.navigate(['/authentication/signout']);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
