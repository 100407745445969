
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { ClientCreateRequestModel } from './client-create-request.model';
import { ClientResponseModel } from './client-response.model';
import { ClientUpdateRequestModel } from './client-update-request.model';

@Injectable
(
	{
		providedIn: 'root'
	}
)
export class ClientService
{
	public constructor
	(
		private http: HttpClient
	)
	{
	}

	public get(clientId: string) : Observable<ClientResponseModel>
	{
		const url = environment.baseUrl + `/client/${ clientId }`;

		return this.http.get<ClientResponseModel>(url);
	}

	public getByEmployeeId() : Observable<ClientResponseModel[]>
	{
		const url = environment.baseUrl + `/clients`;

		return this.http.get<ClientResponseModel[]>(url);
	}

	public create(client: ClientCreateRequestModel): Observable<ClientResponseModel>
	{
		const url = environment.baseUrl + `/client`;
		
		return this.http.post<ClientResponseModel>(url, client);
	}

	public update(client: ClientUpdateRequestModel): Observable<ClientResponseModel>
	{
		const url = environment.baseUrl + `/client/${ client.clientId }`;

		return this.http.put<ClientResponseModel>(url, client)
	}

	public delete(clientId: string): Observable<ClientResponseModel>
	{
		const url = environment.baseUrl + `/client/${ clientId }`;

		return this.http.delete<ClientResponseModel>(url)
	}
}
