import { NgTemplateOutlet } from '@angular/common';
import { Component, input, OnChanges, OnDestroy, OnInit, signal, viewChild } from '@angular/core';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';

import { FuseScrollbarModule } from '@fuse/directives/scrollbar/scrollbar.module';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DrawerModeType } from './enums/drawer-mode-type.enum';
import { SidebarActionType } from './enums/sidebar-action-type.enum';

@Component({
	selector: 'sidebar-view',
	templateUrl: './sidebar-view.component.html',
	styleUrls: ['./sidebar-view.component.scss'],
	standalone: true,
	imports:
		[
			NgTemplateOutlet,
			MatSidenavModule,
			FuseScrollbarModule
		]
})

export class SidebarViewComponent implements OnInit, OnChanges, OnDestroy
{
	public disableSidebarAutoClose = input<boolean>(true);
	public isContentScrollable = input<boolean>(true);
	public showDefaultSidebar = input<boolean>(false);
	public showHeaderInContent = input<boolean>(false);
	public showNavigation = input<boolean>(true);
	public sidebarWidth = input<string>('w-128');

	private navigation = viewChild.required<MatDrawer>('navigation');
	private sidebar = viewChild.required<MatDrawer>('sidebar');

	public drawerModeType = DrawerModeType;
	public navigationMode = signal<DrawerModeType>(DrawerModeType.Side);
	public isNavigationOpened = signal<boolean>(false);

	public sidebarMode = signal<DrawerModeType>(DrawerModeType.Over);
	public isSidebarOpened = signal<boolean>(false);
	public sidebarActionType = SidebarActionType;
	public sidebarAction = signal<SidebarActionType>(SidebarActionType.Default);

	public disableNavigationAutoClose = signal<boolean>(false);

	private _unsubscribeAll = new Subject<void>();

	constructor
		(
			private readonly _fuseMediaWatcherService: FuseMediaWatcherService
		) 
	{
	}

	public ngOnInit() 
	{
		this.subscribeToMediaChanges();

		if (!this.showNavigation())
		{
			this.isNavigationOpened.set(this.showNavigation());
			this.navigationMode.set(DrawerModeType.Over);
		}

		if (this.showDefaultSidebar())
		{
			if (this.sidebarMode() == DrawerModeType.Side)
			{
				this.openDefaultSidebar();
			}
		}
	}

	public ngOnChanges() 
	{
		if (this.showDefaultSidebar())
		{
			if (this.sidebarMode() == DrawerModeType.Side)
			{
				this.openDefaultSidebar();
			}
		}
	}

	public openCreateSidebar()
	{
		if (this.showNavigation() && this.navigationMode() == DrawerModeType.Over)
		{
			this.isNavigationOpened.set(false);
			this.navigation().close();
		}

		this.isSidebarOpened.set(true);
		this.sidebarAction.set(SidebarActionType.Create);
	}

	public openEditSidebar()
	{
		if (this.showNavigation() && this.navigationMode() == DrawerModeType.Over)
		{
			this.isNavigationOpened.set(false);
			this.navigation().close();
		}

		this.isSidebarOpened.set(true);
		this.sidebarAction.set(SidebarActionType.Edit);
	}

	public openDeleteSidebar()
	{
		if (this.showNavigation() && this.navigationMode() == DrawerModeType.Over)
		{
			this.isNavigationOpened.set(false);
			this.navigation().close();
		}

		this.isSidebarOpened.set(true);
		this.sidebarAction.set(SidebarActionType.Delete);
	}

	public openDefaultSidebar()
	{
		if (this.showNavigation() && this.navigationMode() == DrawerModeType.Over)
		{
			this.isNavigationOpened.set(false);
			this.navigation().close();
		}

		this.sidebarAction.set(SidebarActionType.Default);
		this.isSidebarOpened.set(true);
	}

	public toggleNavigation()
	{
		if (this.showDefaultSidebar() && this.navigationMode() == DrawerModeType.Over)
		{
			this.isSidebarOpened.set(false);
		}

		this.navigation().toggle();
	}

	public openNavigation()
	{
		if (this.showNavigation() && this.navigationMode() == DrawerModeType.Over)
		{
			this.isNavigationOpened.set(true);
			this.navigation().open();
		}
	}

	public closeNavigation()
	{
		if (this.navigationMode() == DrawerModeType.Over)
		{
			this.isNavigationOpened.set(false);
			this.navigation().close();
		}
	}

	public toggleSidebar()
	{
		this.sidebar().toggle();
	}

	public openSidebar()
	{
		this.isSidebarOpened.set(true);
		this.sidebar().open();
	}

	public closeSidebar()
	{
		if (this.showDefaultSidebar())
		{
			if (this.sidebarMode() == DrawerModeType.Side)
			{
				this.openDefaultSidebar();
			}
			else
			{
				this.isSidebarOpened.set(false);
				this.sidebar().close();
			}
		}
		else
		{
			this.isSidebarOpened.set(false);
			this.sidebar().close();
		}
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	private subscribeToMediaChanges()
	{
		// Subscribe to media changes
		this._fuseMediaWatcherService.onMediaChange$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				({ matchingAliases }) =>
				{
					// Set the drawerMode and drawerOpened if
					if (matchingAliases.includes('lg'))
					{
						this.disableNavigationAutoClose.set(true);

						if (this.showNavigation())
						{
							this.navigationMode.set(DrawerModeType.Side);
							this.isNavigationOpened.set(true);
						}
						else
						{
							this.navigationMode.set(DrawerModeType.Over);
							this.isNavigationOpened.set(false);
							this.navigation().close();
						}

						if (this.showDefaultSidebar())
						{
							this.sidebarMode.set(DrawerModeType.Side);
							this.isSidebarOpened.set(true);

							this.openDefaultSidebar();
						}
						else
						{
							this.sidebarMode.set(DrawerModeType.Over);
							this.isSidebarOpened.set(false);
						}
					}
					else
					{
						this.disableNavigationAutoClose.set(false);

						if (this.showNavigation())
						{
							this.navigationMode.set(DrawerModeType.Over);
							this.isNavigationOpened.set(false);
						}

						this.sidebarMode.set(DrawerModeType.Over);
						this.isSidebarOpened.set(false);
					}
				}
			);
	}
}
