import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class ClientDetailBodyCompositionBodyWeightMessageService
{
	private displayActionBarSource = new Subject<string>();
	public displayActionBarAction$ = this.displayActionBarSource.asObservable();

	public constructor()
	{
	}

	public displayActionbar(bodyWeightMeasurementId : string)
	{
		this.displayActionBarSource.next(bodyWeightMeasurementId);
	}
}
