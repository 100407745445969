import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { ActivatedRoute } from '@angular/router';

import { ClientResponseModel } from '@fitness-central/api/client/client/client-response.model';
import { ClientContactResponseModel } from '@fitness-central/api/client/contact/contact-response.model';
import { ClientContactService } from '@fitness-central/api/client/contact/contact.service';
import { ContactType } from '@fitness-central/api/general/contact-type/contact-type.model';
import { ContactTypeService } from '@fitness-central/api/general/contact-type/contact-type.service';
import { FuseAlertType } from '@fuse/components/alert';
import { FuseAlertModule } from '@fuse/components/alert/alert.module';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'client-details-home-create-contact-sidebar',
	templateUrl: './client-contact-create.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [MatLegacyButtonModule, MatIconModule, FormsModule, ReactiveFormsModule, NgIf, FuseAlertModule, MatLegacyFormFieldModule, MatLegacyInputModule, MatLegacySelectModule, MatLegacyOptionModule, NgFor, MatLegacyTooltipModule]
})

export class ClientDetailHomeClientContactSidebarCreateComponent implements OnInit, OnDestroy
{
	@Input() client: ClientResponseModel;

	@Output() onCreated = new EventEmitter<ClientContactResponseModel>();
	@Output() onCancel = new EventEmitter();

	@ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

	public alertClientContact:
		{
			type: FuseAlertType,
			message: string
		} =
		{
			type: 'success',
			message: ''
		};
	public showAlert: boolean = false;

	public clientContact: ClientContactResponseModel = new ClientContactResponseModel();
	public clientContactForm: FormGroup;

	public contactTypes: ContactType[] = [];

	private _unsubscribeAll: Subject<any>;

	public constructor
		(
			private _formBuilder: FormBuilder,
			private activatedRoute: ActivatedRoute,
			private clientContactService: ClientContactService,
			private contactTypeService: ContactTypeService,
		)
	{
		this._unsubscribeAll = new Subject<any>();

		this.getContactType();
	}

	public ngOnInit(): void
	{
		this.buildForm();
	}

	private buildForm()
	{
		this.clientContactForm = this._formBuilder.group
			(
				{
					clientId: [this.client.clientId, [Validators.required, Validators.pattern('^((?!00000000-0000-0000-0000-000000000000).)*$')]],
					contactTypeId: ['0', [Validators.required, Validators.pattern('^((?!0).)*$')]],
					value: [this.clientContact.value, Validators.required],
					isOptOut: [this.clientContact.isOptOut, Validators.required]
				}
			);
	}

	private getContactType()
	{
		this.contactTypeService
			.get()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: result =>
					{
						this.contactTypes = result;
					},
					error: error =>
					{
						console.error(error);
					}
				}
			);
	}

	public create()
	{
		if (this.clientContactForm.valid)
		{
			this.clientContactService
				.create(this.clientContactForm.value)
				.pipe(takeUntil(this._unsubscribeAll))
				.subscribe
				(
					{
						next: clientContact =>
						{
							setTimeout
								(
									() => 
									{
										this.buildForm();
										this.formGroupDirective.resetForm();
									}
								);

							this.onCreated.emit(clientContact);
						},
						error: error =>
						{
							this.clientContactForm
								.reset();

							// Set the alert
							this.alertClientContact =
							{
								type: 'error',
								message: 'There was a problem. Please try again later.'
							};

							// Show the alert
							this.showAlert = true;
							console.error(error);
						}
					}
				);
		}
	}

	public cancel()
	{
		setTimeout
			(
				() => 
				{
					this.buildForm();
					this.formGroupDirective.resetForm();
				}
			);

		this.onCancel.emit();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
