import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

export const memberSettingsRoutes: Route[] =
	[
		{
			path: 'account',
			loadComponent: () => import('app/member/settings/account/account.component').then(c => c.MemberSettingsAccountComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'community-profile',
			loadComponent: () => import('app/member/settings/community-profile/community-profile.component').then(c => c.MemberSettingsCommunityProfileComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'finished',
			loadComponent: () => import('app/member/settings/finished/finished.component').then(c => c.MemberSettingsFinishedComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'preferences',
			loadComponent: () => import('app/member/settings/preferences/preferences.component').then(c => c.MemberSettingsPreferencesComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'welcome',
			loadComponent: () => import('app/member/settings/welcome/welcome.component').then(c => c.MemberSettingsWelcomeComponent),
			canActivate: [AuthGuard]
		}
	];