import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { Router } from '@angular/router';

@Component({
	selector: 'profile-search',
	templateUrl: './search.component.html',
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, MatLegacyFormFieldModule, MatIconModule, MatLegacyInputModule, MatLegacyButtonModule]
})

export class ProfileSearchComponent implements OnInit 
{
	public memberSearchForm: FormGroup;

	constructor
		(
			private _formBuilder: FormBuilder,
			private _router: Router
		) 
	{
	}

	ngOnInit() 
	{
		this.buildMemberSearchForm();
	}

	public searchMembers()
	{
		let searchText = this.memberSearchForm.get('searchText').value;
		this._router.navigateByUrl(`/community/search/member?searchText=${searchText}`);
	}

	private buildMemberSearchForm()
	{
		this.memberSearchForm = this._formBuilder.group
			(
				{
					searchText: ['', [Validators.required]]
				}
			);
	}

}
