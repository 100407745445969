import { HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withComponentInputBinding, withInMemoryScrolling, withPreloading } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

import { appRoutes } from 'app/app.routes';

import { ErrorInterceptor } from '@fitness-central/core/authentication/interceptor/error.interceptor';
import { JwtInterceptor } from '@fitness-central/core/authentication/interceptor/jwt.interceptor';
import { TokenRefreshService } from '@fitness-central/core/authentication/token-refresh.service';
import { appConfig } from '@fitness-central/core/config/app.config';
import { CoreModule } from '@fitness-central/core/core.module';
import { LayoutModule } from '@fitness-central/layout/layout.module';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';

import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

if (environment.production)
{
	enableProdMode();
}

bootstrapApplication
	(
		AppComponent,
		{
			providers:
				[
					HttpClientJsonpModule,
					importProvidersFrom
						(
							BrowserModule,
							CoreModule,
							FuseModule,
							FuseConfigModule.forRoot(appConfig),
							LayoutModule,
							ServiceWorkerModule.register
								(
									'ngsw-worker.js',
									{
										enabled: environment.production,
										// Register the ServiceWorker as soon as the app is stable or after 30 seconds (whichever comes first).
										registrationStrategy: 'registerWhenStable:30000'
									}
								)
						),
					provideAnimations(),

					provideRouter
						(appRoutes,
							withPreloading(PreloadAllModules),
							withInMemoryScrolling(),
							withComponentInputBinding()
						),
					TokenRefreshService,
					{
						provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true
					},
					{
						provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true
					}
				]
		}
	)
	.catch
	(
		err => console.error(err)
	);
