import { Route } from '@angular/router';
import { MemberProfileGuard } from '@fitness-central/core/authentication/guard/personal-profile-guard';
import { EmployeeProfileGuard } from '@fitness-central/core/authentication/guard/professional-profile-guard';


export const organizationRoutes: Route[] =
	[
		{
			path: 'detail/:locationId',
			canActivate: [MemberProfileGuard],
			loadComponent: () => import('app/organization/detail/detail.component').then(c => c.OrganizationLocationDetailComponent)
		},
		{
			path: 'locations',
			canActivate: [EmployeeProfileGuard],
			loadComponent: () => import('app/organization/locations/locations.component').then(c => c.OrganizationLocationsComponent)
		},
		{
			path: 'search',
			canActivate: [MemberProfileGuard],
			loadComponent: () => import('app/organization/search/search.component').then(c => c.OrganizationSearchComponent)
		},
		{
			path: 'search/results',
			canActivate: [MemberProfileGuard],
			loadComponent: () => import('app/organization/search-results/search-results.component').then(c => c.EmployeeSearchResultsComponent)
		},
		{
			path: '**',
			redirectTo: 'mytrainers'
		}
	];