import { Component, OnInit } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';

import { environment } from 'environments/environment';

import { TokenRefreshService } from '@fitness-central/core/authentication/token-refresh.service';
import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';
import { LayoutComponent } from '@fitness-central/layout/layout.component';

declare let gtag: Function;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: true,
	imports:
		[
			RouterOutlet,
			MatNativeDateModule,
			LayoutComponent
		]
})
export class AppComponent implements OnInit
{
	constructor
		(
			private _router: Router,
			private readonly _sessionStorageHelper: SessionStorageHelper,
			private readonly _tokenRefreshServe: TokenRefreshService
		)
	{
	}

	public ngOnInit()
	{
		if (environment.production)
		{
			this.setUpAnalytics();
		}

		if (this._sessionStorageHelper.accessToken() != "")
		{
			this._tokenRefreshServe.startRefreshTimer();
		}
	}

	private setUpAnalytics()
	{
		this
			._router
			.events
			.subscribe
			(
				event =>
				{
					if (event instanceof NavigationEnd)
					{
						gtag
							(
								'config',
								environment.googleAnalytics,
								{
									'page_path': event.urlAfterRedirects
								}
							);
					}
				}
			);
	}
}
