import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { TrainingMemberExerciseDetailComponent } from './exercise/detail/detail.component';
import { TrainingMemberRoutineDetailComponent } from './routine/detail/detail.component';
import { TrainingMemberRoutineListComponent } from './routine/list/list.component';

export const memberRoutineTemplateRoutes: Route[] =
	[
		{
			path: 'routine/list',
			component: TrainingMemberRoutineListComponent,
			canActivate: [AuthGuard]
		},
		{
			path: 'routine/:routineId/detail',
			component: TrainingMemberRoutineDetailComponent,
			data:
			{
				isMemberRoutine: true
			},
			canActivate: [AuthGuard]
		},
		{
			path: 'trainer-routine/:routineId/detail',
			component: TrainingMemberRoutineDetailComponent,
			data:
			{
				isMemberRoutine: false
			},
			canActivate: [AuthGuard]
		},
		{
			path: 'exercise/:resistanceExerciseId/detail',
			component: TrainingMemberExerciseDetailComponent,
			data:
			{
				isMemberExercise: true
			},
			canActivate: [AuthGuard]
		},
		{
			path: 'trainer-exercise/:resistanceExerciseId/detail',
			component: TrainingMemberExerciseDetailComponent,
			data:
			{
				isMemberExercise: false
			},
			canActivate: [AuthGuard]
		},
		{
			path: '**',
			redirectTo: ''
		}
	];