import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, signal, ViewChild, ViewEncapsulation, WritableSignal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';

import { DefaultProfileImageComponent } from '@abp/components/default-profile-image/default-profile-image.component';

import { JwtHelper } from '@fitness-central/core/helper/jwt.helper';
import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar/loading-bar.module';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseNavigationModule } from '@fuse/components/navigation/navigation.module';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NavigationService } from './navigation/navigation.service';
import { Navigation } from './navigation/navigation.types';
import { LayoutMessageSidebarComponent } from './sidebars/message-sidebar/message-sidebar.component';
import { LayoutUserSidebarComponent } from './sidebars/user-sidebar/user-sidebar.component';

@Component({
	selector: 'default-layout',
	templateUrl: './default-layout.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [FuseLoadingBarModule, NgIf, FuseNavigationModule, MatLegacyButtonModule, MatIconModule, DefaultProfileImageComponent, MatSidenavModule, LayoutMessageSidebarComponent, LayoutUserSidebarComponent, RouterOutlet]
})
export class DefaultLayoutComponent implements OnInit, OnDestroy
{
	@ViewChild('sidebar', { static: true }) private sidebar: MatDrawer;

	public displayName: WritableSignal<string>;
	public profileImageUrl: WritableSignal<string>;

	public isNew = this._jwtHelper.isNew
	public isScreenSmall: boolean = false;
	public navigation: Navigation = new Navigation();

	public sidebarAction: "Profile" | "Messages" = "Messages";
	public sidebarOpen: boolean = false;

	public unreadMessageCount: WritableSignal<number> = signal(0);

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	constructor
		(
			private readonly _abpMediaWatcherService: FuseMediaWatcherService,
			private readonly _fuseNavigationService: FuseNavigationService,
			private readonly _jwtHelper: JwtHelper,
			private readonly _navigationService: NavigationService,
			private readonly _sessionStorageHelper: SessionStorageHelper
		)
	{
	}

	public get currentYear(): number
	{
		return new Date().getFullYear();
	}

	public ngOnInit(): void
	{
		this.navigation.default = this._navigationService.get();

		// Subscribe to media changes
		this._abpMediaWatcherService
			.onMediaChange$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				({ matchingAliases }) =>
				{
					// Check if the screen is small
					this.isScreenSmall = !matchingAliases.includes('md');
				}
			);

		this.displayName = this._sessionStorageHelper.displayName;
		this.profileImageUrl = this._sessionStorageHelper.profileImageUrl;
	}

	public toggleMessageSidebar()
	{
		if (!this.sidebar.opened)
		{
			this.sidebar.open();
		}
		else if (this.sidebarAction == "Messages")
		{
			this.sidebar.close();
		}

		this.sidebarAction = "Messages";
	}

	public toggleProfileSidebar()
	{
		if (!this.sidebar.opened)
		{
			this.sidebar.open();
		}
		else if (this.sidebarAction == "Profile")
		{
			this.sidebar.close();
		}

		this.sidebarAction = "Profile";
	}

	public toggleNavigation(name: string): void
	{
		// Get the navigation
		const navigation = this.
			_fuseNavigationService
			.getComponent<FuseVerticalNavigationComponent>(name);

		if (navigation)
		{
			// Toggle the opened status
			navigation.toggle();
		}
	}

	public closeSidebar()
	{
		this.sidebar.close();
	}

	public ngOnDestroy(): void
	{
		// Unsubscribe from all subscriptions
		this
			._unsubscribeAll
			.next(null);

		this.
			_unsubscribeAll
			.complete();
	}
}
