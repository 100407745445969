<div class="flex flex-row justify-between px-6 bg-primary">
	<div class="flex flex-col pt-2 text-xl text-on-primary justify-center">Delete Body Fat Measurement</div>
	<div class="flex flex-col justify-center">
		<button (click)="cancel()"
			class="bg-accent text-on-primary my-2"
			mat-icon-button>
			<mat-icon class="text-on-accent">close</mat-icon>
		</button>
	</div>
</div>

<mat-select class="flex flex-col p-4 text-lg bg-default" 
	[(value)]="selectedBodyFatCalculationType" disabled>

	<mat-option *ngFor="let bodyFatCalculationType of bodyFatCalculationTypeList | keyvalue" [value]="bodyFatCalculationType.value">
		{{ bodyFatCalculationType.key }}
	</mat-option>
</mat-select>

<ng-container [ngSwitch]="selectedBodyFatCalculationType">

	<ng-container *ngSwitchCase="bodyFatCalculationTypes.Manual">
		<client-detail-body-composition-body-fat-measurement-sidebar-delete-manual
			[clientGender]="client().genderId"
			[bodyFatMeasurement]="bodyFatMeasurement"
			[bodyFatCalculationTypeList]="bodyFatCalculationTypeList"
			(onDeleted)="bodyFatMeasurementDeleted($event)"
			(onCancel)="cancel()">
		</client-detail-body-composition-body-fat-measurement-sidebar-delete-manual>
	</ng-container>
	<ng-container *ngSwitchCase="bodyFatCalculationTypes.JacksonPollock3Site">
		<client-detail-body-composition-body-fat-measurement-sidebar-delete-jackson-pollock-3-site
			[clientGender]="client().genderId"
			[bodyFatMeasurement]="bodyFatMeasurement"
			[bodyFatCalculationTypeList]="bodyFatCalculationTypeList"
			(onDeleted)="bodyFatMeasurementDeleted($event)"
			(onCancel)="cancel()">
		</client-detail-body-composition-body-fat-measurement-sidebar-delete-jackson-pollock-3-site>
	</ng-container>
	<ng-container *ngSwitchCase="bodyFatCalculationTypes.JacksonPollock7Site">
		<client-detail-body-composition-body-fat-measurement-sidebar-delete-jackson-pollock-7-site
			[clientGender]="client().genderId"
			[bodyFatMeasurement]="bodyFatMeasurement"
			[bodyFatCalculationTypeList]="bodyFatCalculationTypeList"
			(onDeleted)="bodyFatMeasurementDeleted($event)"
			(onCancel)="cancel()">
		</client-detail-body-composition-body-fat-measurement-sidebar-delete-jackson-pollock-7-site>
	</ng-container>


</ng-container>