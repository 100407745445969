import { DatePipe, NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { ActivatedRoute, Router } from '@angular/router';

import { DefaultProfileImageComponent } from '@abp/components/default-profile-image/default-profile-image.component';
import { SidebarViewComponent } from '@abp/components/sidebar-view/sidebar-view.component';
import { Guid } from '@abp/guid';

import { ProfileImageService } from '@fitness-central/api/community/image/image.service';
import { PostResponse } from '@fitness-central/api/community/post/post.response-model';
import { PostService } from '@fitness-central/api/community/post/post.service';
import { ProfileResponseModel } from '@fitness-central/api/community/profile/profile.response-model';
import { ProfileService } from '@fitness-central/api/community/profile/profile.service';
import { GenderType } from '@fitness-central/api/general/gender-type/gender-type.enum';
import { UserResponse } from '@fitness-central/api/identity/user/user.response-model';
import { FuseCardModule } from '@fuse/components/card';

import { Subject, takeUntil } from 'rxjs';

import { ProfileFollowerComponent } from '../_components/follower/follower.component';
import { ProfileFollowingComponent } from '../_components/following/following.component';
import { ProfileImageGalleryComponent } from '../_components/image-gallery/image-gallery.component';
import { CommunityPostSidebarComponent } from '../_components/post-sidebar/post-sidebar.component';
import { PostSidebarViewModel } from '../_components/post-sidebar/post-sidebar.view-model';
import { CommunityPostComponent } from '../_components/post/post.component';
import { ProfileSearchComponent } from '../_components/search/search.component';
import { PostType } from '../_enums/profile-post-type.enum';

@Component({
	selector: 'profile-public',
	templateUrl: './public.component.html',
	styleUrls: ['./public.component.scss'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		NgIf,
		MatLegacyProgressBarModule,
		SidebarViewComponent,
		CommunityPostSidebarComponent,
		ProfileSearchComponent,
		DefaultProfileImageComponent,
		MatLegacyButtonModule,
		FuseCardModule,
		MatIconModule,
		MatLegacyMenuModule,
		ProfileFollowerComponent,
		ProfileFollowingComponent,
		ProfileImageGalleryComponent,
		NgFor,
		CommunityPostComponent,
		DatePipe
	]
})

export class ProfilePublicComponent implements OnInit, OnDestroy
{
	@ViewChild('sidebarView', { static: true }) sidebarView: SidebarViewComponent;

	public user: UserResponse;
	public userProfile: ProfileResponseModel = new ProfileResponseModel();
	public userProfileImageUrl: string;

	public profileHandle: string;
	public profile: ProfileResponseModel = new ProfileResponseModel();

	public followerCount: number = 0;
	public followingCount: number = 0;

	public posts: PostResponse[] = [];
	public previousPosts: boolean = false;
	public postForm: FormGroup;
	public postType: typeof PostType = PostType;
	public postSidebarViewModel: PostSidebarViewModel = new PostSidebarViewModel();

	public gender: typeof GenderType = GenderType;

	public isLoading: boolean = true;

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	public constructor
		(
			private _activatedRoute: ActivatedRoute,
			private _profileService: ProfileService,
			private _profileImageService: ProfileImageService,
			private _postService: PostService,
			private _router: Router,
		)
	{
		this._router.routeReuseStrategy.shouldReuseRoute = () => false;
	}

	public ngOnInit(): void
	{
		this.profileHandle = this._activatedRoute
			.snapshot
			.paramMap
			.get('handle');

		this.getUserProfile();
		this.getProfile(this.profileHandle);
	}

	public editProfileSettings()
	{
		this._router.navigateByUrl('/settings/profile');
	}

	public createPost(postType: PostType)
	{
		this.postSidebarViewModel = new PostSidebarViewModel();
		this.postSidebarViewModel.profileId = this.profile.profileId;
		this.postSidebarViewModel.ownerProfileId = this.userProfile.profileId;
		this.postSidebarViewModel.ownerDisplayName = this.userProfile.displayName;
		this.postSidebarViewModel.ownerHandle = this.userProfile.handle;
		this.postSidebarViewModel.ownerProfileImageUrl = this.userProfileImageUrl;
		this.postSidebarViewModel.postType = postType;

		this.sidebarView.openCreateSidebar();
	}

	public closeSidebar()
	{
		this.sidebarView.closeSidebar();
	}

	public showPreviousPosts()
	{
		let lastPostId = this.posts.slice(-1)[0].postId;
		this.getPosts(lastPostId);
	}

	public routeToFollowers()
	{
		this._router.navigateByUrl(`/community/profile/public/${this.profile.handle}/followers`);
	}

	public routeToFollowing()
	{
		this._router.navigateByUrl(`/community/profile/public/${this.profile.handle}/following`);
	}

	public onPostCreated(post: PostResponse)
	{
		this.posts.unshift(post);
		this.closeSidebar();
	}

	public onPostRemoved(post: PostResponse)
	{
		let postIndex = this.posts.findIndex(post => post.postId == post.postId);
		this.posts.splice(postIndex, 1);
	}

	private getProfile(handle: string)
	{
		this._profileService
			.getByHandle(handle)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: profile =>
					{
						this.profile = { ...profile };

						this.getPosts(Guid.empty);
						this.getProfileFollowerCount();
						this.getFollowedProfileCount();

						this.isLoading = false;
					},
					error: () =>
					{
						// Redirect to 404.
					}
				}
			)
	}

	private getUserProfile()
	{
		this._profileService
			.get()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: userProfile =>
					{
						this.userProfile = userProfile;
					}
				}
			)
	}

	private getPosts(lastPostId: string)
	{
		this._postService
			.getAll(this.profile.profileId, lastPostId, 10)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: postList =>
					{
						if (postList.posts.length)
						{
							this.posts.push(...postList.posts);
							this.previousPosts = postList.previousPosts;
						}
					}
				}
			)
	}

	private getProfileFollowerCount()
	{
		this._profileService
			.getFollowerCount(this.profile.profileId)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: followerCount =>
					{
						this.followerCount = followerCount;
					}
				}
			)
	}

	private getFollowedProfileCount()
	{
		this._profileService
			.getFollowedCount(this.profile.profileId)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: followedCount =>
					{
						this.followingCount = followedCount;
					}
				}
			)
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
