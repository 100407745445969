export class ClientContactResponseModel
{
	public contactId: string = '00000000-0000-0000-0000-000000000000';
	public clientId: string = '00000000-0000-0000-0000-000000000000';
	public contactTypeId: number = 0;
	public contactTypeName: string = '';
	public contactTypeIcon: string = '';
	public value: string = '';
	public isOptOut: boolean = false;
}
