<fuse-card class="flex flex-col min-w-70 m-2 sm:mx-4 w-100-p">
<button mat-button class="w-full rounded-xl bg-card items-start justify-start py-10">
	<ng-container content>
		<div class="flex flex-col items-start">
			<div class="flex flex-row items-center">
				<div class="flex flex-col p-4 items-start">
					<mat-icon class="text-hint">
						{{ icon }}
					</mat-icon>
				</div>
				<div class="flex flex-col pt-2 w-full cursor-pointer gap-2">
					<div class="flex flex-row text-lg font-semibold">
						{{ name }}
					</div>
					<div class="flex flex-row font-normal">
						{{ description }}
					</div>
					<div class="flex flex-col sm:flex-row sm:items-center -ml-0.5 mt-2 sm:mt-1 space-y-1 sm:space-y-0 sm:space-x-3">
						<ng-container >
							<div class="flex items-center">
								<div class="ml-1.5 text-md text-secondary"></div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</button>
</fuse-card>