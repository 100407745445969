import { Injectable, signal, WritableSignal } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { BodyMeasurementType } from './_enums/body-measurement-type.enum';

@Injectable
	(
		{
			providedIn: 'root'
		}
	)

export class ClientDetailBodyCompositionMessageService
{
	public dateFilterStartDate: WritableSignal<Date>;
	public dateFilterEndDate: WritableSignal<Date>;

	private readonly dateFilterChangedSource: BehaviorSubject<Date[]>;
	public readonly dateFilterChangedAction$: Observable<Date[]>;

	private readonly bodyMeasurementTypeChangedSource: BehaviorSubject<BodyMeasurementType> = new BehaviorSubject<BodyMeasurementType>(BodyMeasurementType.Biceps);
	public readonly bodyMeasurementTypeChangedAction$: Observable<BodyMeasurementType> = this.bodyMeasurementTypeChangedSource.asObservable();

	public constructor()
	{
		const defaultNumberOfDays: number = 30;

		let dateFilterStartDate: Date;
		let dateFilterEndDate: Date = new Date(Date.now());

		const date: Date = new Date(dateFilterEndDate.getFullYear(), dateFilterEndDate.getMonth(), dateFilterEndDate.getDate());

		dateFilterStartDate = date;
		dateFilterStartDate.setDate(date.getDate() - defaultNumberOfDays);

		this.dateFilterStartDate = signal(dateFilterStartDate);
		this.dateFilterEndDate = signal(dateFilterEndDate);


		this.dateFilterChangedSource = new BehaviorSubject<Date[]>([dateFilterStartDate, dateFilterEndDate]);
		this.dateFilterChangedAction$ = this.dateFilterChangedSource.asObservable();
	}

	public changeDateFilter(dateRange: Date[])
	{
		this.dateFilterChangedSource.next(dateRange);
	}

	public changeBodyMeasurementType(selectedBodyMeasurementType: BodyMeasurementType)
	{
		this.bodyMeasurementTypeChangedSource.next(selectedBodyMeasurementType);
	}
}
