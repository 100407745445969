import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { ImageRequest } from './image.request-model';
import { ImageResponse } from './image.response-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class ProfileImageService
{
	public constructor(private http: HttpClient) { }

	public create(request: ImageRequest) : Observable<ImageResponse>
	{
		const formData = new FormData();
		formData.append('imageFile', request.imageFile);

		const url = environment.baseUrl + `/community/image`;

		return this.http.post<ImageResponse>(url, formData);
	}

	public getByProfileId(profileId: string) : Observable<ImageResponse>
	{
		const url = environment.baseUrl + `/community/image/profile/${ profileId }`;

		return this.http.get<ImageResponse>(url);
	}

	public update(request: ImageRequest) : Observable<ImageResponse>
	{
		const url = environment.baseUrl + `/community/image/${ request.imageId }`;

		return this.http.put<ImageResponse>(url, request);
	}
}
