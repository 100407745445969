import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewEncapsulation, WritableSignal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

import { ClientResponseModel } from '@fitness-central/api/client/client/client-response.model';
import { ClientService } from '@fitness-central/api/client/client/client.service';
import { Gender } from '@fitness-central/api/general/gender-type/gender-type.model';
import { GenderTypeService } from '@fitness-central/api/general/gender-type/gender-type.service';
import { FuseAlertModule, FuseAlertType } from '@fuse/components/alert';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'client-detail-home-sidebar-client-edit',
	templateUrl: './client-edit.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [MatLegacyButtonModule, MatIconModule, FormsModule, ReactiveFormsModule, NgIf, FuseAlertModule, MatLegacyFormFieldModule, MatLegacyInputModule, MatLegacySelectModule, MatLegacyOptionModule, NgFor, MatDatepickerModule, MatLegacyCheckboxModule, MatLegacyTooltipModule]
})

export class ClientDetailHomeSidebarClientEditComponent implements OnChanges, OnDestroy
{
	@Input() client: WritableSignal<ClientResponseModel>;
	@Output() onUpdated = new EventEmitter();
	@Output() onCancel = new EventEmitter();

	public alertClient:
		{
			type: FuseAlertType,
			message: string
		} =
		{
			type: 'success',
			message: ''
		};

	public showAlert: boolean = false;

	public clientForm: FormGroup;

	public genders: Gender[] = [];

	private _unsubscribeAll: Subject<any>;

	public constructor
		(
			private readonly _clientService: ClientService,
			private readonly _formBuilder: FormBuilder,
			private readonly _genderService: GenderTypeService,
		)
	{
		this._unsubscribeAll = new Subject<any>();

		this.getGenders();
	}

	public ngOnChanges(): void
	{
		this.buildForm();
	}

	public buildForm()
	{
		this.clientForm = this._formBuilder.group
			(
				{
					clientId: [this.client().clientId, Validators.required],
					genderId: [this.client().genderId, [Validators.required, Validators.pattern('^((?!0).)*$')]],
					firstName: [this.client().firstName, Validators.required],
					lastName: [this.client().lastName, Validators.required],
					dateOfBirth: [this.client().dateOfBirth],
					description: [this.client().description],
					isActive: [this.client().isActive]
				}
			);
	}

	public update()
	{
		if (this.clientForm.valid)
		{
			this._clientService
				.update(this.clientForm.value)
				.pipe(takeUntil(this._unsubscribeAll))
				.subscribe
				(
					{
						next: client =>
						{
							this.client.set(client);

							this.onUpdated.emit();
						},
						error: error =>
						{
							// Set the alert
							this.alertClient =
							{
								type: 'error',
								message: 'There was a problem. Please try again later.'
							};

							// Show the alert
							this.showAlert = true;
							console.error(error);
						}
					}
				);
		}
	}

	public cancel()
	{
		this.buildForm();
		this.onCancel.emit();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}

	private getGenders()
	{
		this._genderService
			.get()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: result =>
					{
						this.genders = result;
					},
					error: errors =>
					{
						console.error(errors);
					}
				}
			);
	}
}
