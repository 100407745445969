import { Injectable, signal } from '@angular/core';

import { Guid } from '@abp/guid';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class TrainingRoutineMessageService
{
	public activeId = signal(Guid.empty);
}
