import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { ClientBodyFatMeasurementCreateRequestModel } from './body-fat-measurement-create-request.model';
import { ClientBodyFatMeasurementResponseModel } from './body-fat-measurement-response.model';
import { ClientBodyFatMeasurementUpdateRequestModel } from './body-fat-measurement-update-request.model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class ClientBodyFatMeasurementService
{
	public constructor
	(
		private http: HttpClient,
	)
	{
	}

	public get(bodyFatId: string) : Observable<ClientBodyFatMeasurementResponseModel>
	{
		const url = environment.baseUrl + '/client/body-fat-measurement/' + bodyFatId;

		return this.http.get<ClientBodyFatMeasurementResponseModel>(url);
	}

	public getByClientId(clientId : string, startDate : Date, endDate : Date) : Observable<ClientBodyFatMeasurementResponseModel[]>
	{
		const url = environment.baseUrl + `/client/body-fat-measurements/client/${ clientId }/?startDate=${ startDate.toDateString() }&endDate=${ endDate.toDateString() }`;

		return this.http.get<ClientBodyFatMeasurementResponseModel[]>(url);
	}

	public create(bodyFat: ClientBodyFatMeasurementCreateRequestModel): Observable<ClientBodyFatMeasurementResponseModel>
	{
		const url = environment.baseUrl + '/client/body-fat-measurement/';

		return this.http.post<ClientBodyFatMeasurementResponseModel>(url, bodyFat);
	}

	public update(bodyFat: ClientBodyFatMeasurementUpdateRequestModel): Observable<ClientBodyFatMeasurementResponseModel>
	{
		const url = environment.baseUrl + '/client/body-fat-measurement/' + bodyFat.bodyFatMeasurementId;

		return this.http.put<ClientBodyFatMeasurementResponseModel>(url, bodyFat);
	}

	public delete(bodyFatId: string): Observable<ClientBodyFatMeasurementResponseModel>
	{
		const url = environment.baseUrl + '/client/body-fat-measurement/' +bodyFatId;

		return this.http.delete<ClientBodyFatMeasurementResponseModel>(url);
	}
}
