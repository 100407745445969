import { DatePipe } from '@angular/common';
import { Component, EventEmitter, input, OnDestroy, OnInit, Output, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

import { WorkoutViewModel } from '@fitness-central/shared/components/training/workout/view-models/workout.view-model';
import { TrainingStatusType } from '@fitness-central/shared/enum/training-status-type.enum';
import { FuseCardModule } from '@fuse/components/card';

import { Subject, takeUntil } from 'rxjs';

import { TrainingWorkoutHistoryMessageService } from '../../message-services/history.message-service';

@Component({
	selector: 'training-history-cards-workout',
	templateUrl: './workout.component.html',
	standalone: true,
	imports:
		[
			FuseCardModule,
			MatIconModule,
			MatLegacyTooltipModule,
			MatLegacyButtonModule,
			DatePipe
		]
})

export class TrainingHistoryCardComponent implements OnInit, OnDestroy
{
	public workout = input<WorkoutViewModel>();

	@Output() onContinue = new EventEmitter();
	@Output() onSelect = new EventEmitter();

	public trainingStatus: typeof TrainingStatusType = TrainingStatusType;
	public isActionBarVisible = signal<boolean>(false);

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public constructor
		(
			private readonly _trainingWorkoutHistoryMessageService: TrainingWorkoutHistoryMessageService
		)
	{ }

	public ngOnInit() 
	{
		this.subscribeToMemberTrainingWorkoutHistoryMessageService();
	}

	public subscribeToMemberTrainingWorkoutHistoryMessageService()
	{
		this._trainingWorkoutHistoryMessageService
			.displayActionBarAction$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: workoutId =>
					{
						if (this.workout().workoutId != workoutId)
						{
							this.isActionBarVisible.set(false);
						}
					}
				}
			)
	}

	public toggleActionBarVisibility()
	{
		this.isActionBarVisible.set(!this.isActionBarVisible());
		this._trainingWorkoutHistoryMessageService.displayActionbar(this.workout().workoutId);
	}

	public routeToDetail(workout: WorkoutViewModel)
	{
		this.onSelect.emit(workout);
	}

	public continueWorkout(workout: WorkoutViewModel)
	{
		this.onContinue.emit(workout);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
