<div class="absolute inset-0 flex flex-col sm:flex-auto md:flex-none min-w-0 overflow-hidden">
	
	<ng-template #headerOutlet>
		<ng-content select="[header]">
		</ng-content>
	</ng-template>
	
	@if(!showHeaderInContent())
	{
		<div>
			<ng-container *ngTemplateOutlet="headerOutlet">
			</ng-container>
		</div>
	}

	<mat-drawer-container class="flex-auto h-full">

		<mat-drawer #navigation class="w-72 dark:bg-gray-900" (keydown.escape)="$event.stopPropagation()"
			[autoFocus]="false"
			[mode]="navigationMode()"
			[opened]="isNavigationOpened()"
			[disableClose]="disableNavigationAutoClose()">

			<div>
				<ng-content select="[navigation]"></ng-content>
			</div>

		</mat-drawer>

		<mat-drawer #sidebar class="w-full fixed top-16 sm:absolute sm:top-0 md:{{ sidebarWidth() }}"
			[autoFocus]="false"
			[mode]="sidebarMode()"
			[opened]="isSidebarOpened()"
			[position]="'end'"
			[disableClose]="disableSidebarAutoClose()">

			@switch(sidebarAction())
			{
				@case(sidebarActionType.Create)
				{
					<ng-content select="[create-sidebar]"></ng-content>
				}

				@case(sidebarActionType.Edit)
				{
					<ng-content select="[edit-sidebar]"></ng-content>
				}

				@case(sidebarActionType.Delete)
				{
					<ng-content select="[delete-sidebar]"></ng-content>
				}

				@case(sidebarActionType.Default)
				{
					<ng-content select="[default-sidebar]"></ng-content>
				}
			}
		</mat-drawer>
		
		<mat-drawer-content class="flex flex-col center">
			<div class="content h-full" [fuseScrollbar]="isContentScrollable()">

				@if(showHeaderInContent())
				{
					<div>
						<ng-container *ngTemplateOutlet="headerOutlet">
						</ng-container>
					</div>
				}

				<ng-content select="[content]"></ng-content>
			</div>
		</mat-drawer-content>
	</mat-drawer-container>

	@if(!isSidebarOpened() || sidebarMode() == drawerModeType.Side)
	{
		<div class="sticky bottom-0">
			<ng-content select="[footer]"></ng-content>
		</div>
	}
</div>