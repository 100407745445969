import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';

@NgModule()
export class IconsModule
{
	constructor
		(
			private _matIconRegistry: MatIconRegistry
		)
	{
		this._matIconRegistry.setDefaultFontSetClass('material-symbols-outlined');
	}
}
