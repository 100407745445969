import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';

import { Observable } from 'rxjs';

@Injectable
	(
		{
			providedIn: 'root'
		}
	)
export class JwtInterceptor implements HttpInterceptor
{
	public constructor
		(
			private sessionStorageHelper: SessionStorageHelper
		)
	{
	}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
	{
		let baseHttpHeader = request.headers;

		let accessToken = this
			.sessionStorageHelper
			.accessToken();

		if (accessToken != '')
		{
			const authHttpHeader = baseHttpHeader.append('Authorization', 'Bearer ' + accessToken);

			request = request.clone({ headers: authHttpHeader });
		}
		else
		{
			request = request.clone({ headers: baseHttpHeader });
		}

		return next.handle(request);
	}
}
