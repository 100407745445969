import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { ProfilePrivateComponent } from './private.component';

export const profilePrivateRoutes: Route[] =
[
	{
		path: '',
		component: ProfilePrivateComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'following',
		loadComponent: () => import('./profiles/following/following.component').then(c => c.ProfilePrivateFollowingComponent),
		canActivate: [AuthGuard]
	},
	{
		path: 'followers',
		loadComponent: () => import('./profiles/follower/follower.component').then(c => c.ProfilePrivateFollowerComponent),
		canActivate: [AuthGuard]
	},
	{
		path: '**',
		redirectTo: ''
	}
];