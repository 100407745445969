@if (isLoading())
{
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
}

<sidebar-view #sidebarView
	[showNavigation]="false">
	
	<ng-container header>

		<ui-page-header
			[title]="workout().name"
			[titleSegment]="workout().version"
			[hasNavigation]="false">
		</ui-page-header>

	</ng-container>

	<ng-container content>
			<div class="sticky top-0 z-9999">
				<div class="flex flex-row items-center bg-accent text-on-accent p-4 w-full">
					<div>
						<button mat-icon-button (click)="routeToBack()">
							<mat-icon class="text-white">arrow_back</mat-icon>
						</button>
					</div>
					<div class="flex flex-col text-xl leading-none items-center justify-center w-full">
						<div class="flex flex-row text-2xl">
							Workout Summary
						</div>
						@if (workout().status == trainingStatusType.Completed)
						{
							<div class="text-lg font-light text-on-primary pt-2">
								<div>Date Completed: {{ workout().modifiedDate | date:'MM.dd.YYYY' }}</div>
							</div>
						}
					</div>
				</div>
			</div>

			@if (resistanceExercisesWithSets().length)
			{
				<div class="flex-auto m-4">
					<div class="flex flex-wrap w-full max-w-400 gap-4 justify-center sm:justify-start mt-4">
						@for (resistanceExerciseWithSets of resistanceExercisesWithSets(); track resistanceExerciseWithSets)
						{
							<training-history-cards-exercise
								[resistanceExercise]="resistanceExerciseWithSets">
							</training-history-cards-exercise>
						}
						@empty 
						{
							No Exercise Sets
						}
					</div>
				</div>
			}
	</ng-container>
</sidebar-view>