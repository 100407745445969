import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

export const clientBodyMeasurementRoutes: Route[] =
	[
		{
			path: 'biceps',
			loadComponent: () => import('./biceps/biceps.component').then(c => c.ClientDetailBodyCompositionBodyMeasurementBicepsComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'butt',
			loadComponent: () => import('./butt/butt.component').then(c => c.ClientDetailBodyCompositionBodyMeasurementButtComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'calves',
			loadComponent: () => import('./calves/calves.component').then(c => c.ClientDetailBodyCompositionBodyMeasurementCalvesComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'chest',
			loadComponent: () => import('./chest/chest.component').then(c => c.ClientDetailBodyCompositionBodyMeasurementChestComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'forearms',
			loadComponent: () => import('./forearms/forearms.component').then(c => c.ClientDetailBodyCompositionBodyMeasurementForearmsComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'hips',
			loadComponent: () => import('./hips/hips.component').then(c => c.ClientDetailBodyCompositionBodyMeasurementHipComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'neck',
			loadComponent: () => import('./neck/neck.component').then(c => c.ClientDetailBodyCompositionBodyMeasurementNeckComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'shoulders',
			loadComponent: () => import('./shoulders/shoulders.component').then(c => c.ClientDetailBodyCompositionBodyMeasurementShoulderComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'thighs',
			loadComponent: () => import('./thighs/thighs.component').then(c => c.ClientDetailBodyCompositionBodyMeasurementThighsComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'waist',
			loadComponent: () => import('./waist/waist.component').then(c => c.ClientDetailBodyCompositionBodyMeasurementWaistComponent),
			canActivate: [AuthGuard]
		}
	];