export enum BodyMeasurementType
{
	Biceps = 1,
	Butt = 2,
	Calves = 3,
	Chest = 4,
	Forearms = 5,
	Hips = 6,
	Neck = 7,
	Shoulders = 8,
	Thighs = 9,
	Waist = 10
}