import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { clientRoutineTemplateRoutes } from './routine-templates/routine-template.routes';

export const clientTrainingRoutes: Route[] =
	[
		{
			path: 'routine-templates',
			children:
				[
					...clientRoutineTemplateRoutes
				],
			canActivate: [AuthGuard]
		},
		{
			path: '**',
			redirectTo: 'routine-templates'
		}
	];