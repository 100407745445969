<div class="flex flex-auto justify-center max-w-140 sm:max-w-full mx-auto px-6 py-4">
	<div class="flex-auto">
		<form [formGroup]="memberSearchForm" >
			<mat-form-field appearance="fill" class="abp-mat-dense abp-mat-no-subscript abp-mat-rounded w-full">
				<mat-icon
					class="icon-size-5"
					matPrefix>
				
					search
				
				</mat-icon>
				<input
					matInput
					(keyup.enter)="searchMembers()"
					formControlName="searchText"
					[autocomplete]="'off'"
					[placeholder]="'Find members'">
					
				<button	mat-icon-button 
					type="button"
					(click)="searchMembers()" 
					[disabled]="memberSearchForm.invalid"> 
					<mat-icon>

						send

					</mat-icon>
				</button>
			</mat-form-field>
		</form>
	</div>
</div>