<div class="card-container" cdkDropListGroup
	[ngStyle]="{ '--dragHandleTop': dragHandleTop, '--dragHandleLeft': dragHandleLeft }">
	
<div cdkDropList 
		(cdkDropListEntered)="onDropListEntered($event)" 
		(cdkDropListDropped)="onDropListDropped()">
	</div>

	<div cdkDropList *ngFor="let card of cards" 
		(cdkDropListEntered)="onDropListEntered($event)"
		(cdkDropListDropped)="onDropListDropped()">

		<ng-container *ngIf="isDragDropEnabled">
			<div cdkDrag class="card-box"
				[ngStyle]="{ '--dragHandleTop': dragHandleTop, '--dragHandleLeft': dragHandleLeft }">
				<mat-icon cdkDragHandle>drag_indicator</mat-icon>
				<ng-container *ngTemplateOutlet="cardTemplate; context: {$implicit: card}">
				</ng-container>
			</div>
		</ng-container>

		<ng-container *ngIf="!isDragDropEnabled">
			<div class="card-box">
				<mat-icon></mat-icon>
				<ng-container *ngTemplateOutlet="cardTemplate; context: {$implicit: card}">
				</ng-container>
			</div>
		</ng-container>
	
	</div>
</div>