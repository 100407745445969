import { FuseNavigationItem } from '@fuse/components/navigation';

const employeeCommunityNavigationItem: FuseNavigationItem =
{
	id: 'community',
	title: 'Community',
	type: 'basic',
	icon: 'home',
	link: '/community/profile'
};

export const employeeCommunityNavigation: FuseNavigationItem[] =
	[
		employeeCommunityNavigationItem,
	];
