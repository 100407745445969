import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { MemberRoutineResistanceExerciseSetRequestModel } from './routine-resistance-exercise-set.request-model';
import { MemberRoutineResistanceExerciseSetResponseModel } from './routine-resistance-exercise-set.response-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class MemberRoutineResistanceExerciseSetService
{
	public constructor(private http: HttpClient) { }

	public get(routineResistanceExerciseSetId: string): Observable<MemberRoutineResistanceExerciseSetResponseModel>
	{
		const url = environment.baseUrl + `/member/training/routine-resistance-exercise-set/${ routineResistanceExerciseSetId }`;

		return this.http.get<MemberRoutineResistanceExerciseSetResponseModel>(url);
	}

	public getByRoutineResistanceExerciseId(routineResistanceExerciseId: string): Observable<MemberRoutineResistanceExerciseSetResponseModel[]>
	{
		const url = environment.baseUrl + `/member/training/routine-resistance-exercise-sets/routine-resistance-exercise/${ routineResistanceExerciseId }`;

		return this.http.get<MemberRoutineResistanceExerciseSetResponseModel[]>(url);
	}

	public create(request: MemberRoutineResistanceExerciseSetRequestModel) : Observable<MemberRoutineResistanceExerciseSetResponseModel>
	{
		const url = environment.baseUrl + '/member/training/routine-resistance-exercise-set';

		return this.http.post<MemberRoutineResistanceExerciseSetResponseModel>(url, request);
	}

	public delete(routineResistanceExerciseSetId: string): Observable<MemberRoutineResistanceExerciseSetResponseModel>
	{
		const url = environment.baseUrl + `/member/training/routine-resistance-exercise-set/${ routineResistanceExerciseSetId }`;

		return this.http.delete<MemberRoutineResistanceExerciseSetResponseModel>(url);
	}

	public update(request: MemberRoutineResistanceExerciseSetRequestModel) : Observable<MemberRoutineResistanceExerciseSetResponseModel>
	{
		const url = environment.baseUrl + `/member/training/routine-resistance-exercise-set/${ request.routineResistanceExerciseSetId }`;

		return this.http.put<MemberRoutineResistanceExerciseSetResponseModel>(url, request);
	}

	public updateRange(request: MemberRoutineResistanceExerciseSetRequestModel[]) : Observable<MemberRoutineResistanceExerciseSetResponseModel[]>
	{
		const url = environment.baseUrl + `/member/training/routine-resistance-exercise-sets`

		return this.http.put<MemberRoutineResistanceExerciseSetResponseModel[]>(url, request);
	}
}
