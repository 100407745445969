import { Route } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

export const memberVitalsRoutes: Route[] =
	[
		{
			path: 'blood-glucose',
			loadComponent: () => import('app/member/vitals/blood-glucose/blood-glucose.component').then(c => c.MemberVitalsBloodGlucoseComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'blood-oxygen',
			loadComponent: () => import('app/member/vitals/blood-oxygen/blood-oxygen.component').then(c => c.MemberVitalsBloodOxygenComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'blood-pressure',
			loadComponent: () => import('app/member/vitals/blood-pressure/blood-pressure.component').then(c => c.MemberVitalsBloodPressureComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'body-temperature',
			loadComponent: () => import('app/member/vitals/body-temperature/body-temperature.component').then(c => c.MemberVitalsBodyTemperatureComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'breathing-rate',
			loadComponent: () => import('app/member/vitals/breathing-rate/breathing-rate.component').then(c => c.MemberVitalsBreathingRateComponent),
			canActivate: [AuthGuard]
		},
		{
			path: 'heart-rate',
			loadComponent: () => import('app/member/vitals/heart-rate/heart-rate.component').then(c => c.MemberVitalsHeartRateComponent),
			canActivate: [AuthGuard]
		}
	];