export class ImageResponse
{
	public imageId: string;
	public profileId: string;
	public sasUri: string;
	public isPrimary: boolean;
	public createdDate: Date;
	public modifiedDate: Date;

	constructor()
	{
		this.imageId = '00000000-0000-0000-0000-000000000000';
		this.profileId = '00000000-0000-0000-0000-000000000000';
		this.sasUri = '';
		this.isPrimary = false;
		this.createdDate = new Date();
		this.modifiedDate = new Date();
	}
}
