import { FuseNavigationItem } from '@fuse/components/navigation';

const memberTrainingNavigationItem: FuseNavigationItem =
{
	id: 'training',
	title: 'Training',
	type: 'collapsable',
	icon: 'exercise',
	children:
		[
			{
				id: 'training.daily',
				title: 'Log Workout',
				type: 'basic',
				icon: 'play_circle',
				link: '/member/training/workout/daily'
			},
			{
				id: 'training.routines',
				title: 'Routine Templates',
				type: 'basic',
				icon: 'list_alt',
				link: '/member/training/routine-templates/routine/list'
			},
			{
				id: 'training.history',
				title: 'History',
				type: 'basic',
				icon: 'history',
				link: '/member/training/history'
			}
		]
};

export const memberTrainingNavigation: FuseNavigationItem[] =
	[
		memberTrainingNavigationItem
	];
