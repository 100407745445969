import { Injectable, signal } from '@angular/core';

import { ClientResponseModel } from '@fitness-central/api/client/client/client-response.model';
import { ClientImageResponseModel } from '@fitness-central/api/client/image/image.response-model';

import { Subject } from 'rxjs';

import { ClientDetailNavigationMenuItemType } from '../_components/navigation/models/menu-item-types.enum';

@Injectable
	(
		{
			providedIn: 'root'
		}
	)

export class ClientDetailMessageService
{
	public client = signal<ClientResponseModel>(undefined);
	public clientImage = signal<ClientImageResponseModel>(undefined);
	public selectedNavigationItemType = signal<ClientDetailNavigationMenuItemType>(ClientDetailNavigationMenuItemType.Home);

	//public isNavigationOpen = signal<boolean>(false);

	private navigationOpenSource = new Subject<void>();
	public navigationOpenAction$ = this.navigationOpenSource.asObservable();

	public toggleNavigation()
	{
		this.navigationOpenSource.next();
	}
}